<mat-form-field appearance="outline">
  <mat-label>Attribute type</mat-label>
  <mat-select [formControl]="type" (selectionChange)="newTypeChange()">
    <mat-option *ngFor="let type of types$ | async" [value]="type.id">
      {{ type.name }} ({{ type.valueType }})
    </mat-option>
    <mat-divider></mat-divider>
    <mat-option (click)="createType()"> Create new attribute type </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label>Value</mat-label>
  <input
    matInput
    type="text"
    [formControl]="value"
    *ngIf="valueType === 'string'"
  />
  <input
    matInput
    type="number"
    [formControl]="value"
    *ngIf="valueType === 'number'"
  />
  <input
    matInput
    type="color"
    [formControl]="value"
    *ngIf="valueType === 'color'"
  />
  <mat-select [formControl]="value" *ngIf="valueType === 'boolean'">
    <mat-option value="true">True</mat-option>
    <mat-option value="false">False</mat-option>
  </mat-select>
  <mat-error>Invalid value</mat-error>
</mat-form-field>
<button
  mat-flat-button
  color="primary"
  (click)="save()"
  [disabled]="!type.value || type.invalid || value.invalid"
>
  Add
</button>
