export class SubCategoryCreateRequest {
    name: string | null;
    description: string | null;
    order: number | null;
    parentCategoryId: number | null;
  
    constructor(
      name: string | null = null,
      description: string | null = null,
      order: number | null = null,
      parentCategoryId: number | null = null
    ) {
      this.name = name;
      this.description = description;
      this.order = order;
      this.parentCategoryId = parentCategoryId;
    }
  }
  