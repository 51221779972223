<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
    <mat-cell *matCellDef="let item">{{ item.product.id }}</mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let item">
      <app-product-photo [product]="item.product"></app-product-photo>
      {{ item.product.name }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
    <mat-cell *matCellDef="let item">{{
      item.product.price | formatCurrency
    }}</mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
    <mat-cell *matCellDef="let item" dir="rtl">
      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Quantity</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="item.quantity"
          (change)="updateQuantity(item)"
          required
          min="1"
        />
      </mat-form-field>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      {{ itemsQuantity }}
    </mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
    <mat-cell *matCellDef="let item">
      {{ item.product.price * item.quantity | formatCurrency }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      {{ itemsTotal | formatCurrency }}
    </mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <button mat-icon-button type="button" (click)="deleteItem(item)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="['id', 'name', 'price', 'quantity', 'total', 'delete']"
  ></mat-header-row>
  <mat-row
    *matRowDef="
      let row;
      columns: ['id', 'name', 'price', 'quantity', 'total', 'delete']
    "
  ></mat-row>
  <mat-footer-row
    *matFooterRowDef="['id', 'name', 'price', 'quantity', 'total', 'delete']"
  ></mat-footer-row>
</mat-table>

<mat-form-field appearance="outline" class="add-field">
  <mat-label>Add product</mat-label>
  <mat-select
    (openedChange)="productsFilter.setValue('')"
    (selectionChange)="addProduct($event.value)"
    [formControl]="selectProduct"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="productsFilter"
        placeholderLabel="Search"
        noEntriesFoundLabel="No products found"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let product of filteredProducts$ | async"
      [value]="product.id"
    >
      #{{ product.id }} {{ product.name }} (stock: {{ product.stock }}) -
      {{ product.price | formatCurrency }}
    </mat-option>
  </mat-select>
</mat-form-field>
