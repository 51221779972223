export const SUB_CATEGORY_BASE_URL: string = `/subcategories`;
export const SUB_CATEGORY_STATUS_UPDATE: string = `${SUB_CATEGORY_BASE_URL}/toggle-subcategory-status/{id}`;
export const USER_MANAGEMENT_BASE_URL: string = `/users`;
export const ADD_ADMIN_USER_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/admin-user`;
export const ADMIN_USER_MANAGEMENT_BASE_URL: string = `/users/admin-users`;
export const DESIGNER_USER_MANAGEMENT_BASE_URL: string = `/users/designer`;
export const HOMEOWNER_USER_MANAGEMENT_BASE_URL: string = `/users/homeowner`;
export const USER_DISPATCH_ROLE: string = `${USER_MANAGEMENT_BASE_URL}/dispatch-user-role`;

export const SUB_CATEPGORY_BY_ID_BASE_URL: string = `${SUB_CATEGORY_BASE_URL}/{id}`;

