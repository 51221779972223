import { AfterViewInit, Component, ElementRef, Injector, ViewChild, signal } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { TableModule } from '@src/app/core/shared/components/table/table.module';
import {
  STORE_MANAGEMENT_BASE_URL,
  STORE_MANAGEMENT_STATUS_UPDATE,
  STORE_STATUS,
  StoreManagement,
  StoreManagementService,
} from '../../core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPointConfiguration } from '@src/app/app-types';
import { BaseComponent } from '@src/app/core/base';
import { TableComponent } from '@src/app/core/shared/components/table/table.component';
import {
  AllowedTableActions,
  TableActions,
} from '@src/app/core/interfaces/table-actions.interface';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import {
  AllowedTableActionsData,
  ROW_NOT_CLICKABLE,
} from '@src/app/core/constants/table-actions.constant';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import FileSaver from 'file-saver';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { StoreRepository } from '../../core/repositories/store.repository';

@Component({
  selector: 'store-listing',
  standalone: true,
  templateUrl: './store-listing.component.html',
  styleUrls: ['./store-listing.component.scss'],
  imports: [...COMMON_MODULES, TableModule, NgbDropdownModule, ConfirmDialogModule, AppStatus],
})
export class StoreListingComponent extends BaseComponent implements AfterViewInit {
  @ViewChild(TableComponent, { static: false }) tableCompRef!: TableComponent;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dropdownData: any = [
    {
      name: 'CONSTANTS.ADMIN_USER',
      link: 'add',
      param: { type: 'admin_user' },
    },
    {
      name: 'CONSTANTS.APP_USER',
      link: 'add',
      param: { type: 'app_user' },
    },
  ];
  protected formChange = false; // flage to detect changes in forms of child component
  protected setFormChange = (value: boolean) => {
    this.formChange = value;
  };

  @ViewChild('fileInput') fileInput!: ElementRef;
  public isEditPermission = this.sharedDataService.checkPermission(
    [this.permissionsList.UPDATE_USERS],
    undefined,
  );
  private selectedRows = signal([]);
  public selectedFile: any[] = [];
  public status = STORE_STATUS;
  public users$: BehaviorSubject<StoreManagement[]> = new BehaviorSubject<StoreManagement[]>([]);
  public confirmEventAction: 'active' | 'delete' | null = null;

  public endPointConfiguration: ApiEndPointConfiguration = {
    method: 'GET',
    url: STORE_MANAGEMENT_BASE_URL, 
    // body: {
    //   'filter.roles.code': '$in:commercial_sales_agent,inspector,sales_agent,driver,attendant',
    // },
  };

  public allowedTableActions: AllowedTableActions = AllowedTableActionsData;
  public displayedColumnsViewArray: any[] = [];

  /**
   * Constructor for the AppUserListingComponent.
   *
   * @param {StoreManagementService} storeManagementService - Service for managing user data
   * @param {Injector} injector - Angular's injector service
   * @param {PageSettingsService} pageSettingsService - Service for managing page settings
   */
  constructor(
    // Injecting required services
    private storeManagementService: StoreManagementService, // Service for managing user data
    private injector: Injector, // Angular's injector service
    private pageSettingsService: PageSettingsService, // Service for managing page settings
    private storeRepository: StoreRepository,
  ) {
    // Calling the parent constructor (BaseComponent) and passing the injector
    super(injector);

    // Initializing allowed table actions
    this.allowedTableActions.pending = true; // Setting 'pending' action as allowed
    this.allowedTableActions.draft = false; // Setting 'draft' action as not allowed

    // Fetching user data from the user management service
    storeManagementService.getStores().subscribe({
      next: (res: StoreManagement[]) => {
        // Updating the 'users$' BehaviorSubject with the received data
        this.users$.next(res);
      },
    });

    // Creating new page settings for the user management page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name: 'MENU.STORES', // Name for the breadcrumb
        link: this.routeList.STORES, // Link for the breadcrumb
      },
    };

    // Using the page settings service to update the page settings
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  /**
   * Initializes the displayed columns view array after the view has been initialized.
   * Uses setTimeout to delay execution and avoid ExpressionChangedAfterItHasBeenCheckedError.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    // Using setTimeout to delay execution and avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      // Array of objects defining the displayed columns
      this.displayedColumnsViewArray = [
        {
          key: 'id',
          value: 'COMPONENTS.STORES.STORE_ID', // Localization key for column header
          type: 'text', // Type of column
          sortable: true, // Whether column is sortable
          className: 'w-15', // CSS class for column
        },
        {
          key: 'name',
          value: 'COMPONENTS.STORES.STORE_NAME',
          type: 'text',
          sortable: true,
          className: 'col-first-name text-truncate',
        },
        {
          key: 'status',
          value: 'COMPONENTS.STORES.FIELDS.STATUS',
          headingClass: 'ps-4',
          type: 'status',
          sortable: false,
          defaultTitle: true,
          options: [
            {
              label: 'GENERAL.BUTTONS.ACTIVATE',
              value: 'active',
              icon: 'tick-circle',
            },
            {
              label: 'GENERAL.BUTTONS.DEACTIVATE',
              value: 'inactive',
              icon: 'close-circle',
            },
          ],
          endpoint: STORE_MANAGEMENT_STATUS_UPDATE,
          success: this.translate.instant('MSGS.STORE.UPDATE_STORE_STATUS'),
          successSecond: this.translate.instant('MSGS.STORE.UPDATE_STORE_STATUS_DEACTIVATED'),
          alert: this.translate.instant('MSGS.STORE.ARE_YOU_SURE_DEACTIVATE'),
          alertSecond: this.translate.instant('MSGS.STORE.ARE_YOU_SURE_ACTIVATE'),
          error: this.translate.instant('MSGS.STORE.UNABLE_TO_UPDATE_STORE_STATUS'),
        },
        ...this.sharedDataService.checkPermission([this.permissionsList.UPDATE_USERS], {
          key: 'delete',
          value: '',
          type: 'button',
          class: ' btn-icon-table ms-auto d-block',
          iconClass: 'icon icon-trash-filled middle',
          callback: this.havePermissionToDelete,
        }),
      ];
    }, 500); // Delay of 500 milliseconds

    // Second setTimeout to execute another action after the first one
    setTimeout(() => {
      // Remove 'readonly' attribute from elements with specified IDs
      document.getElementById('email')?.removeAttribute('readonly');
      document.getElementById('password')?.removeAttribute('readonly');
    }, 1000); // Delay of 1000 milliseconds
  }

  public havePermissionToDelete = (permission: any) => {
    // Check if the permission object is an array and if it contains a role with code 'super_admin'
    return (
      Array.isArray(permission?.roles) && // Check if permission.roles is an array
      permission?.roles.some((role: any) => role?.code === 'super_admin') // Check if any role has code 'super_admin'
    );
  };

  /**
   * Checks if the given data contains an item with a role code of 'super_admin'.
   *
   * @param {any} data - The data to be checked.
   * @return {boolean} Returns true if 'super_admin' role is found, false otherwise.
   */
  private hasSuperAdminRole(data: any) {
    let hasSuperAdmin = false;
    // Loop through the data array and check if any item has a role with code 'super_admin'
    data?.forEach((item: any) => {
      if (item?.roles?.some((role: any) => role?.code === 'super_admin')) {
        hasSuperAdmin = true; // Set to true if 'super_admin' role is found
      }
    });
    return hasSuperAdmin; // Return true if 'super_admin' role is found, false otherwise
  }

  /**
   * Handles different actions based on the event object.
   *
   * @param {any} e - The event object.
   * @return {void}
   */
  public onElementClick(e: any) {
    // Handle different actions based on the event object

    // If 'element' property is an array with length greater than 0, set selectedRows
    if (e.element.length > 0) {
      this.selectedRows.set(e.element);
    }
    // If 'element' property is not clickable and has an ID 'from_td', navigate to user listing route
    else if (!ROW_NOT_CLICKABLE.includes(e.action) && e.id && e.id === 'from_td') {
      this.helperService.goToRoute(this.routeList.STORES + `/${e.element.id}`);
    }
    // If 'element' property has an ID but no action, return
    else if (e.id) {
      return;
    }

    // Switch statement to handle different actions
    switch (e.action) {
      case 'delete':
        // If confirmDialCompRef exists, open delete dialog
        if (this.confirmDialCompRef) {
          this.confirmEventAction = 'delete';
          this.confirmDialCompRef.openDialog(e.element);
        }
        break;
      case 'email':
        // If action is 'email', navigate to user listing route
        this.helperService.goToRoute(this.routeList.STORES + `/${e.element.id}`);
        break;
    }
  }

  /**
   * Processes the modal close event and performs actions based on the event type.
   *
   * @param {any} event - The event object containing information about the modal close event.
   * @return {void}
   */
  public processModalClose(event: {
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }) {
    // Switch case based on event type
    if (event?.eventType === 'delete') {
      // If action is confirm
      if (event.action === 'confirm') {
        // Check permission to delete
        if (!this.havePermissionToDelete(event.data)) {
          // Delete store
          this.storeRepository
            .delete(event.data?.id)
            .pipe(this.destroy$())
            .subscribe({
              /**
               * Handles the response from the delete operation.
               *
               * @param {any} res - The response from the delete operation.
               * @return {void}
               */
              next: (response: unknown) => {
                const res = response as ApiResponseInterface<any>;

                // If delete operation is successful
                if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
                  // Update table
                  this.updateTable(this.translate.instant('MSGS.STORE.DELETE_TEXT_SUCCESS'));
                } else {
                  // Show error message
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.STORE.UNABLE_TO_DELETE'),
                    'error',
                  );
                }
              },
            });
        } else {
          // Show error message
          this.httpService.showMessage(
            this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_SUPER_ADMIN'),
            'error',
          );
        }
      }
    }
  }

  /**
   * Following method will work against particullar action performed
   *
   * @param e Action emitted by filter action in table
   */
  public tableAction({ key, params }: TableActions) {
    // Check if action is not related to downloading or importing
    if (key !== 'download_template' && key !== 'download_selected_rows' && key !== 'import') {
      // If rows are selected
      if (this.selectedRows().length > 0) {
        // Prepare ids to send along with API request
        const ids = this.selectedRows().map((item: any) => {
          return item.id;
        });
        
        // For other actions like delete
        if (key === 'delete') {
          if (!this.hasSuperAdminRole(this.selectedRows())) {
            // Delete selected rows
            this.storeRepository
              .delete(ids[0])
              .pipe(this.destroy$())
              .subscribe({
                /**
                 * Handles the response from the delete operation.
                 *
                 * @param {any} res - The response from the delete operation.
                 * @return {void}
                 */
                next: (response: unknown) => {
                  const res = response as ApiResponseInterface<any>;

                  // If delete operation is successful
                  if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
                    // Update table
                    this.updateTable(this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS'));
                  }
                },
              });
          } else {
            // Show error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_SUPER_ADMIN'),
              'error',
            );
          }
        }
      } else {
        // Show error message if no row is selected
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.SELECT_ROW'), 'error');
      }
    }
  }

  // Function to update the table
  private updateTable(msg: string) {
    // Show success message
    this.httpService.showMessage(msg, 'success');
    // Reload the table data
    this.tableCompRef.loadResourcesPage();
    // Clear the bulk select list
    this.tableCompRef.bulkSelectList = [];
    // Clear the selected rows
    this.selectedRows.set([]);
  }
}
