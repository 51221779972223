import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';
import {USER_PROFILE_OVERVIEW} from '@src/app/features/profile/core/constants/apis_list';
import {USER_PROFILE_UPDATE_PICTURE, USER_PROFILE_UPDATE_PASSWORD} from '@src/app/features/profile/core';

@Injectable({
  providedIn: 'root',
})
export class ProfileRepository {
constructor(private httpService: HttpService) {}
  getAll(): Observable<ApiResponseInterface<null>> {
  
    return this.httpService.requestEntity('GET', USER_PROFILE_OVERVIEW);
  }

  updatePassword( value: any): Observable<ApiResponseInterface<null>> {

    return  this.httpService
      .requestEntity('PATCH', USER_PROFILE_UPDATE_PASSWORD, value)
  }
  updateDetail( formData: any): Observable<ApiResponseInterface<null>> {
  
    return this.httpService.requestEntity(
      'PATCH',
      USER_PROFILE_UPDATE_PICTURE,
      formData,
      this.httpService.multiPartFormDataHeaders,
    );
  }


}

