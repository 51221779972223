import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SharedDataService } from '@app/core/services';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnChanges {
  @Input() isButtonSpinner: boolean = false;

  constructor(public sharedDataService: SharedDataService) {}

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }
}
