export const STORE_MANAGEMENT_BASE_URL: string = `/stores`;
export const STORE_BY_ID_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/{id}`;
export const UPDATE_STORE_PICTURE_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/picture`;
export const UPDATE_STORE_STATUS_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/status`;
export const UPLOAD_STORE_DOCUMETS_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/store/{id}/upload-document-file`;
export const DELETE_STORE_DOCUMETS_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/store/{documentId}/delete-document-file`;
export const DOWNLOAD_STORE_DOCUMETS_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/store/{documentFileId}/download-document-file`;
export const UPDATE_STORE_DOCUMENT_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/store/{id}/document`;
export const UPDATE_STORE_OFFICIAL_DETAILS_BASE_URL: string = `${STORE_MANAGEMENT_BASE_URL}/store/{id}/professional-detail`;
export const STORE_MANAGEMENT_BULK_STATUS: string = `${STORE_MANAGEMENT_BASE_URL}/bulk-status-update`;
export const STORE_MANAGEMENT_STATUS_UPDATE: string = `${STORE_MANAGEMENT_BASE_URL}/toggle-store-status/{id}`;
export const STORE_MANAGEMENT_BULK_DELETE: string = `${STORE_MANAGEMENT_BASE_URL}/bulk-delete`;

export const GET_COUNTRIES_BASE_URL: string = `system/countries`;
export const GET_CITIES_BASE_URL: string = `system/countries/{countryId}/cities`;
export const SYSTEM_ROLES_BASE_URL: string = `system/roles`;

export const GET_AUDITS_BASE_URL: string = `system/audits`;
export const GET_CATEGORIES_BASE_URL: string = `system/categories`;
export const GET_MODULES_BASE_URL: string = `system/modules`;
