<mat-form-field appearance="outline">
  <mat-label> {{ label }} </mat-label>
  <mat-select
    [(ngModel)]="value"
    (selectionChange)="onChange($event.value); value = $event.value"
    (opened)="onTouched()"
    [multiple]="type.endsWith('List')"
    #select
  >
    <mat-select-trigger>{{ value | formatCountry }}</mat-select-trigger>
    <mat-option
      [value]="value"
      *ngIf="!select.panelOpen && !!value"
    ></mat-option>
    <mat-option
      [value]="value[0]"
      *ngIf="!select.panelOpen && !!value"
    ></mat-option>
    <mat-option>
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="filterChange($event)"
        placeholderLabel="Search"
        noEntriesFoundLabel="No items found"
      ></ngx-mat-select-search>
    </mat-option>
    <ng-container *ngIf="type.startsWith('countr') && select.panelOpen">
      <mat-option
        *ngFor="let country of filteredCountries"
        [value]="country.code"
      >
        {{ country.code | formatCountry }}
      </mat-option>
    </ng-container>
    <ng-container *ngIf="type.startsWith('curr') && select.panelOpen">
      <mat-option
        *ngFor="let currency of filteredCurrencies"
        [value]="currency"
      >
        {{ currency }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
