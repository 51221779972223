<div class="user-profile">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li [ngbNavItem]="1">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col">
            <a ngbNavLink>{{ 'COMPONENTS.STORES.STORE_DETAIL' | translate }}</a>
          </div>
        </div>
      </div>
      <ng-template ngbNavContent>
        <form [formGroup]="formGroup" novalidate>
          <div class="row">
            <div class="col-md-6">
              <div class="form-field mb-4">
                <div
                  class="d-flex gap-3 align-items-center mb-4 flex-column flex-wrap align-content-start w-50 float-left">
                  <label htmlFor="upload1" class="required">
                    {{ 'Store Image' | translate }}
                    <button
                      type="button"
                      class="btn-transparent ms-2 tooltip-btn"
                      tooltipClass="tooltip-info-details"
                      placement="right"
                      [ngbTooltip]="logoTipContent">
                      <i class="middle icon-info-circle-outline f-18"></i>
                    </button>
                  </label>
                  <div class="wd-100">
                    <img
                      [src]="storeLogo"
                      class="w-100 user-img"
                      alt="user" />
                  </div>
                  <input
                    [attr.disabled]="pageMode === 'view' ? true : null"
                    [class.disabled]="pageMode === 'view'"
                    type="file"
                    id="upload1"
                    hidden
                    (change)="onPictureUpload($event)"
                    accept="image/png, image/jpg, image/jpeg" />

                  <label
                    for="upload1"
                    onKeyPress="onPictureUpload($event)"
                    [class.disabled]="pageMode === 'view'"
                    class="btn btn-outline btn-outline-primary btn-140">
                    {{ 'COMPONENTS.USERS.UPLOAD_PHOTO' | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
                <div class="d-flex flex-row-reverse">
                  @if (isEditMode() && isLoading === false) {
                    <status
                      [data]="baseModel"
                      [element]="baseModel"
                      [options]="constantList.STATUS_ARRAY"
                      [column]="column"
                      (updatedStatus)="updateStatus($event.status)"></status>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="pageMode === 'edit'" class="col-md-6">
              <div class="form-field mb-4">
                <label htmlFor="id" class="required">
                  {{ 'COMPONENTS.STORES.STORE_ID' | translate }}
                </label>
                <input
                  id="id"
                  [class.disabled]="
                    pageMode === 'view' || (pageMode === 'edit' && hasSpecificRole === false)
                  "
                  name="id"
                  class="form-control"
                  formControlName="id"
                  type="text"
                  readonly
                  onfocus="this.removeAttribute('readonly');"
                  placeholder="" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: formGroup.controls['id'] }"></ng-container>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-field mb-4">
                <label htmlFor="name" class="required">
                  {{ 'COMPONENTS.STORES.STORE_NAME' | translate }}
                </label>
                <input
                  [class.disabled]="pageMode === 'view'"
                  formControlName="name"
                  name="name"
                  id="name"
                  appNoConsecutiveSpaces
                  class="form-control"
                  type="text"
                  placeholder="Store Name" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{
                    control: formGroup.controls['name']
                  }"></ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-field mb-4">
                <label htmlFor="email" class="required">
                  {{ 'COMPONENTS.STORES.STORE_EMAIL' | translate }}
                </label>
                <input
                  [class.disabled]="pageMode === 'view'"
                  id="email"
                  appNoConsecutiveSpaces
                  name="email"
                  class="form-control"
                  formControlName="email"
                  type="email"
                  placeholder="charly.robinson@domain.com" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{
                    control: formGroup.controls['email']
                  }"></ng-container>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-field mb-4">
                <label htmlFor="phoneNumber" class="required">
                  {{ 'COMPONENTS.STORES.STORE_PHONE_NUMBER' | translate }}
                </label>
                <ngx-intl-tel-input
                  class="intl-tel-input"
                  inputId="input-phone-number"
                  name="phoneNumber"
                  customPlaceholder="+000 000 0000"
                  formControlName="phoneNumber"
                  searchCountryPlaceholder="Search by Country Name or Code"
                  [phoneValidation]="true"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [separateDialCode]="false"
                  [searchCountryFlag]="true"
                  [selectFirstCountry]="false"
                  [maxLength]="15"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name,
                    SearchCountryField.DialCode
                  ]"
                  [selectedCountryISO]="CountryISO.UnitedStates"
                  [numberFormat]="phoneNumberFormat.National"></ngx-intl-tel-input>
                <ng-container
                  *ngIf="
                    formGroup.controls['phoneNumber'].value !== null &&
                    formGroup.controls['phoneNumber']?.invalid
                  ">
                  <small class="text-danger w-100">
                    {{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}
                  </small>
                </ng-container>
                <ng-container
                  *ngIf="
                    formGroup.controls['phoneNumber'].value === null &&
                    formGroup.controls['phoneNumber']?.invalid &&
                    formGroup.controls['phoneNumber']?.touched &&
                    formGroup.controls['phoneNumber']?.dirty
                  ">
                  <small class="text-danger w-100">
                    {{ 'COMMON.VALIDATION.REQUIRED' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-field mb-4">
                <label htmlFor="address" class="required">
                  {{ 'COMPONENTS.STORES.STORE_ADDRESS' | translate }}
                </label>
                <input
                  [class.disabled]="pageMode === 'view'"
                  id="address"
                  appNoConsecutiveSpaces
                  name="address"
                  class="form-control"
                  formControlName="address"
                  type="address"
                  placeholder="{{ 'COMPONENTS.STORES.STORE_ADDRESS' | translate }}" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{
                    control: formGroup.controls['address']
                  }"></ng-container>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-field mb-4"></div>
            </div>
          </div>

          <div *ngIf="pageMode === 'edit'" class="d-flex justify-content-end gap-2">
            <button
              (click)="setStore()"
              type="button"
              class="btn btn-outline btn-outline-primary btn-140">
              {{ 'GENERAL.BUTTONS.RESET' | translate }}
            </button>
            <button
              type="button"
              appDebounceClick
              (debounceClick)="onSubmit('update')"
              class="btn btn-primary btn-140">
              {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
            </button>
          </div>
          <div *ngIf="pageMode === 'add'" class="d-flex justify-content-end gap-2">
            <button
              (click)="setStore('cancel')"
              type="button"
              class="btn btn-outline btn-outline-primary btn-140">
              {{ 'GENERAL.BUTTONS.CANCEL' | translate }}
            </button>
            <button
              type="button"
              appDebounceClick
              (debounceClick)="onSubmit('add')"
              class="btn btn-primary btn-140">
              {{ 'GENERAL.BUTTONS.SAVE' | translate }}
            </button>
          </div>
        </form>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="pageMode === 'edit'">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col">
            <a ngbNavLink>{{ 'COMPONENTS.STORES.TRANSACTION' | translate }}</a>
          </div>
        </div>
      </div>
      <ng-template ngbNavContent>
        <div class="col-lg-8">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">No available Transactions</p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="py-4 px-0"></div>
</div>

<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    } @else if (control?.hasError('minlength') || control?.hasError('maxlength')) {
      <small class="text-danger">
        {{ 'MSGS.STORE.STORE_NAME_VALIDATION' | translate }}
      </small>
    }
  }
</ng-template>
<ng-template #tipContent>
  @if (this.userType === 'app_user') {
    <ul class="mb-0 ps-3">
      <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_SIX_DIGITS' | translate }}</li>
    </ul>
  } @else {
    <ul class="mb-0 ps-3">
      <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_EIGHT_SIXTEEN_CHARACTERS' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_CAPITAL' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_LOWER' | translate }}</li>
      <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_NUMBER' | translate }}</li>
    </ul>
  }
</ng-template>
<ng-template #logoTipContent>
  <ul class="mb-0 ps-3">
    <li class="tx-12">Size: 0.3 MB</li>
    <li class="tx-12">Dimensions: 1200x900</li>
  </ul>
</ng-template>
