import { AllowedTableActions } from '@src/app/core/interfaces/table-actions.interface';
export interface TableActions {
  actionName: string;
  icon?: string;
  key:
    | 'active'
    | 'inactive'
    | 'draft'
    | 'delete'
    | 'download_template'
    | 'download_selected_rows'
    | 'import';
}

export const AllowedTableActionsData: AllowedTableActions = {
  active: true,
  inactive: true,
  delete: true,
  draft: true,
  pending: false,
  download_selected_rows: true,
  download_template: true,
  import: true,
};

export const FILTER_BUTTONS = [
  {
    key: 'filter.startDate',
    type: 'current',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.isExpired'],
    extraFilter: { value: false, key: 'filter.isExpired' },
    operation: '$lte:',
  },
  {
    key: 'filter.startDate',
    type: 'upcoming',
    selectedValue: '',
    resetFilters: ['filter.startAt', 'filter.isExpired'],
    extraFilter: { value: false, key: 'filter.isExpired' },
    operation: '$gt:',
  },
  {
    key: 'filter.isExpired',
    type: 'expired',
    selectedValue: '',
    resetFilters: ['filter.isExpired'],
    extraFilter: { value: true, key: 'filter.isExpired' },
    operation: '$eq:',
  },
];


export const ROW_NOT_CLICKABLE = ['delete', 'status', '', 'selected Rows', 'system'];

export const CHOOSE_FILTER_IMAGE_URL = 'empty-reports.svg';
