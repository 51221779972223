export const USER_MANAGEMENT_BASE_URL: string = `/users`;
export const ADD_ADMIN_USER_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/admin-user`;
export const ADMIN_USER_MANAGEMENT_BASE_URL: string = `/users/admin-users`;
export const DESIGNER_USER_MANAGEMENT_BASE_URL: string = `/users/designer`;
export const HOMEOWNER_USER_MANAGEMENT_BASE_URL: string = `/users/homeowner`;
export const USER_DISPATCH_ROLE: string = `${USER_MANAGEMENT_BASE_URL}/dispatch-user-role`;
export const USER_BY_ID_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/{id}`;
export const EDIT_ADMIN_USER_BY_ID_BASE_URL: string = `${ADD_ADMIN_USER_BASE_URL}/{id}`;
export const UPDATE_USER_PICTURE_BASE_URL: string = `${USER_BY_ID_BASE_URL}/picture`;
export const UPDATE_USER_PASSWORD_BASE_URL: string = `${USER_BY_ID_BASE_URL}/update-password`;
export const UPDATE_USER_STATUS_BASE_URL: string = `${USER_BY_ID_BASE_URL}/status`;
export const UPLOAD_USER_DOCUMETS_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/profile/{id}/upload-document-file`;
export const DELETE_USER_DOCUMETS_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/profile/{documentId}/delete-document-file`;
export const DOWNLOAD_USER_DOCUMETS_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/profile/{documentFileId}/download-document-file`;
export const UPDATE_USER_ADDRESS_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/profile/{id}/address`;
export const UPDATE_USER_DOCUMENT_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/profile/{id}/document`;
export const UPDATE_USER_OFFICIAL_DETAILS_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/profile/{id}/professional-detail`;
export const USER_MANAGEMENT_BULK_STATUS: string = `${USER_MANAGEMENT_BASE_URL}/bulk-status-update`;
export const USER_MANAGEMENT_STATUS_UPDATE: string = `${USER_MANAGEMENT_BASE_URL}/{id}`;
export const DESIGNER_STATUS_UPDATE: string = `${USER_MANAGEMENT_BASE_URL}/admin-user/{id}`;
export const PROFILE_STATUS_UPDATE: string = `${USER_MANAGEMENT_BASE_URL}/profile-status/{id}`;
export const USER_MANAGEMENT_BULK_DELETE: string = `${USER_MANAGEMENT_BASE_URL}/bulk-delete`;
export const USER_MANAGEMENT_EXPORT: string = `${USER_MANAGEMENT_BASE_URL}/export`;
export const USER_MANAGEMENT_DOWNLOAD_TEMPLATE: string = `${USER_MANAGEMENT_BASE_URL}/import/download-template`;
export const USER_MANAGEMENT_IMPORT_EXCEL: string = `${USER_MANAGEMENT_BASE_URL}/import/from-excel`;
export const USER_PROFILE_OVERVIEW: string = `${USER_MANAGEMENT_BASE_URL}/user-profile/overview`;
export const USER_PROFILE_UPDATE_PASSWORD: string = `${USER_PROFILE_OVERVIEW}/update-password`;
export const USER_PROFILE_UPDATE_PICTURE: string = `${USER_PROFILE_OVERVIEW}/update-picture`;
export const REGENERATE_PASSWORD_BY_ID_BASE_URL: string = `${USER_MANAGEMENT_BASE_URL}/regenerate-password/{id}`;

export const GET_COUNTRIES_BASE_URL: string = `system/countries`;
export const GET_CITIES_BASE_URL: string = `system/countries/{countryId}/cities`;
export const SYSTEM_ROLES_BASE_URL: string = `system/roles`;

export const GET_AUDITS_BASE_URL: string = `system/audits`;
export const GET_CATEGORIES_BASE_URL: string = `system/categories`;
export const GET_MODULES_BASE_URL: string = `system/modules`;
