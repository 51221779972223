<form [formGroup]="formGroup" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="name" class="required">
          {{ 'COMPONENTS.CATEGORY.SUB_CATEGORY_TITLE' | translate }}
        </label>
        <input [class.disabled]="pageMode === 'view'" formControlName="name" class="form-control" type="text"
          appNoConsecutiveSpaces id="name" name="name" placeholder="Subcategory Title" />
        <ng-container [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['name'], name: 'name'  }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="description">
          {{ 'COMPONENTS.CATEGORY.SUB_CATEGORY_DESCRIPTION' | translate }}
        </label>
        <input [class.disabled]="pageMode === 'view'" formControlName="description" name="description" id="description"
          appNoConsecutiveSpaces class="form-control" type="text" placeholder="Subcategory Description" />
        <ng-container [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['description'], name: 'description'  }"></ng-container>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="id" [class.required]="hasSpecificRole === false">
          {{ 'COMPONENTS.CATEGORY.SUB_CATEGORY_ID' | translate }}
        </label>
        <input id="id" appNoConsecutiveSpaces [class.disabled]="hasSpecificRole === false" name="id"
          class="form-control" formControlName="id" type="text" readonly placeholder="" 
          [value]="formGroup.controls['id'].value !== null ? formGroup.controls['id'].value.toString() : ''" />
          
        <ng-container [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['id'] }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="order" class="required">
          {{ 'COMPONENTS.CATEGORY.SUB_CATEGORY_ORDER' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="order"
          name="order"
          id="order"
          class="form-control"
          type="text"
          onlyInteger
          min="0"
          max= "99"
          minlength="1" 
          maxlength="2"
          placeholder="Order" />
        <ng-container [ngTemplateOutlet]="reqOrderErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['order'] }"></ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="parentCategoryId" class="required">
          {{ 'COMPONENTS.CATEGORY.CATEGORY' | translate }}
        </label>

        <ng-select [clearSearchOnAdd]="true" [class.disabled]="disableRoles === true"
          class="bg-white w-100 cus-select clear-disable" [searchable]="false" [clearable]="false"
          formControlName="parentCategoryId" bindValue="id" [closeOnSelect]="true" bindLabel="name"
          name="parentCategoryId" placeholder="{{ 'COMPONENTS.CATEGORIES.CATEGORY_NAME' | translate }}">
          <ng-option *ngFor="let category of categoryList" [value]="category.id">
            {{ category.name }}
          </ng-option>
        </ng-select>
        <ng-container [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['parentCategoryId'] }"></ng-container>
      </div>
    </div>
    <div class="col-md-6"></div>
  </div>

  <div *ngIf="pageMode === 'edit'" class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button (click)="setSubCategory()" type="button" class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.RESET' | translate }}
    </button>
    <button type="button" appDebounceClick (debounceClick)="submitSubCategory()"
      class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
    </button>
  </div>

  <div *ngIf="pageMode === 'add'" class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button (click)="setSubCategory('cancel')" type="button" class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.CANCEL' | translate }}
    </button>
    <button type="button" appDebounceClick (debounceClick)="submitSubCategory()"
      class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.CREATE' | translate }}
    </button>
  </div>
</form>

<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
  @if (control?.hasError('required')) {
  <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
  } @else if (control?.hasError('pattern')) {
    <small class="text-danger" *ngIf="control?.hasError('maxlength')">
      <!-- Dynamic error message based on control name -->
      {{ name === 'name' ? ('COMMON.VALIDATION.TITLE_LIMIT_EXCEED' | translate) : '' }}
      {{ name === 'description' ? ('COMMON.VALIDATION.DESCRIPTION_LIMIT_EXCEED' | translate) : '' }}
    </small>
  } @else if (
  name === 'employeeId' && (control?.hasError('minlength') || control?.hasError('maxlength'))
  ) {
  <small class="text-danger">
    {{ 'COMMON.VALIDATION.USER_ID_VALIDATION' | translate }}
  </small>
  }
  }
</ng-template>
<ng-template #reqOrderErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_ORDER_VALUE' | translate }}</small>
    }
  }
</ng-template>
<ng-template #tipContent>
  @if (this.userType === 'app_user') {
  <ul class="mb-0 ps-3">
    <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_SIX_DIGITS' | translate }}</li>
  </ul>
  } @else {
  <ul class="mb-0 ps-3">
    <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_EIGHT_SIXTEEN_CHARACTERS' | translate }}</li>
    <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_CAPITAL' | translate }}</li>
    <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_LOWER' | translate }}</li>
    <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_NUMBER' | translate }}</li>
  </ul>
  }
</ng-template>