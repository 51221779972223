import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { takeUntil } from 'rxjs';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { GET_CITIES_BASE_URL, GET_COUNTRIES_BASE_URL, UserManagementService } from '@src/app/features/user-management/core';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import * as constants from '@src/app/core/constants/system.constant';
import { UPDATE_USER_ADDRESS_BASE_URL } from '../../../core';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import {UserRepository} from '@src/app/features/user-management/core/repositories/user.repository';
import {CountryRepository} from '@src/app/features/user-management/core/repositories/country.repository';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [...COMMON_MODULES, NgSelectModule, NgbDropdownModule, ViewPermissionDirectiveModule],
})
export class UserAddressComponent extends BaseComponent implements OnInit, OnDestroy {
  public countries: any = [];
  public cities: any = [];
  public addressForm: FormGroup = new FormGroup({
    cityId: new FormControl(null, [Validators.required]),
    countryId: new FormControl(null, [Validators.required]),
    address: new FormControl(null, [Validators.required]),
  });
  formBackup = this.helperService.clone(this.addressForm.value);
  /**
   * Constructor for the UserAddressComponent.
   *
   * @param {Injector} injector - The injector instance.
   * @param {UserManagementService} userManagementService - The UserManagementService instance.
   */
  constructor(
    injector: Injector,
    private userManagementService: UserManagementService,
    private userRepository: UserRepository,
    private countryRepository: CountryRepository
  ) {
    super(injector);
    this.getCountries();
    if (this.isEditMode() == null) this.pageMode = 'add';
  }
  /**
   * Listens for changes in the addressForm and updates the isDataModified flag in the userManagementService.
   * Also emits an event through sharedDataService.dataModified with the modified data.
  *
  * @return {void} This function does not return anything.
  */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onChanges(): void {
    this.addressForm.valueChanges.pipe(this.destroy$()).subscribe(val => {
      let formData: any = this.helperService.clone(val);

      this.userManagementService.isDataModified = !this.helperService.isEqual(
        this.formBackup,
        formData,
      );
      this.sharedDataService.dataModified.next({
        component: 'user-address',
        isModified: this.userManagementService.isDataModified,
        data: this.helperService.clone(val),
      });
    });
  }

  /**
   * Component initialization method
   */
  ngOnInit(): void {
    // Subscribe to route data and get user data
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        // If data has user object
        if (data?.user) {
          // Set user to local baseModel
          this.baseModel = this.helperService.clone(data?.user);
          // Set roles data
          this.baseModel.rolesData = this.helperService.clone(data?.user.roles);
          // If user has roles
          if (this.baseModel.rolesData?.length > 0) {
            // Find index of role having code 'super_admin'
            const index = this.baseModel?.rolesData.findIndex(
              (role: any) => role.code === 'super_admin',
            );
            // If index is not -1, set pageMode to 'view'
            if (index !== -1) this.pageMode = 'view';
          }
          // If user has address
          if (this.baseModel?.address) {
            // Set countryId and cityId of address
            this.baseModel.address.countryId = this.baseModel.address?.country?.id;
            this.baseModel.address.cityId = this.baseModel.address?.city?.id;
            // If countryId exists, get cities of that country
            if (this.baseModel.address.countryId) {
              this.countryChanged(this.baseModel.address.countryId);
            }
          }
          // Set address form values from baseModel
          this.setAddress();
        }
      });
    this.onChanges();
  }

  /**
   * Get countries from DB
   *
   * This function makes a GET request to the apiList.GET_COUNTRIES_BASE_URL endpoint
   * and maps the received data to a more convenient format
   * before assigning it to the component's countries property
   */
  getCountries() {
    this.countryRepository
      .getAll()
      .pipe(this.destroy$())
      .subscribe({
        /**
         * Maps the response body to a more convenient format and assigns it to the component's countries property.
         *
         * @param {any} res - The response object from the API call.
         * @return {void} This function does not return anything.
         */
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;

          if (res?.body) {
            this.countries = res.body.map((i: any) => ({
              id: i.id,
              name: i.name[this.sharedDataService.currentLanguage],
            }));
          }
        },
      });
  }

  /**
   * Submit address form
   *
   * This function checks if the address form is valid, if not, it marks all the form
   * fields as touched and shows a message that the user should enter required fields
   *
   * If the form is valid, it makes a PUT request to the API_URL/users/updateAddress/{id}
   * endpoint with the form values as the request body
   *
   * After the request is submitted, it checks the response status and shows a success or
   * error message
   */
  protected submitAddress(): void {
    const { valid, value } = this.addressForm;
    if (!valid) {
      this.addressForm.markAllAsTouched();
      this.httpService.showMessage(
        this.translate.instant('COMMON.VALIDATION.ENTER_REQUIRED_FIELDS'),
        'error',
      );
      return;
    }
    if (valid) {
      let url = UPDATE_USER_ADDRESS_BASE_URL.replace('{id}', this.baseModel.id);
      this.userRepository
        .updateAddress(this.baseModel.id,  value)
        .pipe(this.destroy$())
        .subscribe({
          /**
           * Handles the response from the API call and displays a success or error message.
           *
           * @param {any} res - The response object from the API call.
           * @return {void} This function does not return anything.
           */
          next: (response: unknown) => {
            const res = response as ApiResponseInterface<any>;

            if (res?.status >= 200 && res?.status <= 210) {
              this.formBackup = this.helperService.clone(this.addressForm.value);
              this.userManagementService.isDataModified = false;
              this.sharedDataService.dataModified.next({
                component: 'user-info',
                isModified: this.userManagementService.isDataModified,
                data: this.helperService.clone(res),
              });
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UPDATE_USER_ADDRESS'),
                'success',
              );
            } else {
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_ADDRESS'),
                'error',
              );
            }
          },
          /**
           * Handles the error during API call and displays an error message.
           *
           * @param {any} err - The error object.
           * @return {void} This function does not return anything.
           */
          error: (err: any) => {
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_ADDRESS'),
              'error',
            );
          },
        });
    }
  }

  /**
   * Handle change of country select
   *
   * This function gets the cities of a selected country
   * by making a GET request to the API_URL/cities?countryId={id}
   * and mapping the received data to a more convenient format
   * before assigning it to the component's cities property
   *
   * @param id the selected country id
   */
  countryChanged(id: any): void {
    this.countryRepository
      .getCities(id)
      .pipe(this.destroy$())
      .subscribe({
        /**
         * Maps the response body to a more convenient format and assigns it to the component's cities property.
         *
         * @param {any} res - The response object from the API call.
         * @return {void} This function does not return anything.
         */
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;

          if (res?.body) {
            res.body = res.body.map((i: any) => {
              i.value = i.id;
              i.name = i.name[this.sharedDataService.currentLanguage];
              return i;
            });
            this.cities = res.body;
          }
        },
      });
  }

  /**
   * Set the address form values based on the component's baseModel
   *
   * If the baseModel.address is not null, it sets the address form values to the
   * baseModel.address object by calling FormGroup.patchValue() with { emitEvent: false }
   * and then updates the form value and validity with { emitEvent: false }
   *
   * If the baseModel.address is null, it resets the address form
   */
  setAddress() {
    if (this.baseModel?.address !== null) {
      this.addressForm.patchValue({ ...this.baseModel?.address }, { emitEvent: false });
      this.addressForm.updateValueAndValidity({ emitEvent: false });
      this.formBackup = this.helperService.clone(this.addressForm.value);
    } else {
      this.addressForm.reset();
    }
  }
}
