import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import {
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
  PAGE_SIZE_OPTIONS,
} from '@app/core/constants';
import { SharedDataService } from '@app/core/services';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() page: number = DEFAULT_PAGE_INDEX;
  @Input() size: number = NUMBER_RECORDS_PER_PAGE;
  @Input() totalPages: number = 0;
  @Input() totalElements: number = 0;
  @Input() totalPaginatedElementCount: number = 0;

  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  public pageSizeList: number[] = PAGE_SIZE_OPTIONS;

  constructor(public sharedDataService: SharedDataService) {}

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }

  /**
   * Handles the change event when the page or size is changed.
   *
   * @param {string} type - The type of change ('page' or 'size').
   * @param {number} value - The new value of the page or size.
   */
  handlePageChange(type: string, value: number) {
    switch (type) {
      case 'page':
        this.triggerEvent(type, value);
        break;
      case 'size':
        this.triggerEvent(type, value);
        break;
    }
  }

  /**
   * Triggers an event with the specified type and value.
   *
   * @param {string} type - The type of the event.
   * @param {number} value - The value associated with the event.
   * @return {void} This function does not return a value.
   */
  private triggerEvent(type: string, value: number): void {
    this.pageChange.emit({ type, value });
  }
}
