import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appIntegerInput]',
})
export class IntegerInputDirective {
  private regex: RegExp = /^\d+$/; // Allows only digits

  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
  ) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const inputValue = event?.target['value'];
    if (inputValue.includes('.')) {
      this.el.nativeElement.value = inputValue.replaceAll('.', '');
      this.ngControl.control?.setValue(this.el.nativeElement.value);
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const pastedValue = (event.clipboardData || (<any>window)['clipboardData']).getData('text');

    if (!this.regex.test(pastedValue)) {
      event.preventDefault();
    }
  }
}
