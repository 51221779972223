import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as constants from '@src/app/core/constants/system.constant';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';

import { updateInputBindingOnChanges } from '@app/core/shared/helpers';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnChanges, OnDestroy {
  searchControl: FormControl = new FormControl(null);
  hasValue: boolean = false;

  @Input() placeholder: string = 'Search';
  @Output() searchClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }

  /**
   * Executes when the component is destroyed.
   * Patches the searchControl FormControl with a null value,
   * disabling event emission and only updating the control itself.
   */
  ngOnDestroy() {
    this.searchControl.patchValue(null, { emitEvent: false, onlySelf: true });
  }

  /**
   * Resets the value of the search control, sets hasValue to false, and emits an empty string.
   *
   * @return {void}
   */
  public resetValue() {
    setTimeout(() => {
      this.hasValue = false;
    });
    this.searchControl.patchValue(null, { emitEvent: false, onlySelf: true });
    this.searchClick.emit('');
  }

  /**
   * Emits the search value and updates the hasValue property based on the value.
   *
   * @param {any} val - The value to be emitted and checked for truthiness.
   * @return {void} This function does not return a value.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME)
  search(val: any) {
    this.searchClick.emit(val);
    if (val) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
  }
}
