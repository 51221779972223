import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NewProductPhotosInputComponent } from '../../components/new-product-photos-input/new-product-photos-input.component';
import {
  ProductsActions,
  selectCatalogLoading,
  selectCatalogNewProductId,
  selectCategoriesList,
} from '../../store';
import { first, Observable, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { Category } from '@app/core/api';

@Component({
  selector: 'app-create-product-form',
  templateUrl: './create-product-form.component.html',
  styleUrls: ['./create-product-form.component.scss'],
})
export class CreateProductFormComponent {
  categories$ = this.store.select(selectCategoriesList);
  subCategories$: Observable<Category[]> | undefined;

  addForm = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    description: new FormControl('', {
      nonNullable: true,
    }),
    category: new FormControl('', {
      nonNullable: true,
    }),
    subCategory: new FormControl('', {
      nonNullable: true,
    }),
    price: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0)],
    }),
    stock: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0)],
    }),
    visible: new FormControl('true', {
      nonNullable: true,
    }),
  });
  newProductId$ = this.store.select(selectCatalogNewProductId);

  @ViewChild(NewProductPhotosInputComponent)
  photosInput!: NewProductPhotosInputComponent;

  constructor(private store: Store, private router: Router) {}
  categoryChanged() {
    setTimeout(async () => {
      const selectedCategory = this.addForm.get('category')?.value;
      const categories = await firstValueFrom(this.categories$);
      this.subCategories$ = new Observable((observer) =>
        observer.next(
          categories.map((category: any) => {
            if (category?.parentCategory?.id === selectedCategory) {
              return category;
            }
          }),
        ),
      );
    });
  }
  async save() {
    let productData: any = this.addForm.getRawValue();
    if (productData.subCategory) {
      productData.categoryId = productData.subCategory;
    } else {
      productData.categoryId = productData.category;
    }
    delete productData.subCategory;
    // console.log(productData);
    this.store.dispatch(
      ProductsActions.addProduct({
        data: {
          ...productData,
          visible: this.addForm.value.visible === 'true',
        },
      }),
    );
    this.newProductId$
      .pipe(first((v) => v !== null))
      .subscribe(async (value) => {
        if (value) {
          await this.savePhotos(value);
        }
      });
  }

  private async savePhotos(productId: number) {
    for (const file of this.photosInput.photosToSave) {
      this.store.dispatch(
        ProductsActions.addProductPhoto({
          productId,
          data: file,
        }),
      );
    }
    this.store
      .select(selectCatalogLoading)
      .pipe(first((v) => !v))
      .subscribe(() => {
        this.router.navigate(['/catalog/products', productId]);
      });
  }
}
