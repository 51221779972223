import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@app/core/shared/shared.module';
import { SearchBarModule } from '@components/search-bar/search-bar.module';
import { FiltersComponent } from '@components/filters/filters.component';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FiltersComponent],
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    SearchBarModule,
    SharedModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    TranslateModule,
  ],
  exports: [FiltersComponent],
})
export class FiltersModule {}
