import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './views/profile/profile.component';
import {UnsavedDataChangesGuard} from '@src/app/core/guards/unsaved-data-changes.guard';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    canDeactivate: [UnsavedDataChangesGuard],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
