import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { updateInputBindingOnChanges } from '@app/core/shared/helpers';
import { HelperService } from '@src/app/core/services/helper.service';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: any = '';
  @Input() options: any = {};
  @Input() defaultLabel: string = '';
  @Input() showLabel: any = true;
  @Input() value: boolean = false;
  @Input() disabled = false;
  @Input() className = '';
  @Input() labelClass = '';
  @Input() name: any = '';
  @Input() type: any = 'checkbox';

  @Output() onCheckedChanged: EventEmitter<any> = new EventEmitter<any>();

  public selectedValue: boolean = false;

  constructor(private helperService: HelperService) {}

  /**
   * Initializes the component and sets the initial value of the 'value' property if it is not provided.
   * Also sets the 'selectedValue' property to a clone of the 'value' property using the 'helperService'.
   *
   * @return {void} This function does not return a value.
   */
  ngOnInit(): void {
    if (!this.value) {
      this.value = false;
    }
    this.selectedValue = this.helperService.clone(this.value);
  }

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   * @return {void} This function does not return anything.
   */
  ngOnChanges(changes: SimpleChanges): void {
    updateInputBindingOnChanges(this, changes, (newInputs: any) => {
      Object.assign(this, newInputs);
      if (newInputs.hasOwnProperty('value')) {
        this.selectedValue = this.helperService.clone(newInputs.value || false);
      }
    });
  }

  /**
   * Executes when the component is destroyed.
   * Resets all properties to their default values.
   */
  ngOnDestroy() {
    this.id = '';
    this.options = {};
    this.showLabel = true;
    this.value = false;
    this.disabled = false;
    this.className = '';
    this.labelClass = '';
    this.name = '';
    this.type = 'checkbox';
    this.selectedValue = false;
  }

  /**
   * Handles the change event when the toggle switch is clicked.
   *
   * @param {boolean} checked - The new checked state of the toggle switch.
   * @return {void} This function does not return anything.
   */
  public handleChangeEvent(checked: boolean): void {
    this.selectedValue = checked;
    this.onCheckedChanged.emit(checked);
  }

  /**
   * Returns the label value based on the selected value and options.
   *
   * @return {any} The label value, or the default label value if no match is found, or an empty string if both are undefined.
   */
  public get label(): any {
    return this.options[`${this.selectedValue}`] || this.defaultLabel || '';
  }
}
