import { Routes, RouterModule } from '@angular/router';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { StoreManagementComponent } from './store-management.component';
import { StoreListingComponent } from './views/store-listing/store-listing.component';
import { StoreDetailComponent } from './views/store-detail/store-detail.component';
import { StoreResolver } from './core/resolvers/store-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: StoreManagementComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: StoreListingComponent,
      },
      {
        path: ROUTE_LIST.VIEW_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: StoreDetailComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: StoreDetailComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: StoreDetailComponent,
        resolve: {
          store: StoreResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
