import { Routes, RouterModule } from '@angular/router';
import { HasPermissionGuard } from '@src/app/core/guards';
import { PERMISSIONS_LIST } from '@src/app/core/constants';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { UnsavedDataChangesGuard } from '@src/app/core/guards/unsaved-data-changes.guard';
import { UserProfileComponent } from '@app/features/user-management/views/user-profile/user-profile.component';
import { AppUserListingComponent } from '@app/features/user-management/views/app-users-listing/app-users-listing.component';
import { UserDetailsResolver } from '@app/features/user-management/core/resolvers/user-details-resolver.service';
import { AppUserManagementComponent } from './app-user-management.component';

const routes: Routes = [
  {
    path: '',
    component: AppUserManagementComponent,
    children: [
      {
        path: '',
        canActivate: [HasPermissionGuard],
        data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: AppUserListingComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        canActivate: [HasPermissionGuard],
        data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        canDeactivate: [UnsavedDataChangesGuard],
        component: UserProfileComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        canActivate: [HasPermissionGuard],
        data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        canDeactivate: [UnsavedDataChangesGuard],
        component: UserProfileComponent,
        resolve: {
          user: UserDetailsResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
