
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { BaseComponent } from '@src/app/core/base';
import {  takeUntil, debounceTime, distinctUntilChanged } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { DEFAULT_DEBOUNCE_TIME } from '@src/app/core/constants';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';

import * as constants from '@src/app/core/constants/system.constant';

import { SystemRepository } from '@src/app/features/user-management/core/repositories/system.repository';
import { UserManagementService } from '@src/app/features/user-management/core';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { CategoryRepository } from '@src/app/features/categories/core/repositories/category.repository';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import {CATEGORY_STATUS_UPDATE} from '@src/app/features/categories/core';

export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}

@Component({
  selector: 'brand-detail',
  templateUrl: './brands-detail.component.html',
  styleUrls: ['./brands-detail.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgbDropdownModule,
    NgbTooltipModule,
    AppStatus,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
  ],
  providers: [DatePipe],
})
export class BrandDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  public todayDate: string = new Date().toISOString().split('T')[0];
  public minEighteen: string = '';
  public maxEighty: string = '';
  public CountryISO = CountryISO;
  public profileImage: any = 'assets/images/user-profile-placeholder.png';
  public image: any = null;
  public phoneNumberFormat = PhoneNumberFormat;
  public SearchCountryField = SearchCountryField;
  public rolesList: any = Role;
  public orderlist: any = Array.from({ length: 16 }, (_, index) => index + 1);
  public column: any = {
    key: 'status',
    value: 'COMPONENTS.CATEGORIES.STATUS',
    headingClass: 'ps-4',
    type: 'status',
    sortable: false,
    defaultTitle: true,
    options: [
      {
        label: 'GENERAL.BUTTONS.ACTIVATE',
        value: 'active',
        icon: 'tick-circle',
      },
      {
        label: 'GENERAL.BUTTONS.DEACTIVATE',
        value: 'inactive',
        icon: 'close-circle',
      },
    ],
    endpoint: CATEGORY_STATUS_UPDATE,
    success: this.translate.instant('MSGS.CATEGORY.UPDATE_CATEGORY_STATUS'),
    successSecond: this.translate.instant('MSGS.CATEGORY.UPDATE_CATEGORY_STATUS_DEACTIVATED'),
    alert: this.translate.instant('MSGS.CATEGORY.ARE_YOU_SURE_ACTIVATE'),
    alertSecond: this.translate.instant('MSGS.CATEGORY.ARE_YOU_SURE_DEACTIVATE'),
    error: this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_CATEGORY_STATUS'),
  };

  public formGroup: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    file: new FormControl(null),
    order: new FormControl([], [Validators.required]),
  });
  formBackup = this.helperService.clone(this.formGroup.value);
  public hasSpecificRole: boolean = false;
  public userType: any = 'admin_user';
  public disableRoles: boolean = false;
  public isLoading: boolean = false;

  /**
   * Constructor of the UserInfoComponent
   * @param injector The injector.
   * @param datePipe The DatePipe.
   */
  constructor(
    injector: Injector,
    private datePipe: DatePipe,
    private userManagementService: UserManagementService,
    private categoryRepository: CategoryRepository,
    private systemRepository: SystemRepository,
    private pageSettingsService: PageSettingsService, // Service for managing page settings
  ) {
    super(injector);
    // If the page is not in edit mode, set the pageMode to 'add'
    if (this.isEditMode() == null) {
      this.pageMode = 'add';
    }
    // Get the current date
    const currentDate = moment();
    // Set the minimum date to 18 years ago
    this.minEighteen = currentDate.subtract(80, 'years').format('YYYY-MM-DD');
    // Set the maximum date to 80 years ago
    this.maxEighty = currentDate.subtract(18 - 80, 'years').format('YYYY-MM-DD');

    // Creating new page settings for the user management page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name: 'COMPONENTS.CATEGORIES.ADD_CATEGORY', // Name for the breadcrumb
        link: this.routeList.CATEGORIES_NEW + '/add', // Link for the breadcrumb
      },
    };

    // Using the page settings service to update the page settings
    this.pageSettingsService.updatePageSettings(newSettings);
  }
  /**
   * Listens for changes in the form group and updates the form backup and data modified flag.
   *
   * @return {void} This function does not return anything.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onChanges(): void {
    this.formGroup.valueChanges.pipe(this.destroy$()).subscribe(val => {
      if (this.formBackup !== 'reset') {
        let formData: any = this.helperService.clone(val);

        formData.phoneNumber =
          formData.phoneNumber && formData.phoneNumber.number
            ? formData.phoneNumber.number
            : formData.phoneNumber;
        formData.landlineNumber =
          formData.landlineNumber && formData.landlineNumber.number
            ? formData.landlineNumber.number
            : formData.landlineNumber;
        this.userManagementService.isDataModified = !this.helperService.isEqual(
          this.formBackup,
          formData,
        );
        this.sharedDataService.dataModified.next({
          component: 'user-info',
          isModified: this.userManagementService.isDataModified,
          data: this.helperService.clone(val),
        });
      }
    });
  }

  /**
   * @description
   * Initializes component and retrieves user data from route resolver
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });
    /**
     * Retrieves user data from route resolver
     * Stores user data in component property `baseModel`
     * Also stores a copy of user's roles in `baseModel.rolesData`
     * Checks if user is a super admin
     *   and sets `pageMode` property to 'view' if true
     * Converts user's role IDs to an array of strings
     * Removes '+' prefix from phone numbers if present
     * Formats phone numbers to E.164 format
     * Removes 'null' and '+null' from landlineNumber
     * Formats date of birth to ISO format
     * Calls `setProfile`
     */
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.store) {
          this.baseModel = this.helperService.clone(data?.store);

          this.setProfile();
        }
      });

    // this.onChanges();
  }

  /**
   * Reset the password form fields
   *
   * @param form The form to reset
   */
  resetPasswordForm(form: any) {
    // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    form.reset(); // Reset the form fields
  }

  /**
   * Get role details by ID
   *
   * @param id The ID of the role
   * @returns The role details if found, empty object otherwise
   */
  getRoleDetailsbyId(id: any): any {
    const index = this.rolesList.findIndex((s: any) => s.id == id);
    if (index !== -1) {
      return this.rolesList[index];
    } else {
      return {}; // Empty object if not found
    }
  }

  /**
   * Finds all invalid controls in the given form
   * @param form The form to search for invalid controls
   * @returns The names of the invalid controls
   */
  public findInvalidControls(form: any): string[] {
    const invalid = []; // The names of the invalid controls
    const controls = form.controls; // The controls of the given form

    // Loop through all the controls in the form
    for (const name in controls) {
      // If the control is invalid
      if (controls[name].invalid) {
        // Add its name to the list of invalid controls
        invalid.push(name);
      }
    }
    return invalid;
  }

    /**
   * Updates the status of the segment.
   *
   * @param {string} status - The new status of the segment.
   * @return {void} This function does not return anything.
   */
    public updateStatus(status: string) {

      this.httpService
        .requestEntity(
          'PATCH',
          CATEGORY_STATUS_UPDATE.replace('{id}', this.baseModel?.id),
          {
            status: status,
          },
        )
        .pipe(this.destroy$())
        .subscribe({
          /**
           * Handles the next callback of the subscription.
           *
           * @param {any} res - The response object.
           * @return {void} This function does not return anything.
           */
          next: (res: any) => {
            if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
              this.httpService.showMessage(
                this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
                'success',
              );
              this.baseModel.isActivated = res.body?.isActivated;
              
            }
          },
        });
    }

  /**
   * Submits the user profile based on the specified type.
   *
   * @param {string} type - The type of submission ('draft' or 'create').
   * @return {void} This function does not return a value.
   */
  protected onSubmit(type: string): void {
    let { valid, value } = this.formGroup;
    if(!this.image?.file){
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
      return;
    }
    value.file = this.image?.file;

    let formData = new FormData();
    formData.append('name', value.name);
    formData.append('description', value.description);
    formData.append('file', this.image.file);
    formData.append('order', value.order);

    if (valid) {
      // Determine API endpoint and HTTP method based on whether it's an update or creation
      let apiContainer = this.categoryRepository.create(formData);
      if (this.baseModel?.id) {
        apiContainer = this.categoryRepository.update(this.baseModel.id, formData);
      }

      apiContainer
        .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
        .subscribe({
          /**
           * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
           * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
           *
           * @param {any} res - The response object.
           * @return {Promise<void>} A promise that resolves when the function completes.
           */
          next: async (res: any) => {
            this.httpService.showMessage(
              `${
                type === 'draft'
                  ? this.translate.instant('MSGS.USERS.SAVE_AS_DRAFT_TEXT')
                  : this.translate.instant('COMPONENTS.CATEGORIES.SUCCESS_MESSAGE')
              }`,
              'success',
            );
            this.router.navigate([this.routeList.CATEGORIES_NEW]);
          },
        });
    }
  }

  /**
   * Sets the form values to the base model
   *
   * This function is called in the ngOnInit lifecycle hook,
   * after getting the user profile from the API.
   *
   * It patches the formGroup with the baseModel values,
   * and updates the formGroup validity to emit events.
   */
  setProfile(id?: string) {
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    if (id === 'cancel') {
      this.formGroup.patchValue({}, { emitEvent: false });
      this.helperService.goToRoute(this.routeList.CATEGORIES_NEW);
    } else {
      this.formGroup.patchValue({ ...this.baseModel }, { emitEvent: false });
    }
  }

  /**
   * Handles file input change event
   * @param event file input change event
   */
  onPictureUpload(event: any) {
    if (!event.target.files[0]?.type.includes('image')) {
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
      return;
    }
    // Set the selected image details to the image variable
    this.image = {
      file: event.target.files[0],
      fileName: event.target.files[0]?.name,
    };
    // Create an instance of FileReader
    const reader = new FileReader();
    // Set the function to be called when loading is complete
    reader.onloadend = () => {
      // Set the image source to the result of reading the image
      this.profileImage = reader.result;
    };
    // Read the image as a data url
    reader.readAsDataURL(new Blob([event.target.files[0]], { type: event.target.files[0].type }));
  }
}
