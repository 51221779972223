import { Component } from '@angular/core';

@Component({
  selector: 'app-user-management-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent {
  constructor() {}
}
