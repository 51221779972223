export const USER_STATUS: any = {
  active: 'Active',
  inactive: 'Inactive',
  draft: 'Pending',
  unverified: 'Unverified',
};

export const PROFILE_STATUS: any = {
  accepted: 'Accepted',
  rejected: 'Rejected',
  pending_review: 'Pending Review',
  incomplete: 'Incomplete',
};

export const CATEGORY_STATUS: any = {
  active: 'Active',
  inactive: 'Inactive'
};
export const TAGS_TABS: any = {
  style: 'style',
  finish: 'finish',
  base_material: 'base_material'
};

export const CITIES: any[] = [
  'Dakar',
  'Thiès',
  'Kaolack',
  'Ziguinchor',
  'Saint-Louis',
  'Touba',
  'Rufisque',
  'Fatick',
  'Mbour',
  'Diourbel',
  'Louga',
  'Richard-Toll',
  'Tambacounda',
  'Kolda',
  'Mbacké',
  'Matam',
  'Kaffrine',
  'Saint-Louis',
  'Joal-Fadiouth',
  'Dara',
  'Gossas',
  'Bignona',
  'Guinguinéo',
  'Sédhiou',
];
