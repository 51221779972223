import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UnsavedDataChangesGuard } from '@src/app/core/guards/unsaved-data-changes.guard';
import { ModuleRoutes } from './profile.routes';

@NgModule({
  declarations: [],
  imports: [CommonModule, ModuleRoutes, RouterModule],
  providers: [provideClientHydration(), UnsavedDataChangesGuard],
})
export class ProfileModule {}
