import { BaseComponent } from '@app/features/user-management/views/base/base.component';
import { NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { ModuleRoutes } from '@app/features/user-management/user-management.routes';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BaseResolver } from '@src/app/core/resolvers/base-resolver';
import { UnsavedDataChangesGuard } from '@src/app/core/guards/unsaved-data-changes.guard';

@NgModule({
  declarations: [BaseComponent],
  imports: [CommonModule, ModuleRoutes, TranslateModule],
  providers: [provideClientHydration(), BaseResolver, UnsavedDataChangesGuard],
})
export class UserManagementModule {}
