import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/dashboard.component').then(x => x.DashboardComponent),
    data: {
      title: 'Dashboard',
      breadcrumb: [
        {
          label: 'Dashboard',
          url: '',
        },
      ],
    },
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
