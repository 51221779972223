<div class="user-profile">
  <app-user-about></app-user-about>
  <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ 'COMPONENTS.USERS.USER_PROFILE' | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-about></app-user-about>
      </ng-template>
    </li>
 <li [ngbNavItem]="2" [disabled]="pageMode === 'add'" *ngIf="user_Type === 'admin_user'">
      <a ngbNavLink>{{ 'COMPONENTS.USERS.USER_ACTIVITIES' | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-activities [userId]="baseModel?.id"></app-user-activities>
      </ng-template>
    </li> 
  </ul>
  <div [ngbNavOutlet]="nav" class="py-4 px-0"></div> -->
</div>
