<div class="mx-3">
  <h4 class="tx-16 mb-4 fw-500">
    {{ 'COMPONENTS.USERS.ABOUT' | translate }}
  </h4>
  <form [formGroup]="formGroup" autocomplete="new-password">
    <div class="d-flex gap-3 align-items-center mb-4">
      <div class="wd-100">
        <img
          [src]="profileImage || 'assets/images/user-profile-placeholder.png'"
          class="rounded-circle w-100 user-img"
          alt="user" />
      </div>
      <input
        type="file"
        id="upload1"
        disabled
        hidden
        (change)="onPictureUpload($event)"
        accept="image/png, image/jpg, image/jpeg" />

      <label role="button" for="upload1" class="btn btn-outline btn-outline-primary btn-140">
        {{ 'COMPONENTS.USERS.UPDATE' | translate }}
      </label>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="firstName">
            {{ 'COMPONENTS.USERS.FIRST_NAME' | translate }}
          </label>
          <input
            [class.disabled]="true"
            class="form-control"
            type="text"
            id="firstName"
            name="firstName"
            [value]="(user$ | async)?.firstName"
            placeholder="Charly" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="lastName">
            {{ 'COMPONENTS.USERS.LAST_NAME' | translate }}
          </label>
          <input
            [class.disabled]="true"
            class="form-control"
            name="lastName"
            id="lastName"
            [value]="(user$ | async)?.lastName"
            type="text"
            placeholder="Robinson" />
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="dob">
            {{ 'COMPONENTS.USERS.DATE_OF_BIRTH' | translate }}
          </label>
          <input
            [class.disabled]="true"
            type="date"
            name="dob"
            [value]="user?.dob"
            class="form-control" />
        </div>
      </div> -->
      <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="email">
            {{ 'COMPONENTS.USERS.EMAIL' | translate }}
          </label>
          <input
            id="email"
            [class.disabled]="true"
            name="email"
            class="form-control"
            [value]="(user$ | async)?.email"
            type="email"
            readonly
            placeholder="charly.robinson@domain.com" />
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-6">
        <div class="form-field mb-4">
          <label htmlFor="employeeId">
            {{ 'COMPONENTS.USERS.USER_ID' | translate }}
          </label>
          <input
            id="employeeId"
            [class.disabled]="true"
            name="employeeId"
            class="form-control"
            [value]="user?.employeeId"
            type="text"
            placeholder="12345678" />
        </div>
      </div> -->
      <!-- <div class="col-md-6">
        <div class="mb-4">
          <div [formGroup]="passwordForm" class="form-field password-field">
            <div class="label-info">
              <label htmlFor="password" class="required">
                {{ 'COMPONENTS.USERS.PASSWORD' | translate }}
              </label>
              <button
                type="button"
                class="btn-transparent ms-2 tooltip-btn"
                tooltipClass="tooltip-info-details"
                placement="right"
                [ngbTooltip]="tipContent">
                <i class="middle icon-info-circle-outline f-18"></i>
              </button>
            </div>
            <input
              formControlName="password"
              class="form-control"
              placeholder="{{ 'COMPONENTS.USERS.NEW_PASSWORD' | translate }}"
              appNoConsecutiveSpaces
              name="password"
              readonly
              onfocus="this.removeAttribute('readonly');"
              [type]="passwordType"
              autocomplete="new-password"
              id="password"
              required />
            <button type="button" class="password-toggle" (click)="passwordToggle()">
              <i
                class="isax"
                [ngClass]="passwordType === 'password' ? 'isax-eye4' : 'isax-eye-slash5'"></i>
            </button>

            <ng-container
              [ngTemplateOutlet]="reqErrRef"
              [ngTemplateOutletContext]="{
                control: passwordForm.controls['password']
              }"></ng-container>
          </div>

          <div class="d-flex justify-content-end pt-2">
            <a (click)="onPasswordChange()" class="btn-link text-primary text-underline">
              {{ 'COMPONENTS.USERS.CHANGE_PASSWORD' | translate }}
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </form>
</div>

<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    }
  }
</ng-template>

<ng-template #tipContent>
  <ul class="mb-0 ps-3">
    <li class="tx-12">{{ 'COMPONENTS.USERS.LENGTH_EIGHT_SIXTEEN_CHARACTERS' | translate }}</li>
    <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_CAPITAL' | translate }}</li>
    <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_LOWER' | translate }}</li>
    <li class="tx-12">{{ 'COMPONENTS.USERS.ONE_NUMBER' | translate }}</li>
  </ul>
</ng-template>
