import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';

import {
  CATEGORY_BASE_URL,
  CATEGORY_BY_ID_BASE_URL,
} from '@src/app/features/categories/core/constants/apis_list';
import { LocalStorageService } from '@src/app/core/services/local-storage.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CategoryRepository {
  constructor(
    private httpService: HttpService,
    private localService: LocalStorageService,
  ) {}

  create(body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'POST',
      CATEGORY_BASE_URL,
      body,
      this.httpService.multiPartFormDataHeaders,
    );
  }
  update(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('PATCH', CATEGORY_BY_ID_BASE_URL.replace('{id}', id), body, this.httpService.multiPartFormDataHeaders);
  }
  updateUserDetail(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('PUT', CATEGORY_BY_ID_BASE_URL.replace('{id}', id), body);
  }

  getById(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', CATEGORY_BY_ID_BASE_URL.replace('{id}', id));
  }

  search(filter = {}): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', CATEGORY_BASE_URL, filter);
  }

  delete(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('DELETE', CATEGORY_BY_ID_BASE_URL.replace('{id}', id));
  }
}
