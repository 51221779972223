import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { BaseComponent } from '@src/app/core/base';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { OnlyNumberDirectiveModule } from '@src/app/core/shared/directives';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { UserManagementService } from '@src/app/features/user-management/core';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import * as constants from '@src/app/core/constants/system.constant';
import {
  DELETE_USER_DOCUMETS_BASE_URL,
  DOWNLOAD_USER_DOCUMETS_BASE_URL,
  UPDATE_USER_DOCUMENT_BASE_URL,
  UPLOAD_USER_DOCUMETS_BASE_URL,
} from '../../../core';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { UserRepository } from '@src/app/features/user-management/core/repositories/user.repository';
@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [...COMMON_MODULES, OnlyNumberDirectiveModule, ViewPermissionDirectiveModule],
  providers: [DatePipe],
})
export class UserDocumentsComponent extends BaseComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  public todayDate: string = new Date().toISOString().split('T')[0];
  public deletedFiles: any = [];
  public documentFiles: any = [];

  public documentForm: FormGroup = new FormGroup({
    drivingLicenseNumber: new FormControl(null, [Validators.required]),
    drivingLicenseExpiryDate: new FormControl(null, [Validators.required]),
    nationalIdentityNumber: new FormControl(null, [Validators.required]),
  });
  formBackup = this.documentForm.value;
  /**
   * Constructor for the UserDocumentsComponent.
   *
   * @param {Injector} injector - The injector instance.
   * @param {DatePipe} datePipe - The DatePipe instance.
   * @param {UserManagementService} userManagementService - The UserManagementService instance.
   */
  constructor(
    injector: Injector,
    private datePipe: DatePipe,
    private userManagementService: UserManagementService,
    private userRepository: UserRepository,
  ) {
    super(injector);
    if (this.isEditMode() == null) this.pageMode = 'add';
  }
  /**
   * Listens for changes in the documentForm and updates the isDataModified flag in the userManagementService.
   * Also emits an event through sharedDataService.dataModified with the modified data.
   *
   * @return {void} This function does not return anything.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onChanges(): void {
    this.documentForm.valueChanges.pipe(this.destroy$()).subscribe(val => {
      this.userManagementService.isDataModified = !this.helperService.isEqual(
        this.formBackup,
        this.helperService.clone(val),
      );
      this.sharedDataService.dataModified.next({
        component: 'user-documents',
        isModified: this.userManagementService.isDataModified,
        data: this.helperService.clone(val),
      });
    });
  }
  /**
   * Initialization of component after Angular initializes the data-bound input properties.
   * Subscribes to the route data and sets the pageMode, baseModel and userDocumentFiles properties.
   */
  ngOnInit(): void {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        // If the data has a user property
        if (data?.user) {
          // Clone the data.user property to the baseModel property
          this.baseModel = this.helperService.clone(data?.user);
          // Clone the data.user.roles property to the baseModel.rolesData property
          this.baseModel.rolesData = this.helperService.clone(data?.user.roles);
          // If the baseModel.rolesData property has elements
          if (this.baseModel?.rolesData?.length > 0) {
            // Find the index of the role with code 'super_admin'
            const index = this.baseModel?.rolesData.findIndex(
              (role: any) => role.code === 'super_admin',
            );
            // If found
            if (index !== -1) {
              // Set the pageMode property to 'view'
              this.pageMode = 'view';
            }
          }
          // If the baseModel has a document property
          if (this.baseModel?.document) {
            // Set the document.drivingLicenseExpiryDate property to the moment object of the input date with format 'DD/MM/YYYY'
            this.baseModel.document.drivingLicenseExpiryDate = moment(
              this.baseModel.document?.drivingLicenseExpiryDate,
              'DD/MM/YYYY',
            );
            // Set the document.drivingLicenseExpiryDate property to the moment object formatted as 'YYYY-MM-DD'
            this.baseModel.document.drivingLicenseExpiryDate =
              this.baseModel?.document.drivingLicenseExpiryDate.format('YYYY-MM-DD');
            // If the baseModel.document has document files
            if (this.baseModel?.document?.userDocumentFiles?.length > 0) {
              // Set the documentFiles property to the document.userDocumentFiles property
              this.documentFiles = this.baseModel.document.userDocumentFiles;
            }
          }
          // Call the setDocuments method
          this.setDocuments();
        }
      });
    this.onChanges();
  }

  /**
   * Submits the user documents form if it is valid.
   *
   * @return {void} This function does not return anything.
   */
  protected submitDocuments(): void {
    // Destructure valid and value from documentForm
    const { valid, value } = this.documentForm;

    // Check if form is invalid
    if (!valid) {
      // Mark all form fields as touched to trigger validation messages
      this.documentForm.markAllAsTouched();

      // Show error message
      this.httpService.showMessage(
        this.translate.instant('COMMON.VALIDATION.ENTER_REQUIRED_FIELDS'),
        'error',
      );

      // Exit function early
      return;
    }

    // If form is valid
    if (valid) {
      // Transform date format for driving license expiry date
      value.drivingLicenseExpiryDate = this.datePipe.transform(
        value.drivingLicenseExpiryDate,
        'dd/MM/yyyy',
      );

      // Construct URL for updating user documents
      let apiContainer = this.userRepository.updateUserDocumentFile(this.baseModel.id, value);

      // Initialize array to store files
      let filesArray: any = [];

      // Filter documentFiles to get files and add them to filesArray
      this.documentFiles?.filter((file: any) => {
        if (file?.file) {
          filesArray.push(file?.file);
        }
      });

      // Create FormData object for file upload
      const formData: any = new FormData();
      filesArray.forEach((file: any) => {
        formData.append('documents', file);
      });

      // Construct URL for uploading user documents
      let url1 = UPLOAD_USER_DOCUMETS_BASE_URL.replace('{id}', this.baseModel.id);

      // Create HTTP request for uploading documents
      const uploadRequest = this.userRepository.updateUserDocument(this.baseModel.id, formData);

      // Array to store both update and upload requests
      const requestArray = [];
      requestArray.push(apiContainer);

      // Add upload request to array if filesArray has files
      if (filesArray?.length > 0) {
        requestArray.push(uploadRequest);
      }

      // Execute both requests concurrently
      forkJoin(requestArray)
        .pipe(this.destroy$())
        .subscribe({
          /**
           * Handles the response from the server after submitting user documents.
           *
           * @param {any} responses - An array of responses from the server.
           * @return {void} This function does not return anything.
           */
          next: (responses: any) => {
            let uploadResponse: any = null;
            this.formBackup = this.helperService.clone(this.documentForm.value);
            this.userManagementService.isDataModified = false;
            this.sharedDataService.dataModified.next({
              component: 'user-info',
              isModified: this.userManagementService.isDataModified,
              data: this.helperService.clone(responses),
            });
            const updateResponse = responses[0];
            if (requestArray.length == 2) {
              uploadResponse = responses[1];
            }
            if (
              requestArray?.length == 1 &&
              updateResponse?.status >= 200 &&
              updateResponse?.status <= 210
            ) {
              // Show success message for updating user documents
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UPDATE_USER_DOCUMENTS'),
                'success',
              );
            } else if (
              requestArray?.length == 2 &&
              updateResponse?.status >= 200 &&
              updateResponse?.status <= 210 &&
              uploadResponse?.status >= 200 &&
              uploadResponse?.status <= 210
            ) {
              // Show success message for updating and uploading user documents
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UPDATE_UPLOAD_USER_DOCUMENTS'),
                'success',
              );
            } else {
              // Show error message if update or upload failed
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_DOCUMENTS'),
                'error',
              );
            }
          },
          /**
           * Show an error message if the HTTP request fails.
           *
           * @param {any} err - The error object.
           */
          error: (err: any) => {
            // Show error message if HTTP request fails
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_DOCUMENTS'),
              'error',
            );
          },
        });
    }
  }

  /**
   * Delete a document
   * @param file The document to delete
   * @param index The index of the document in the documents list
   */
  onDocumentDelete(file: any, index: number) {
    if (file?.id) {
      // Add the document id to the deleted files array
      this.deletedFiles.push(file?.id);
      // Remove the document from the documents list
      this.documentFiles = this.documentFiles.filter((files: any) => files?.id !== file?.id);
      // Replace the {documentId} placeholder with the document id in the delete url

      // Make a delete request to the url
      this.userRepository
        .deleteDocument(this.deletedFiles[0])
        .pipe(this.destroy$())
        .subscribe({
          // Handle the response
          next: (response: unknown) => {
            const res = response as ApiResponseInterface<any>;

            if (res?.status >= 200 && res?.status <= 210) {
              this.formBackup = this.helperService.clone(this.documentForm.value);
              this.userManagementService.isDataModified = false;
              this.sharedDataService.dataModified.next({
                component: 'user-info',
                isModified: this.userManagementService.isDataModified,
                data: {},
              });
              // If the status is between 200-210, reset the deleted files array
              // and show a success message
              this.deletedFiles = [];
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.DELETE_DOCUMENT_SUCCESS'),
                'success',
              );
            } else {
              // If the status is not between 200-210, show an error message
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_DOCUMENT'),
                'error',
              );
            }
          },
          // Handle errors
          error: err => {
            // Show an error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_DOCUMENT'),
              'error',
            );
          },
        });
    } else if (file?.file) {
      // If the document is a base64 string, remove it from the documents list
      this.documentFiles.splice(index, 1);
    }
  }

  /**
   * Download a file
   * @param url The url of the file to download
   * @param filename The name of the downloaded file
   */
  download(url: any, filename: any) {
    fetch(url).then(function (t) {
      return t.blob().then(b => {
        // Create a link
        const a = document.createElement('a');
        // Set the URL to the downloaded file
        a.href = URL.createObjectURL(b);
        // Set the filename
        a.setAttribute('download', filename);
        // Dispatch click event to trigger download
        a.click();
      });
    });
  }

  /**
   * Download a file
   * @param fileData File data containing id or file
   */
  downloadMyFile(fileData: any) {
    // If the file id is present, download the file from the server
    if (fileData?.id) {
      this.userRepository
        .downloadDocuments(fileData?.id)
        .pipe(this.destroy$())
        .subscribe({
          /**
           * Handles the response from the server after downloading a document.
           *
           * @param {any} res - The response object from the server.
           * @return {void} This function does not return anything.
           */
          next: (response: unknown) => {
            const res = response as ApiResponseInterface<any>;

            if (res.status === this.constantList.SUCCESS_STATUS) {
              let s3FileUrl = res?.body?.url;
              this.download(s3FileUrl, fileData?.fileName);
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.DOWNLOAD_DOCUMENT_SUCCESS'),
                'success',
              );
            } else {
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UNABLE_TO_DOWNLOAD_DOCUMENT'),
                'error',
              );
            }
          },
          error: err => {
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_DOWNLOAD_DOCUMENT'),
              'error',
            );
          },
        });
    }

    // If the file itself is present, download it directly
    else if (fileData?.file) {
      const blob = new Blob([fileData?.file], { type: fileData.file.type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileData.fileName;
      link.click();
    }

    // If neither file id nor file is present, show an error message
    else {
      this.httpService.showMessage(
        this.translate.instant('MSGS.USERS.UNABLE_TO_DOWNLOAD_DOCUMENT'),
        'error',
      );
    }
  }

  /**
   * Sets the document form values and document file list from the base model
   */
  setDocuments() {
    if (this.baseModel?.document !== null) {
      // Patch the document form with the values from the base model
      this.documentForm.patchValue({ ...this.baseModel?.document }, { emitEvent: false });
      // Update the form validity
      this.documentForm.updateValueAndValidity({ emitEvent: false });
      // If there are any document files, set the file list to them
      if (this.baseModel?.document?.userDocumentFiles?.length > 0) {
        this.documentFiles = this.baseModel.document.userDocumentFiles;
      } else {
        // Else set the file list to an empty array
        this.documentFiles = [];
      }
    } else {
      // If the base model doesn't have a document, reset the form
      this.documentForm.reset();
    }
    // Set the deleted file list to an empty array
    this.deletedFiles = [];
    this.formBackup = this.helperService.clone(this.documentForm.value);
  }

  /**
   * Handles file input change event
   *
   * @param event file input change event
   */
  onFileChange(event: any) {
    // Loop through the selected files
    for (const file of event.target.files) {
      // Check if the file is a pdf or image
      if (!file.type.includes('pdf') && !file.type.includes('image')) {
        // Show error message
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_FILE'), 'error');
        // Return from the function
        return;
      }
    }
    // Loop through the selected files again
    for (const file of event.target.files) {
      const formattedMoment = moment(file.lastModifiedDate).format('DD/MM/YYYY HH:mm');
      this.documentFiles.push({
        file,
        fileName: file.name,
        createdAt: formattedMoment || '-',
      });
    }
  }
}
