import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appIgnoreWhitespace]',
})
export class PreventKeyboardKeysDirective {
  @Input() preventKeys: number[] = [];

  /**
   * Handles the keydown event and prevents the default behavior if the pressed key is included in the preventKeys array.
   *
   * @param {KeyboardEvent} event - The keydown event object.
   * @return {void} This function does not return anything.
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.preventKeys?.length > 0 && this.preventKeys.includes(event.keyCode)) {
      event.preventDefault();
    }
  }
}
