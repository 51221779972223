import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxdModule } from '@ngxd/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { TableComponent } from './table.component';

import { SharedModule } from '@app/core/shared/shared.module';
import { LoaderModule } from '@components/loader/loader.module';
import { FiltersModule } from '@components/filters/filters.module';
import { PaginationModule } from '@components/pagination/pagination.module';
import { ToggleSwitchModule } from '@components/toggle-switch/toggle-switch.module';
import { EmptyStateModule } from '../empty-state/empty-state.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalizePipe } from '@src/app/core/shared/pipes/localization.pipe';
import { AppStatus } from '../app-status/app-status.component';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TableComponent],
  exports: [TableComponent],
  imports: [
    CommonModule,
    FormsModule,
    LazyLoadImageModule,
    NgxdModule,
    SharedModule,
    PaginationModule,
    ToggleSwitchModule,
    FiltersModule,
    NgSelectModule,
    EmptyStateModule,
    LoaderModule,
    LocalizePipe,
    ConfirmDialogModule,
    TranslateModule,
    AppStatus
  ],
})
export class TableModule {}
