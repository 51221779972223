import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';
import {
  SYSTEM_ROLES_BASE_URL,
} from '@src/app/features/user-management/core/constants/apis_list';


@Injectable({
  providedIn: 'root',
})
export class SystemRepository {
  constructor(private httpService: HttpService) {}
  getAllRoles(query: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', SYSTEM_ROLES_BASE_URL, {
      search: query,
      limit: 100,
    });
  }
}
