import { inject } from '@angular/core';
import { of } from 'rxjs';
import { catchError,  map, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { BaseResolver } from '@app/core/resolvers/base-resolver';
import {CategoryRepository} from '@src/app/features/categories/core/repositories/category.repository';

/**
 * Resolves the details of a user based on the provided route and state.
 *
 * @param {ActivatedRouteSnapshot} route - The route snapshot containing the user ID.
 * @param {RouterStateSnapshot} state - The router state snapshot.
 * @param {BaseResolver} [baseResolver=inject(BaseResolver)] - The base resolver instance.
 * @return {Observable<any>} An observable that emits the user details or null.
 */
export const BrandsResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  baseResolver: BaseResolver = inject(BaseResolver),
  categoryRepository: CategoryRepository = inject(CategoryRepository),
) => {
  // const id = route.paramMap.get('id');
  // if (id) {
  //   return categoryRepository.getById(id).pipe(
  //     take(1),
  //     map((result: any) => {
  //       if (result?.body) {
  //         return result?.body;
  //       }
  //       baseResolver.redirectToLink(baseResolver.routeList.CATEGORIES_NEW);
  //       return null;
  //     }),
  //     catchError((err: any) => of(err)),
  //   );
  // }
  // baseResolver.redirectToLink(baseResolver.routeList.CATEGORIES_NEW);
  return null;
};
