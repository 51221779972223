import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserManagement } from '../interfaces/user-management.interface';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private usersList: UserManagement[] = [];

  isDataModified = false;

  /**
   * Initializes the UserManagementService by populating the usersList array with 50 objects,
   * each representing a user, and setting the status of the third user to 'active'.
   *
   * @return {void} This function does not return anything.
   */
  constructor() {
    for (let index = 0; index < 50; index++) {
      this.usersList.push({
        id: 1,
        first_name: 'Omer',
        last_name: 'Lamin',
        email: 'lamar@domain.com',
        contact: '+220 123 4567',
        role: 'Driver',
        created_date: '21/11/2022',
        status: 'in_active',
      });
    }

    this.usersList[2].status = 'active';
  }

  /**
   * Retrieves the list of users.
   *
   * @return {Observable<UserManagement[]>} An observable that emits the list of users.
   */
  public getUsers(): Observable<UserManagement[]> {
    return of(this.usersList);
  }
}
