import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@src/app/core/services/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/internal/operators/take';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { of, skipWhile, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot } from '@angular/router';
import { selectAuthUserState } from '@src/app/features/auth/core/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  authService = inject(AuthService);
  /**
   * Initializes a new instance of the class.
   *
   * @param {Router} router - The router service.
   * @param {Object} platformId - The platform ID.
   */
  constructor(
    private router: Router,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}
  /**
   * Checks if the user can activate the route.
   *
   * @return {boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree>} Returns true if the user is logged in and the platform is a browser, otherwise returns false. If the platform is a browser and the user is not logged in, it sets the redirect URL and returns a UrlTree to the '/auth' route.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    if (isPlatformBrowser(this.platformId)) {
      return this.store.select(selectAuthUserState).pipe(
        skipWhile(state => !state.checked),
        switchMap((state): Observable<boolean> => {
          if (route.data['isFromAuth']) {
            if (!state?.user) { // state?.user?.role !== 'admin'
              return of(true);
            } else {
              this.router.navigate(['/']);
              return of(false);
            }
          } else {
            if (state?.user) { // state?.user?.role === 'admin'
              return of(true);
            }
            this.authService.setRedirectUrl();
            return of(false);
          }
        }),
      );
    }
    return false;
  }
}
