import * as i0 from '@angular/core';
import { Component, Optional, Self, Input, HostBinding, HostListener, InjectionToken, Pipe, Inject, NgModule } from '@angular/core';
import * as i1 from '@angular/cdk/a11y';
import { FocusMonitor } from '@angular/cdk/a11y';
import * as i2 from '@angular/material/core';
import { mixinErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import * as i3 from '@angular/forms';

/**
 * The files to be uploaded
 */
class FileInput {
  constructor(_files, delimiter = ', ') {
    this._files = _files;
    this.delimiter = delimiter;
    this._fileNames = (this._files || []).map(f => f.name).join(delimiter);
  }
  get files() {
    return this._files || [];
  }
  get fileNames() {
    return this._fileNames;
  }
}

// Boilerplate for applying mixins to FileInput
/** @docs-private */
class FileInputBase {
  constructor(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, stateChanges) {
    this._defaultErrorStateMatcher = _defaultErrorStateMatcher;
    this._parentForm = _parentForm;
    this._parentFormGroup = _parentFormGroup;
    this.ngControl = ngControl;
    this.stateChanges = stateChanges;
  }
}
/**
 * Allows to use a custom ErrorStateMatcher with the file-input component
 */
const FileInputMixinBase = mixinErrorState(FileInputBase);
class FileInputComponent extends FileInputMixinBase {
  /**
   * @see https://angular.io/api/forms/ControlValueAccessor
   */
  constructor(fm, _elementRef, _renderer, _defaultErrorStateMatcher, ngControl, _parentForm, _parentFormGroup) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, new Subject());
    this.fm = fm;
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this._defaultErrorStateMatcher = _defaultErrorStateMatcher;
    this.ngControl = ngControl;
    this._parentForm = _parentForm;
    this._parentFormGroup = _parentFormGroup;
    this.focused = false;
    this.controlType = 'file-input';
    this.autofilled = false;
    this._required = false;
    this.accept = null;
    this.id = `ngx-mat-file-input-${FileInputComponent.nextId++}`;
    this.describedBy = '';
    this._onChange = _ => {};
    this._onTouched = () => {};
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(_elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }
  setDescribedByIds(ids) {
    this.describedBy = ids.join(' ');
  }
  get value() {
    return this.empty ? null : new FileInput(this._elementRef.nativeElement.value || []);
  }
  set value(fileInput) {
    if (fileInput) {
      this.writeValue(fileInput);
      this.stateChanges.next();
    }
  }
  get multiple() {
    return this._multiple;
  }
  set multiple(value) {
    this._multiple = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  /**
   * Whether the current input has files
   */
  get empty() {
    return !this._elementRef.nativeElement.value || this._elementRef.nativeElement.value.length === 0;
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty || this.valuePlaceholder !== undefined;
  }
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  get isDisabled() {
    return this.disabled;
  }
  get disabled() {
    return this._elementRef.nativeElement.disabled;
  }
  set disabled(dis) {
    this.setDisabledState(coerceBooleanProperty(dis));
    this.stateChanges.next();
  }
  onContainerClick(event) {
    if (event.target.tagName.toLowerCase() !== 'input' && !this.disabled) {
      this._elementRef.nativeElement.querySelector('input').focus();
      this.focused = true;
      this.open();
    }
  }
  get fileNames() {
    return this.value ? this.value.fileNames : this.valuePlaceholder;
  }
  writeValue(obj) {
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', obj instanceof FileInput ? obj.files : null);
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  /**
   * Remove all files from the file input component
   * @param [event] optional event that may have triggered the clear action
   */
  clear(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.value = new FileInput([]);
    this._elementRef.nativeElement.querySelector('input').value = null;
    this._onChange(this.value);
  }
  change(event) {
    const fileList = event.target.files;
    const fileArray = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        fileArray.push(fileList[i]);
      }
    }
    this.value = new FileInput(fileArray);
    this._onChange(this.value);
  }
  blur() {
    this.focused = false;
    this._onTouched();
  }
  setDisabledState(isDisabled) {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
  ngOnInit() {
    this.multiple = coerceBooleanProperty(this.multiple);
  }
  open() {
    if (!this.disabled) {
      this._elementRef.nativeElement.querySelector('input').click();
    }
  }
  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this._elementRef.nativeElement);
  }
  ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }
}
FileInputComponent.nextId = 0;
FileInputComponent.ɵfac = function FileInputComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FileInputComponent)(i0.ɵɵdirectiveInject(i1.FocusMonitor), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i2.ErrorStateMatcher), i0.ɵɵdirectiveInject(i3.NgControl, 10), i0.ɵɵdirectiveInject(i3.NgForm, 8), i0.ɵɵdirectiveInject(i3.FormGroupDirective, 8));
};
FileInputComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileInputComponent,
  selectors: [["ngx-mat-file-input"]],
  hostVars: 6,
  hostBindings: function FileInputComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function FileInputComponent_change_HostBindingHandler($event) {
        return ctx.change($event);
      })("focusout", function FileInputComponent_focusout_HostBindingHandler() {
        return ctx.blur();
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("id", ctx.id);
      i0.ɵɵattribute("aria-describedby", ctx.describedBy);
      i0.ɵɵclassProp("mat-form-field-should-float", ctx.shouldLabelFloat)("file-input-disabled", ctx.isDisabled);
    }
  },
  inputs: {
    autofilled: "autofilled",
    valuePlaceholder: "valuePlaceholder",
    accept: "accept",
    errorStateMatcher: "errorStateMatcher",
    value: "value",
    multiple: "multiple",
    placeholder: "placeholder",
    required: "required",
    disabled: "disabled"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: MatFormFieldControl,
    useExisting: FileInputComponent
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 4,
  consts: [["input", ""], ["type", "file"], [1, "filename", 3, "title"]],
  template: function FileInputComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "input", 1, 0);
      i0.ɵɵelementStart(2, "span", 2);
      i0.ɵɵtext(3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵattribute("multiple", ctx.multiple ? "" : null)("accept", ctx.accept);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", ctx.fileNames);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate(ctx.fileNames);
    }
  },
  styles: ["[_nghost-%COMP%]{display:inline-block;width:100%}[_nghost-%COMP%]:not(.file-input-disabled){cursor:pointer}input[_ngcontent-%COMP%]{width:0px;height:0px;opacity:0;overflow:hidden;position:absolute;z-index:-1}.filename[_ngcontent-%COMP%]{display:inline-block;text-overflow:ellipsis;overflow:hidden;width:100%}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileInputComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-mat-file-input',
      providers: [{
        provide: MatFormFieldControl,
        useExisting: FileInputComponent
      }],
      template: "<input #input type=\"file\" [attr.multiple]=\"multiple? '' : null\" [attr.accept]=\"accept\">\n<span class=\"filename\" [title]=\"fileNames\">{{ fileNames }}</span>\n",
      styles: [":host{display:inline-block;width:100%}:host:not(.file-input-disabled){cursor:pointer}input{width:0px;height:0px;opacity:0;overflow:hidden;position:absolute;z-index:-1}.filename{display:inline-block;text-overflow:ellipsis;overflow:hidden;width:100%}\n"]
    }]
  }], function () {
    return [{
      type: i1.FocusMonitor
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i2.ErrorStateMatcher
    }, {
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }]
    }, {
      type: i3.NgForm,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i3.FormGroupDirective,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    autofilled: [{
      type: Input
    }],
    valuePlaceholder: [{
      type: Input
    }],
    accept: [{
      type: Input
    }],
    errorStateMatcher: [{
      type: Input
    }],
    id: [{
      type: HostBinding
    }],
    describedBy: [{
      type: HostBinding,
      args: ['attr.aria-describedby']
    }],
    value: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    shouldLabelFloat: [{
      type: HostBinding,
      args: ['class.mat-form-field-should-float']
    }],
    required: [{
      type: Input
    }],
    isDisabled: [{
      type: HostBinding,
      args: ['class.file-input-disabled']
    }],
    disabled: [{
      type: Input
    }],
    change: [{
      type: HostListener,
      args: ['change', ['$event']]
    }],
    blur: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();

/**
 * Optional token to provide custom configuration to the module
 */
const NGX_MAT_FILE_INPUT_CONFIG = new InjectionToken('ngx-mat-file-input.config');
class ByteFormatPipe {
  constructor(config) {
    this.config = config;
    this.unit = config ? config.sizeUnit : 'Byte';
  }
  transform(value, args) {
    if (parseInt(value, 10) >= 0) {
      value = this.formatBytes(+value, +args);
    }
    return value;
  }
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 ' + this.unit;
    }
    const B = this.unit.charAt(0);
    const k = 1024;
    const dm = decimals || 2;
    const sizes = [this.unit, 'K' + B, 'M' + B, 'G' + B, 'T' + B, 'P' + B, 'E' + B, 'Z' + B, 'Y' + B];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
ByteFormatPipe.ɵfac = function ByteFormatPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ByteFormatPipe)(i0.ɵɵdirectiveInject(NGX_MAT_FILE_INPUT_CONFIG, 24));
};
ByteFormatPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "byteFormat",
  type: ByteFormatPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ByteFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'byteFormat'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [NGX_MAT_FILE_INPUT_CONFIG]
      }]
    }];
  }, null);
})();
class MaterialFileInputModule {}
MaterialFileInputModule.ɵfac = function MaterialFileInputModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MaterialFileInputModule)();
};
MaterialFileInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MaterialFileInputModule
});
MaterialFileInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [FocusMonitor]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaterialFileInputModule, [{
    type: NgModule,
    args: [{
      declarations: [FileInputComponent, ByteFormatPipe],
      providers: [FocusMonitor],
      exports: [FileInputComponent, ByteFormatPipe]
    }]
  }], null, null);
})();
class FileValidator {
  /**
   * Function to control content of files
   *
   * @param bytes max number of bytes allowed
   *
   * @returns
   */
  static maxContentSize(bytes) {
    return control => {
      const size = control && control.value ? control.value.files.map(f => f.size).reduce((acc, i) => acc + i, 0) : 0;
      const condition = bytes >= size;
      return condition ? null : {
        maxContentSize: {
          actualSize: size,
          maxSize: bytes
        }
      };
    };
  }
}

// Module

/**
 * Generated bundle index. Do not edit.
 */

export { ByteFormatPipe, FileInput, FileInputComponent, FileValidator, MaterialFileInputModule, NGX_MAT_FILE_INPUT_CONFIG };
