import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of, skipWhile, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { User } from '../../api';
import RoleEnum = User.RoleEnum;
import { selectAuthUserState } from '@src/app/features/auth/core/store';
import { AuthService, LocalStorageService } from '../../services';
import { CURRENT_USER_DATA } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class AuthRoleGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private authService: AuthService,
    private localService: LocalStorageService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const roles: RoleEnum[] = route.data['roles'];
    return this.store.select(selectAuthUserState).pipe(
      skipWhile((state) => !state.checked),
      switchMap((state): Observable<boolean> => {
        const userData = this.localService.getEncryptedData(CURRENT_USER_DATA);
        if (roles.length === 0) {
          return of(true);
        } else if (!userData?.roles) {
          this.router.navigate(['/error/no-access']);
          return of(false);
        } else if (roles.includes(userData.roles?.[0].code)) {
          return of(true);
        } else {
          this.router.navigate(['/error/no-access']);
          return of(false);
        }
      }),
    );
  }
}
