<div class="toggle-switch d-flex align-items-center gap-3" [ngClass]="className">
  <div class="toggle-switch__input d-flex justify-content-center align-items-center">
    <input
      [name]="name"
      [type]="type"
      [value]="value"
      [disabled]="disabled"
      [checked]="value && selectedValue && selectedValue === value"
      (change)="handleChangeEvent($any($event.target).checked)" />
    <span class="toggle-switch__button rounded-pill">
      <span class="toggle-switch__button-dot rounded-circle"></span>
    </span>
  </div>
  <ng-container *ngIf="showLabel">
    <label class="toggle-switch__label" [ngClass]="labelClass" for="">
      {{ label }}
    </label>
  </ng-container>
</div>
