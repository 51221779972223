import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrandsService {
  /**
   * Initializes the StoreManagementService by populating the stores array with 50 objects,
   * each representing a store, and setting the status of the third stores to 'active'.
   *
   * @return {void} This function does not return anything.
   */
  constructor() {}
}
