import { Directive, HostListener, ElementRef, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validator,
  AbstractControl,
  NG_VALIDATORS,
  Validators,
} from '@angular/forms';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import * as constants from '@src/app/core/constants/system.constant';

// Define a validator provider for NoConsecutiveSpaces
const NO_CONSECUTIVE_SPACES_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NoConsecutiveSpaces),
  multi: true,
};

// Define a value accessor provider for NoConsecutiveSpaces
const NO_CONSECUTIVE_SPACES_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NoConsecutiveSpaces),
  multi: true,
};

@Directive({
  selector:
    '[appNoConsecutiveSpaces][formControlName], [appNoConsecutiveSpaces][formControl], [appNoConsecutiveSpaces][ngModel]',
  providers: [NO_CONSECUTIVE_SPACES_VALIDATOR, NO_CONSECUTIVE_SPACES_VALUE_ACCESSOR],
})
export class NoConsecutiveSpaces implements ControlValueAccessor, Validator {
  private onTouchedCallback!: () => void;
  private onChangeCallback!: (_: any) => void;

  constructor(private elementRef: ElementRef) {}

  // Validator interface method
  validate(control: AbstractControl): { [key: string]: any } | null {
    // Use Angular's built-in Validators.required method to validate
    if (control.value) {
      return Validators.required(control);
    } else {
      return null;
    }
  }

  // ControlValueAccessor interface method
  writeValue(value: any): void {
    // Access the native input element
    const input = this.elementRef.nativeElement as HTMLInputElement;

    // Replace consecutive spaces with a single space and trim the value
    input.value = value ? value?.replace(/\s{2,}/g, ' ')?.trim() : '';
  }

  // ControlValueAccessor interface method
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // ControlValueAccessor interface method
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // Listen for the blur event to clean up input value
    /**
     * Handles the blur event on the input element.
     *
     * @param {Event} event - The blur event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = this.elementRef.nativeElement as HTMLInputElement;
    let value = input.value;

    // Remove consecutive spaces
    value = value.replace(/\s{2,}/g, ' ');

    // Trim spaces from the start and end of the input
    value = value.trim();

    // Update the input value
    input.value = value;

    // Update the control value and trigger the onChange callback
    this.onChangeCallback(value);
    this.onTouchedCallback();
  }

  // Listen for the input event to clean up input value
  // this function will be called before WriteValue()
  /**
   * Handles the input event on the input element.
   *
   * @param {Event} event - The input event object.
   * @return {void} This function does not return anything.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.elementRef.nativeElement as HTMLInputElement;
    let value = input.value;

    // Remove consecutive spaces
    value = value?.replace(/\s{2,}/g, ' ');

    // Remove space from the start of the input
    value = value?.replace(/^\s+/g, '');

    // Update the input value
    input.value = value;

    // Update the control value and trigger the onChange callback
    this.onChangeCallback(value);
  }
}
