import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberUptoThreeDecimalDirective } from './number-upto-three-decimal.directive';


@NgModule({
  declarations: [OnlyNumberUptoThreeDecimalDirective],
  imports: [CommonModule],
  exports: [OnlyNumberUptoThreeDecimalDirective],
})
export class OnlyNumberUptoThreeDecimalDirectiveModule {}
