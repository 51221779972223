import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoleDirective } from '@src/app/core/shared/directives/auth-role-directives/auth-role.directive';

@NgModule({
  declarations: [AuthRoleDirective],
  imports: [CommonModule],
  exports: [AuthRoleDirective],
})
export class AuthRoleDirectiveModule {}
