<mat-card>
  <mat-card-title-group>
    <mat-card-title>Categories</mat-card-title>
  </mat-card-title-group>
  <mat-card-content>
    <span *ngIf="(categories$ | async)?.length === 0" class="empty-placeholder">
      No categories found
    </span>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="categories-tree">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="mat-body-strong">
        {{ node.name }}
        <a [routerLink]="['/catalog/categories', node.id]"></a>
        <button mat-icon-button class="add-button" color="primary" (click)="addCategory(node)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" class="new-node">
        <form (submit)="add(); $event.preventDefault()">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" [formControl]="newName" />
          </mat-form-field>
          <button mat-flat-button color="primary">Add</button>
        </form>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node mat-body-strong">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          {{ node.name }}
          <a [routerLink]="['/catalog/categories', node.id]"></a>
          <button mat-icon-button class="add-button" color="primary" (click)="addCategory(node)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div [class.categories-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
    <button
      mat-icon-button
      class="add-button"
      color="primary"
      (click)="addCategory()"
      *ngIf="!!newNode.parentCategory">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
