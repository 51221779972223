import { AfterViewInit, Component, ElementRef, Injector, signal, ViewChild } from '@angular/core';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BASE_MATERIAL, COMMON_MODULES, DEFAULT_DEBOUNCE_TIME, FINISH, ITEM_STATUS } from '@src/app/core/constants';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { TableModule } from '@src/app/core/shared/components/table/table.module';
import { ITEM_BASE_URL, ITEM_BY_ID_BASE_URL, ITEM_STATUS_UPDATE, ITEMS_TABS } from '../../core';
import { TableComponent } from '@src/app/core/shared/components/table/table.component';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { BaseComponent } from '@src/app/core/base';
import { ApiEndPointConfiguration } from '@src/app/app-types';
import { AllowedTableActions, TableActions } from '@src/app/core/interfaces/table-actions.interface';
import { AllowedTableActionsData, ROW_NOT_CLICKABLE } from '@src/app/core/constants/table-actions.constant';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { ItemsRepository } from '../../core/repositories/items.repository';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import FileSaver from 'file-saver';
import { UserManagement, UserManagementService } from '@src/app/features/auth/core';
import { CATEGORY_BASE_URL } from '@src/app/features/categories/core';
import { BRAND_BASE_URL } from '@src/app/features/brands/core';
import { STORE_MANAGEMENT_BASE_URL } from '@src/app/features/store-management/core';
import { RequestActvationType } from '@src/app/core/shared/interfaces/app-types';

@Component({
  selector: 'app-items-list',
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    TableModule,
    NgbNavModule,
    NgbDropdownModule,
    ConfirmDialogModule,
    AppStatus,
  ],
  templateUrl: './items-list.component.html',
  styleUrl: './items-list.component.scss'
})
export class ItemsListComponent extends BaseComponent implements AfterViewInit  {
  @ViewChild(TableComponent, { static: false }) tableCompRef!: TableComponent;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dropdownData: any = [
    {
      name: 'CONSTANTS.ADMIN_USER',
      link: 'add',
      param: { type: 'admin_user' },
    },
    {
      name: 'CONSTANTS.APP_USER',
      link: 'add',
      param: { type: 'app_user' },
    },
  ];
  protected formChange = false; // flage to detect changes in forms of child component
  protected setFormChange = (value: boolean) => {
    this.formChange = value;
  };

  @ViewChild('fileInput') fileInput!: ElementRef;
  public isEditPermission = this.sharedDataService.checkPermission(
    [this.permissionsList.UPDATE_USERS],
    undefined,
  );
  
  public users$: BehaviorSubject<UserManagement[]> = new BehaviorSubject<UserManagement[]>([]);
  public confirmEventAction: 'active' | 'delete' | null = null;

  public endPointConfiguration: ApiEndPointConfiguration = {
    method: 'GET',
    url: ITEM_BASE_URL,
    // body: {
    //   'filter.roles.code': '$not:$in:commercial_sales_agent,inspector,sales_agent,driver,attendant',
    // },
  };

  public allowedTableActions: AllowedTableActions = AllowedTableActionsData;
  public paramFilters: any[] = [
    {
      key: 'filter.isActivated',
      type: 'option',
      placeholder: 'COMMON.TEXT.STATUS',
      operation: '$eq:',
      options: this.constantList.ITEM_STATUS
    },
    {
      type: 'autocomplete',
      key: 'filter.subcategories.parentCategory.id',
      method: 'GET',
      placeholder: 'COMMON.TEXT.CATEGORY',
      field: 'name',
      filterKey: 'id',
      preFetch: true,
      searchable: false,
      multiple: false,
      operation: '$eq:',
      apiUrl: CATEGORY_BASE_URL,
      size: 100,
      loading: true,
      closeOnSelect: true,
      params: [
        // {
        //   key: 'filter.subcategories.parentCategory.id',
        //   value: '$eq:parentCategory.id',
        // },
      ],
    },
    {
      type: 'autocomplete',
      key: 'filter.brand',
      method: 'GET',
      placeholder: 'COMMON.TEXT.BRAND',
      field: 'name',
      filterKey: 'id',
      preFetch: true,
      searchable: false,
      multiple: false,
      operation: '$eq:',
      apiUrl: BRAND_BASE_URL,
      size: 100,
      loading: true,
      closeOnSelect: true,
      params: [],
    },
    {
      type: 'autocomplete',
      key: 'filter.store',
      method: 'GET',
      placeholder: 'COMMON.TEXT.BRAND',
      field: 'name',
      filterKey: 'id',
      preFetch: true,
      searchable: false,
      multiple: false,
      operation: '$eq:',
      apiUrl: STORE_MANAGEMENT_BASE_URL,
      size: 100,
      loading: true,
      closeOnSelect: true,
      params: [],
    },
  ];

  public displayedColumnsViewArray: any[] = [];

  /**
   * Constructor for the UserListingComponent.
   *
   * @param {UserManagementService} userManagementService - Service for managing user data
   * @param {Injector} injector - Angular's injector service
   * @param {PageSettingsService} pageSettingsService - Service for managing page settings
   */
  constructor(
    // Injecting required services
    private userManagementService: UserManagementService, // Service for managing user data
    private injector: Injector, // Angular's injector service
    private pageSettingsService: PageSettingsService, // Service for managing page settings
    private itemRepository: ItemsRepository,
  ) {
    // Calling the parent constructor (BaseComponent) and passing the injector
    super(injector);

    // Initializing allowed table actions
    this.allowedTableActions.pending = true; // Setting 'pending' action as allowed
    this.allowedTableActions.draft = false; // Setting 'draft' action as not allowed

    // Fetching user data from the user management service
    userManagementService.getUsers().subscribe({
      next: (res: UserManagement[]) => {
        // Updating the 'users$' BehaviorSubject with the received data
        this.users$.next(res);
      },
    });

    // Creating new page settings for the user management page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name: 'MENU.ITEMS', // Name for the breadcrumb
        link: this.routeList.ITEMS, // Link for the breadcrumb
      },
    };

    // Using the page settings service to update the page settings
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  /**
   * Initializes the displayed columns view array after the view has been initialized.
   * Uses setTimeout to delay execution and avoid ExpressionChangedAfterItHasBeenCheckedError.
   *
   * @return {void}
   */
  ngAfterViewInit(): void {
    // Using setTimeout to delay execution and avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      // Array of objects defining the displayed columns
      this.displayedColumnsViewArray = [
        {
          key: 'id',
          value: 'COMPONENTS.ITEMS.ITEM_ID', // Localization key for column header
          type: 'text', // Type of column
          sortable: false, // Whether column is sortable
          className: 'w-15', // CSS class for column
        },
        {
          key: 'name',
          value: 'COMPONENTS.ITEMS.ITEM_NAME',
          type: 'text',
          sortable: false,
          className: ' text-truncate',
        },
        {
          key: 'category.name',
          value: 'COMPONENTS.ITEMS.CATEGORY',
          type: 'text',
          sortable: false,
          className: ' text-truncate',
        },
        {
          key: 'brand',
          value: 'COMPONENTS.ITEMS.BRAND',
          type: 'text',
          sortable: false,
          className: 'col-last-name text-truncate',
          callback: (brand: any) => {
            // return role;
            return (brand || []).map((obj: any) => obj.name.charAt(0).toUpperCase() + obj.name.slice(1))
              .join(', ');
          },
        },
        {
          key: 'alternatives',
          value: 'COMPONENTS.ITEMS.ALTERNATIVES',
          type: 'text',
          sortable: false,
          className: 'col-last-name text-truncate',
        },
        {
          key: 'store',
          value: 'COMPONENTS.ITEMS.STORE',
          type: 'text',
          sortable: false,
          className: 'col-last-name text-truncate',
          callback: (store: any) => {
            return (store || []).map((obj: any) => obj.name.charAt(0).toUpperCase() + obj.name.slice(1))
              .join(', ');
          },
        },
        
        {
          key: 'status',
          value: 'COMPONENTS.ITEMS.STATUS',
          headingClass: 'ps-4',
          type: 'status',
          sortable: false,
          defaultTitle: true,
          apiCallType: RequestActvationType.RESOURCE_URL,
          // callback: (status: any) => {
          //   console.log(status)
          //   return status;
          // },
          options: [
            {
              label: 'GENERAL.BUTTONS.ACTIVATE',
              value: 'active',
              icon: 'tick-circle',
            },
            {
              label: 'GENERAL.BUTTONS.DEACTIVATE',
              value: 'inactive',
              icon: 'close-circle',
            },
          ],
          endpoint: ITEM_BY_ID_BASE_URL,
          success: this.translate.instant('MSGS.ITEMS.UPDATE_ITEM_STATUS'),
          successSecond: this.translate.instant('MSGS.ITEMS.UPDATE_ITEM_STATUS_DEACTIVATED'),
          alert: this.translate.instant('MSGS.ITEMS.ARE_YOU_SURE_DEACTIVATE'),
          alertSecond: this.translate.instant('MSGS.ITEMS.ARE_YOU_SURE_ACTIVATE'),
          error: this.translate.instant('MSGS.ITEMS.UNABLE_TO_UPDATE_ITEM_STATUS'),
        },
        ...this.sharedDataService.checkPermission([this.permissionsList.UPDATE_USERS], {
          key: 'delete',
          value: '',
          type: 'button',
          class: ' btn-icon-table ms-auto d-block',
          iconClass: 'icon icon-trash-filled middle',
          callback: this.havePermissionToDelete,
        }),
      ];
    }, 500); // Delay of 500 milliseconds

    // Second setTimeout to execute another action after the first one
    setTimeout(() => {
      // Remove 'readonly' attribute from elements with specified IDs
      document.getElementById('email')?.removeAttribute('readonly');
      document.getElementById('password')?.removeAttribute('readonly');
    }, 1000); // Delay of 1000 milliseconds
  }

  public havePermissionToDelete = (permission: any) => {
    // Check if the permission object is an array and if it contains a role with code 'super_admin'
    return (
      Array.isArray(permission?.roles) && // Check if permission.roles is an array
      permission?.roles.some((role: any) => role?.code === 'super_admin') // Check if any role has code 'super_admin'
    );
  };

  /**
   * Checks if the given data contains an item with a role code of 'super_admin'.
   *
   * @param {any} data - The data to be checked.
   * @return {boolean} Returns true if 'super_admin' role is found, false otherwise.
   */
  private hasSuperAdminRole(data: any) {
    let hasSuperAdmin = false;
    // Loop through the data array and check if any item has a role with code 'super_admin'
    data?.forEach((item: any) => {
      if (item?.roles?.some((role: any) => role?.code === 'super_admin')) {
        hasSuperAdmin = true; // Set to true if 'super_admin' role is found
      }
    });
    return hasSuperAdmin; // Return true if 'super_admin' role is found, false otherwise
  }

  /**
   * Handles different actions based on the event object.
   *
   * @param {any} e - The event object.
   * @return {void}
   */
  /**
   * Handles different actions based on the event object.
   *
   * @param {any} e - The event object.
   * @return {void}
   */
  public onElementClick(e: any) {
    // Handle different actions based on the event object

    if (!ROW_NOT_CLICKABLE.includes(e.action) && e.id && e.id === 'from_td') {
      this.helperService.goToRoute(this.routeList.ITEMS + `/${e.element.id}`);
    }
    // If 'element' property has an ID but no action, return
    else if (e.id) {
      return;
    }

    // Switch statement to handle different actions
    switch (e.action) {
      case 'delete':
        // If confirmDialCompRef exists, open delete dialog
        if (this.confirmDialCompRef) {
          this.confirmEventAction = 'delete';
          this.confirmDialCompRef.openDialog(e.element);
        }
        break;
      case 'email':
        // If action is 'email', navigate to user listing route
        this.helperService.goToRoute(this.routeList.APP_SUB_CATEGORY + `/${e.element.id}`);
        break;
    }
  }

  /**
   * Processes the modal close event and performs actions based on the event type.
   *
   * @param {any} event - The event object containing information about the modal close event.
   * @return {void}
   */
  public processModalClose(event: {
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }) {
    // Switch case based on event type
    if (event?.eventType === 'delete') {
      // If action is confirm
      if (event.action === 'confirm') {
        // Check permission to delete
        if (!this.havePermissionToDelete(event.data)) {
          // Delete item
          this.itemRepository
            .delete(event.data?.id)
            .pipe(this.destroy$())
            .subscribe({
              /**
               * Handles the response from the delete operation.
               *
               * @param {any} res - The response from the delete operation.
               * @return {void}
               */
              next: (response: unknown) => {
                const res = response as ApiResponseInterface<any>;
                // If delete operation is successful
                if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
                  // Update table
                  this.updateTable(this.translate.instant('MSGS.ITEMS.DELETE_ITEM_SUCCESS'));
                } else {
                  // Show error message
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.ITEMS.UNABLE_TO_DELETE'),
                    'error',
                  );
                }
              },
              error: (err) => {
                this.httpService.showMessage(
                  this.translate.instant('MSGS.ITEMS.UNABLE_TO_DELETE'),
                  'error'
                );
              }        
            });
        } else {
          // Show error message
          this.httpService.showMessage(
            this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE_SUPER_ADMIN'),
            'error',
          );
        }
      }
    }
  }

  /**
   * Following method will work against particullar action performed
   *
   * @param e Action emitted by filter action in table
   */
  public tableAction({ key, params }: TableActions) {}

  // Function to update the table
  private updateTable(msg: string) {
    // Show success message
    this.httpService.showMessage(msg, 'success');
    // Reload the table data
    this.tableCompRef.loadResourcesPage();
    // Clear the bulk select list
    this.tableCompRef.bulkSelectList = [];
  }
}
