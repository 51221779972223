<div>
  <a routerLink="/catalog/products">
    <i class="icon icon-arrow-left fs-3" title="Go Back"></i></a>Create new product
</div>
<div class="card mb-4">
  <div class="card-body">
    <div class="form-card">
      <form [formGroup]="addForm" novalidate>
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="form-field mb-4">
                <label htmlFor="name" class="required">
                  {{ 'Product Name' | translate }}
                </label>
                <input
                  formControlName="name"
                  class="form-control"
                  type="text"
                  appNoConsecutiveSpaces
                  id="name"
                  name="name"
                  placeholder="Product Name" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['name'] }"></ng-container>
              </div>
              <div class="form-field mb-4">
                <label htmlFor="productPictures">
                  {{ 'Product Pictures' | translate }}
                </label>
                <app-new-product-photos-input></app-new-product-photos-input>
              </div>
              <div class="form-field mb-4">
                <label htmlFor="description">
                  {{ 'Product Description' | translate }}
                </label>
                <input
                  formControlName="description"
                  class="form-control"
                  type="text"
                  appNoConsecutiveSpaces
                  id="description"
                  name="description"
                  placeholder="Product Description" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['description'] }"></ng-container>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-field mb-4">
                <label htmlFor="category" class="required">
                  {{ 'Category' | translate }}
                </label>
                <ng-select
                  [clearSearchOnAdd]="true"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="category"
                  name="category"
                  class="w-100 cus-select clear-disable"
                  placeholder="{{ 'Category' | translate }}"
                  (valueChange)="categoryChanged()"
                  [disabled]="!(categories$ | async)?.length"
                  required>
                  @if(!(categories$ | async)?.length){
                    <ng-option value="">No item found.</ng-option>
                    } @for (item of (categories$ | async); track $index) {
                    @if(!item?.parentCategory){
                    <ng-option [value]="item.id">{{ item.name }}</ng-option>
                    } }
                </ng-select>
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['category'] }"></ng-container>
              </div>
              <div class="form-field mb-4">
                <label htmlFor="subCategory">
                  {{ 'Sub Category' | translate }}
                </label>
                <ng-select
                  [clearSearchOnAdd]="true"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="subCategory"
                  name="subCategory"
                  class="w-100 cus-select clear-disable"
                  placeholder="{{ 'subCategory' | translate }}"
                  (valueChange)="categoryChanged()"
                  [disabled]="!(subCategories$ | async)?.length">
                  @if(!(subCategories$ | async)?.length){
                    <ng-option value="">No item found.</ng-option>
                    } @for (item of (subCategories$ | async); track $index) {
                    @if(item?.parentCategory){
                    <ng-option [value]="item.id">{{ item.name }}</ng-option>
                    } }
                </ng-select>
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['subCategory'] }"></ng-container>
              </div>
              <div class="form-field mb-4">
                <label htmlFor="price" class="required">
                  {{ 'Product Price' | translate }}
                </label>
                <input
                  formControlName="price"
                  class="form-control"
                  type="number"
                  appNoConsecutiveSpaces
                  id="price"
                  name="price"
                  placeholder="Product Price" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['price'] }"></ng-container>
              </div>
              <div class="form-field mb-4">
                <label htmlFor="stock" class="required">
                  {{ 'Product Stock' | translate }}
                </label>
                <input
                  formControlName="stock"
                  class="form-control"
                  type="number"
                  appNoConsecutiveSpaces
                  id="stock"
                  name="stock"
                  placeholder="Product Stock" />
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['stock'] }"></ng-container>
              </div>
              <div class="form-field mb-4">
                <label htmlFor="visible">
                  {{ 'Visibility' | translate }}
                </label>
                <ng-select
                  [clearSearchOnAdd]="true"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="visible"
                  name="visible"
                  class="w-100 cus-select clear-disable"
                  placeholder="{{ 'Product Visibility' | translate }}">
                  <ng-option value="true">
                    {{ 'Visible' | translate }}
                  </ng-option>
                  <ng-option value="false">
                    {{ 'Hidden' | translate }}
                  </ng-option>
                </ng-select>
                <ng-container
                  [ngTemplateOutlet]="reqErrRef"
                  [ngTemplateOutletContext]="{ control: addForm.controls['visible'] }"></ng-container>
              </div>
            </div>
          </div>
        
          <div class="row">
            <div
            class="d-flex justify-content-end gap-2">
            <button
              type="button"
              class="btn btn-outline btn-outline-primary btn-140">
              {{ 'GENERAL.BUTTONS.RESET' | translate }}
            </button>
            <button
              type="button"
              appDebounceClick
              [disabled]="!addForm.valid || !addForm.dirty"
              class="btn btn-primary btn-140">
              {{ 'GENERAL.BUTTONS.SAVE' | translate }}
            </button>
          </div>
          </div>
        </div>
        
          
        </form>
    </div>
  </div>
</div>




<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">

</ng-template>
<ng-template #tipContent>
 
</ng-template>