import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItemManagementRoutingModule } from './item-management-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import { BaseResolver } from '@src/app/core/resolvers/base-resolver';
import { UnsavedDataChangesGuard } from '@src/app/core/guards/unsaved-data-changes.guard';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ItemManagementRoutingModule
  ],
  providers: [provideClientHydration(), BaseResolver, UnsavedDataChangesGuard],
})
export class ItemManagementModule { }
