import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from '@app/features/user-management/views/base/base.component';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { HomeOwnerListingComponent } from './home-owner-listing.component';
import { HomeOwnerDetailComponent } from '../home-owner-detail/home-owner-detail.component';
import { HomeOwnerDetailsResolver } from '../../../core/resolvers/home-owner-details-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: HomeOwnerListingComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: HomeOwnerDetailComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: HomeOwnerDetailComponent,
        resolve: {
          homeOwner: HomeOwnerDetailsResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
