import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberUptoThreeDecimalPlaces]',
})
export class OnlyNumberUptoThreeDecimalDirective {
  constructor(private el: ElementRef) {}

  @Input() OnlyNumber = true;
  @Input() allowNegative = false;
  @Input() excludeKeyCodes: number[] = [189, 109];

  /**
   * Handles the keydown event for the input field.
   *
   * @param {any} event - The keydown event object.
   * @return {void} This function does not return anything.
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    const e = event as KeyboardEvent;
    if ((this.OnlyNumber as any) == '') {
      this.OnlyNumber = true;
    }
    if (this.OnlyNumber) {
      // Checking for exclude key codes
      if (this.excludeKeyCodes && this.excludeKeyCodes.length > 0) {
        const hasKeyCode = this.excludeKeyCodes.indexOf(e.keyCode) > -1;
        if (hasKeyCode) {
          e.preventDefault();
        }
      }
      if (
        [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        [35, 36, 37, 39].indexOf(e.keyCode) !== -1
      ) {
        // let it happen, don't do anything
        return;
      }
      // Allow negative values if set to true
      if (this.allowNegative && e.keyCode === 109) {
        return;
      }

      // Get the current input value and the selection start position
      const inputValue: string = this.el.nativeElement.value;
      const selectionStart: number = this.el.nativeElement.selectionStart;

      // Create a new value by inserting the pressed key at the current selection position
      const newValue: string =
        inputValue.slice(0, selectionStart) + e.key + inputValue.slice(selectionStart);

      // Validate the new value using a regular expression
      // const decimalRegex = /^-?\d*\.?\d{0,2}$/;
      const decimalRegex = /^-?\d{0,14}(\.\d{0,2})?$/;
      if (!decimalRegex.test(newValue)) {
        e.preventDefault();
      }
    }
  }

    /**
     * Prevents the default paste action.
     *
     * @param {any} event - The paste event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('paste', ['$event']) onPaste(event: any) {
    event.preventDefault(); // Prevent the paste action
  }

    /**
     * Prevents the default behavior of the mouse wheel event in Chrome.
     *
     * @param {any} event - The mouse wheel event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    event.preventDefault();
  }

    /**
     * Prevents the default mouse wheel action in Firefox.
     *
     * @param {any} event - The mouse wheel event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    event.preventDefault();
  }

    /**
     * Prevents the default mouse wheel action in Internet Explorer.
     *
     * @param {any} event - The mouse wheel event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    event.preventDefault();
  }
}
