import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductsActions, selectCategoriesList, CategoriesActions, selectCategory } from '../../store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductPhotosInputComponent } from '../product-photos-input/product-photos-input.component';
import { Product } from '@src/app/core/api';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { Category } from '@app/core/api';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  categories$ = this.store.select(selectCategoriesList);
  category$ = this.store.select(selectCategory);
  subCategories$: Observable<Category[]> | undefined;
  @Input() product: Product | null = null;

  editForm = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    description: new FormControl('', {
      nonNullable: true,
    }),
    category: new FormControl(0, {
      nonNullable: true,
    }),
    subCategory: new FormControl(0, {
      nonNullable: true,
    }),
    price: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0)],
    }),
    stock: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0)],
    }),
    visible: new FormControl('true', {
      nonNullable: true,
    }),
  });

  @ViewChild(ProductPhotosInputComponent)
  photosInput!: ProductPhotosInputComponent;

  photosInputDirty = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  async ngOnInit() {
    if (this.product?.categories && this.product?.categories?.length > 0) {
      this.store.dispatch(
        CategoriesActions.getCategory({ id: this.product?.categories[0].id }),
      );
      this.category$.subscribe(async (category) => {
        let parentCategoryIndex: number;
        let subCategoryIndex: number = -1;
        console.log('category', category);

        if (category?.parentCategory) {
          parentCategoryIndex = category.parentCategory.id;
          subCategoryIndex = category.id;
        } else {
          parentCategoryIndex = category?.id || -1;
        }
        await this.resetValues(parentCategoryIndex, subCategoryIndex);
      });
      await this.resetValues();
    }
  }

  async resetValues(
    parentCategoryIndex: number | undefined = -1,
    subCategoryIndex: number | undefined = -1,
  ) {
    const categories = await firstValueFrom(this.categories$);

    if (parentCategoryIndex != -1) {
      this.subCategories$ = new Observable((observer) =>
        observer.next(
          categories.map((category: any) => {
            if (
              category?.parentCategory &&
              +category?.parentCategory?.id === parentCategoryIndex
            ) {
              return category;
            }
          }),
        ),
      );
    }
    if (!this.product) {
      return;
    }
    this.editForm.reset({
      name: this.product.name,
      description: this.product.description,
      category: parentCategoryIndex,
      subCategory: subCategoryIndex,
      price: this.product.price,
      stock: this.product.stock,
      visible: this.product.visible.toString(),
    });
  }

  categoryChanged() {
    setTimeout(async () => {
      const selectedCategory = this.editForm.get('category')?.value;
      const categories = await firstValueFrom(this.categories$);
      this.subCategories$ = new Observable((observer) =>
        observer.next(
          categories.map((category: any) => {
            if (category?.parentCategory?.id === selectedCategory) {
              return category;
            }
          }),
        ),
      );
    });
  }

  async save() {
    let productData: any = this.editForm.getRawValue();
    if (productData.subCategory) {
      productData.categoryId = productData.subCategory;
    } else {
      productData.categoryId = productData.category;
    }
    // category: String(this.product.categories[this.product.categories.length - 1].id)  ,
    //   subCategory: String(this.product.categories[this.product.categories.length - 1].parentCategory?.id || ''),

    if (!this.product) {
      return;
    }
    this.store.dispatch(
      ProductsActions.updateProduct({
        id: this.product.id,
        data: {
          name: this.editForm.value.name,
          description: this.editForm.value.description,
          categoryId: productData.categoryId,
          price: this.editForm.value.price,
          stock: this.editForm.value.stock,
          visible: this.editForm.value.visible === 'true',
        },
      }),
    );
    await this.photosInput.save();
    await this.editForm.markAsPristine();
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete this product?',
        confirmButton: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && this.product) {
        this.store.dispatch(
          ProductsActions.deleteProduct({ id: this.product.id }),
        );
        await this.router.navigate(['/catalog/products']);
      }
    });
  }
}
