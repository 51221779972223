import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SettingsApiService } from '@src/app/core/api';
import { SettingsActions } from '../actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { AuthActions } from '@src/app/features/auth/core/store';

@Injectable()
export class SettingsEffects {
  private localStorageService = inject(LocalStorageService);
  public defaultHeaders = new HttpHeaders();

  constructor(
    private actions$: Actions,
    private settingsApi: SettingsApiService,
  ) {
    this.defaultHeaders = this.defaultHeaders.set('Authorization', `Bearer ${this.localStorageService.getToken()}`);
  }

  loadSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SettingsActions.loadSettings,
        AuthActions.loginCheckSuccess,
        AuthActions.loginSuccess,
      ),
      switchMap(() => {
        
        return  of(SettingsActions.loadSettingsFailure({ error: "1error.message" }))
        
        // this.settingsApi.settingsControllerDeleteSetting(0
        
        // ).pipe(
        //   map((settings) => SettingsActions.loadSettingsSuccess({ settings })),
        //   catchError(({ error }) =>
        //     of(SettingsActions.loadSettingsFailure({ error: error.message })),
        //   ),
        // );
      }
      ),
    );
  });

  createSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsActions.createSetting),
      switchMap(({ data }) =>
        this.settingsApi.settingsControllerCreateSetting(data).pipe(
          map((setting) => SettingsActions.createSettingSuccess({ setting })),
          catchError(({ error }) =>
            of(SettingsActions.createSettingFailure({ error: error.message })),
          ),
        ),
      ),
    );
  });

  updateSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsActions.updateSetting),
      switchMap(({ settingId, data }) =>
        this.settingsApi.settingsControllerUpdateSetting(settingId, data).pipe(
          map((setting) =>
            SettingsActions.updateSettingSuccess({ settingId, setting }),
          ),
          catchError(({ error }) =>
            of(SettingsActions.updateSettingFailure({ error: error.message })),
          ),
        ),
      ),
    );
  });

  deleteSetting$ = createEffect(() => {
   // for later
    return this.actions$.pipe(
      ofType(SettingsActions.deleteSetting),
      switchMap(({ settingId }) =>
         of(SettingsActions.deleteSettingFailure({ error: "error.message" }))
    ),
  );
});
// this.settingsApi.settingsControllerDeleteSetting(settingId).pipe(
//   map(() => SettingsActions.deleteSettingSuccess({ settingId })),
//   catchError(({ error }) =>
//     of(SettingsActions.deleteSettingFailure({ error: error.message })),
//   ),
// ),
}
