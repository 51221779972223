import { BrandsModule } from './brands/brands.module';
export { DashboardModule } from './dashboard/dashboard.module';

export { UserManagementModule } from './user-management/user-management.module';
export { AppUserManagementModule } from './app-user-management/app-user-management.module';
export { InteriorDesignerManagementModule } from './interior-designer-management/interior-designer-management.module';
export { HomeOwnerModule } from './user-management/views/home-owner/home-owner-listing/home-owner.module';
export { SubCategoryModule } from './sub-categories/sub-category.module';


export { ProfileModule } from './profile/profile.module';
export { SettingsModule } from './settings/settings.module';
export { PagesModule } from './pages/pages.module';
export { CatalogModule } from './catalog/catalog.module';
export { SalesModule } from './sales/sales.module';
export { StoreManagementModule } from './store-management/store-management.module';
export { CategoriesModule } from './categories/categories.module';
export { TagsModule } from './tags/tags.module';
export { BrandsModule } from './brands/brands.module';
export { ItemManagementModule } from './item-management/item-management.module';
