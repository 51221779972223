import { Component } from '@angular/core';

@Component({
  selector: 'app-create-return-form',
  templateUrl: './create-return-form.component.html',
  styleUrls: ['./create-return-form.component.scss'],
})
export class CreateReturnFormComponent {
  constructor() {}
}
