<div class="product-photos">
  <div class="inputWrapper">
    <i class="icon icon-upload  fs-3" title="Select Pictures"></i>
    <br>
    <label for="file">
      Select Pictures
    </label>
    <input 
        class="fileInput" 
        type="file" 
        name="file" 
        accept="image/*" 
        multiple 
        (change)="updatePhotosToDisplay($event)" 
      />
  </div>
  <div *ngFor="let photo of photosToDisplay">
    <img [src]="photo.data | safeUrl" alt="product photo" />
    <button
      type="button"
      (click)="removePhoto(photo.name)"
    >
      <i class="icon icon-trash" title="Remove photo"></i>
    </button>
  </div>
  <div *ngFor="let photo of remotePhotosToDisplay">
    <img [src]="photo.path | safeUrl" alt="product photo" />
    <button
      type="button"
      (click)="removeRemotePhoto(photo.id)"
    >
      <i class="icon icon-trash" title="Remove photo"></i>
    </button>
  </div>
</div>
