<mat-card>
  <mat-card-title-group>
    <mat-card-title>
      <a mat-button routerLink="/pages"> <mat-icon>arrow_back</mat-icon></a
      >Edit page
    </mat-card-title>
  </mat-card-title-group>
  <mat-card-content>
    <form [formGroup]="editForm" (submit)="save()">
      <mat-form-field appearance="outline" class="page-title">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" required />
        <mat-error>Invalid title</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="page-slug">
        <mat-label>Slug</mat-label>
        <input matInput formControlName="slug" />
        <mat-error>Invalid slug</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="page-groups">
        <mat-label>Groups</mat-label>
        <mat-chip-grid #chipList>
          <mat-chip
            *ngFor="let group of editForm.controls.groups.value"
            (removed)="removeGroup(group)"
          >
            {{ group }}
            <button matChipRemove><mat-icon>cancel</mat-icon></button>
          </mat-chip>
          <input
            placeholder="Add group..."
            #groupInput
            formControlName="newGroup"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            (focus)="editForm.controls.newGroup.setValue('')"
            (matChipInputTokenEnd)="addGroup($event)"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selected($event)"
        >
          <mat-option
            *ngFor="let group of filteredGroups$ | async"
            [value]="group.name"
          >
            {{ group.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="form-content">
        <div class="page-content">
          <mat-form-field appearance="outline" class="fill-container">
            <mat-label>Content</mat-label>
            <textarea matInput formControlName="content"></textarea>
            <mat-error>Invalid content</mat-error>
          </mat-form-field>
        </div>
        <markdown [data]="editForm.controls.content.value"></markdown>
      </div>

      <div class="form-actions">
        <button mat-flat-button type="button" (click)="delete()" color="warn">
          Delete page
        </button>
        <button
          mat-flat-button
          type="submit"
          [disabled]="!editForm.valid || !editForm.dirty"
          color="primary"
        >
          Save
        </button>
        <button
          mat-button
          type="button"
          (click)="resetValues()"
          color="primary"
        >
          Cancel
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
