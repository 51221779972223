import { Injectable } from '@angular/core';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { HttpService } from '@src/app/core/services';
import { Observable } from 'rxjs';
import { GET_COUNTRIES_BASE_URL, GET_CITIES_BASE_URL } from '../constants/apis_list';



@Injectable({
  providedIn: 'root',
})
export class CountryRepository {
  constructor(private httpService: HttpService) {}
  getAll(): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', GET_COUNTRIES_BASE_URL);
  }
  getCities(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', GET_CITIES_BASE_URL.replace('{countryId}', id));
  }
}
