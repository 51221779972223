<mat-list role="list">
  <app-product-attribute-item
    *ngFor="let attribute of product?.attributes"
    [attribute]="attribute"
    (delete)="onDelete($event)"
    (update)="onUpdate($event.id, $event.value)"
  ></app-product-attribute-item>
  <span *ngIf="product?.attributes?.length === 0" class="mat-headline">
    No attributes
  </span>
</mat-list>

<mat-divider></mat-divider>

<app-product-attributes-add-form
  [product]="product"
  (add)="onAdd($event)"
></app-product-attributes-add-form>
