import { Routes, RouterModule } from '@angular/router';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { InteriorDesignerManagementComponent } from './interior-designer-management.component';
import { InteriorDesignersListingComponent } from '../user-management/views/interior-designer/interior-designers-listing/interior-designers-listing.component';
import { InteriorDesignerDetailComponent } from '../user-management/views/interior-designer/interior-designer-detail/interior-designer-detail.component';
import { InteriorDesignerDetailsResolver } from '../user-management/core/resolvers/interior-designer-details-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: InteriorDesignerManagementComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: InteriorDesignersListingComponent,
      },
      {
        path: ROUTE_LIST.VIEW_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: InteriorDesignerDetailComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: InteriorDesignerDetailComponent,
        resolve: {
          interiorDesigner: InteriorDesignerDetailsResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
