<form [formGroup]="editForm" (submit)="save()">
  <div class="form-column">
    <mat-form-field appearance="outline" class="product-name">
      <mat-label>Product name</mat-label>
      <input type="text" matInput required formControlName="name" />
      <mat-error>Invalid product name</mat-error>
    </mat-form-field>
    <app-product-photos-input
      [product]="product"
      (dirty)="photosInputDirty = true"
      (pristine)="photosInputDirty = false"
    ></app-product-photos-input>
    <mat-form-field appearance="outline" class="product-description">
      <mat-label>Product description</mat-label>
      <textarea matInput formControlName="description"></textarea>
      <mat-error>Invalid product description</mat-error>
    </mat-form-field>
  </div>
  <div class="form-column">
    <mat-form-field appearance="outline" class="product-visible">
      <mat-label>Category</mat-label>
      <mat-select
        formControlName="category"
        (valueChange)="categoryChanged()"
        [disabled]="!(categories$ | async)?.length"
        required
      >
        @if(!(categories$ | async)?.length){
        <mat-option value="">No item found.</mat-option>
        } @for (item of (categories$ | async); track $index) {
        @if(!item?.parentCategory){
        <mat-option [value]="item.id">{{ item.name }}</mat-option>
        } }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="product-visible">
      <mat-label>Sub Category</mat-label>
      <mat-select
        formControlName="subCategory"
        [disabled]="!(subCategories$ | async)?.length"
      >
        @if(!(subCategories$ | async)?.length){
        <mat-option value="">No item found.</mat-option>
        } @for (item of (subCategories$ | async); track $index) {
        @if(item?.parentCategory){
        <mat-option [value]="item.id">{{ item.name }}</mat-option>
        } }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="product-price">
      <mat-label>Price</mat-label>
      <span matPrefix>{{ 0 | formatCurrency : true }}&nbsp;</span>
      <input type="number" matInput required formControlName="price" />
      <mat-error>Invalid price</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="product-stock">
      <mat-label>Stock</mat-label>
      <input type="number" matInput required formControlName="stock" />
      <mat-error>Invalid stock</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="product-visible">
      <mat-label>Visibility</mat-label>
      <mat-select formControlName="visible">
        <mat-option value="true">Visible</mat-option>
        <mat-option value="false">Hidden</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button type="button" (click)="delete()" color="warn">
      Delete product
    </button>
    <div>
      <button
        mat-button
        type="button"
        (click)="resetValues(); photosInput.resetValues()"
        color="primary"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        type="submit"
        [disabled]="
          (!editForm.valid || !editForm.dirty) && photosInputDirty === false
        "
        color="primary"
      >
        Save
      </button>
    </div>
  </div>
</form>
