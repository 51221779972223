<div>
  <section *ngIf="order">
    <h2>Account</h2>
    <p *ngIf="!order.user">Guest account</p>
    <p *ngIf="order.user">
      <strong>{{ order.user.email }}</strong
      ><br />
      <strong>{{ order.user.firstName }} {{ order.user.lastName }}</strong>
    </p>
  </section>
  <section *ngIf="order">
    <h2>General info</h2>
    <p>
      Order ID: <strong>{{ order.id }}</strong> <br />
      Order created date: <strong>{{ order.created | date: 'medium' }} </strong
      ><br />
      Order updated date: <strong>{{ order.updated | date: 'medium' }} </strong>
    </p>
  </section>
  <section *ngIf="order">
    <h2>Status</h2>
    <p>
      <strong>{{ order.status }}</strong>
    </p>
  </section>
  <section *ngIf="order">
    <h2>Message</h2>
    <p>
      {{ order.message || '-' }}
    </p>
  </section>
  <section *ngIf="order">
    <button
      *ngIf="order.return === null"
      mat-flat-button
      color="primary"
      (click)="addReturn()"
    >
      Add return
    </button>
  </section>
</div>

<div>
  <section *ngIf="order">
    <h2>Contact Info</h2>
    <p>
      Name: <strong>{{ order.fullName || '-' }}</strong
      ><br />
      E-mail: <strong>{{ order.contactEmail || '-' }}</strong
      ><br />
      Phone number: <strong>{{ order.contactPhone || '-' }}</strong>
    </p>
  </section>
  <section *ngIf="order">
    <h2>Payment</h2>
    <p>
      Status: <strong>{{ order.payment.paymentStatus }}</strong> <br />
      Method: <strong>{{ order.payment.method.name }} </strong><br />
      Price: <strong>{{ order.payment.method.price | formatCurrency }} </strong
      ><br />
    </p>
  </section>
  <section *ngIf="order">
    <h2>Delivery</h2>
    <p>
      Status: <strong>{{ order.delivery.deliveryStatus }}</strong> <br />
      Method: <strong>{{ order.delivery.method.name }}</strong> <br />
      Price:
      <strong>{{ order.delivery.method.price | formatCurrency }}</strong> <br />
      Address: <strong>{{ order.delivery.address }}</strong> <br />
      City: <strong>{{ order.delivery.city }}</strong> <br />
      Zip code: <strong>{{ order.delivery.postalCode }}</strong> <br />
      Country: <strong>{{ order.delivery.country }} </strong><br />
    </p>
  </section>
</div>
