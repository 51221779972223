<mat-card>
  <mat-card-title-group>
    <mat-card-title>
      <a mat-button routerLink="/catalog/products">
        <mat-icon>arrow_back</mat-icon></a
      >{{ (product$ | async)?.name }}
    </mat-card-title>
  </mat-card-title-group>
  <mat-tab-group dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mat-tab-icon">info</mat-icon>
        General
      </ng-template>
      <mat-card-content>
        <app-product-details
          *ngIf="product$ | async"
          [product]="(product$ | async) ?? null"
        ></app-product-details>
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mat-tab-icon">list</mat-icon>
        Attributes
      </ng-template>
      <mat-card-content>
        <app-product-attributes
          *ngIf="product$ | async"
          [product]="(product$ | async) ?? null"
        ></app-product-attributes>
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mat-tab-icon">reviews</mat-icon>
        Ratings
      </ng-template>
      <mat-card-content>
        <app-product-ratings
          *ngIf="product$ | async"
          [product]="(product$ | async) ?? null"
        ></app-product-ratings
      ></mat-card-content>
    </mat-tab>
  </mat-tab-group>
</mat-card>
