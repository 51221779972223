<div style="position: relative">
  <a class="add-button" mat-fab color="primary" routerLink="/catalog/products/new">
    <mat-icon>add</mat-icon>
  </a>
  <mat-card>
    <mat-card-title>Products</mat-card-title>
    <mat-card-content>
      <mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
        matSortActive="id"
        matSortDirection="asc">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</mat-header-cell>
          <mat-cell *matCellDef="let product">
            {{ product.id }}
            <a [routerLink]="['/catalog/products', product.id]"></a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="photo">
          <mat-header-cell *matHeaderCellDef mat-sort-header="photo">Photo</mat-header-cell>
          <mat-cell *matCellDef="let product">
            <app-product-photo [product]="product"></app-product-photo>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
          <mat-cell *matCellDef="let product">{{ product.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header="description">
            Description
          </mat-header-cell>
          <mat-cell *matCellDef="let product">{{ product.description }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef mat-sort-header="price" arrowPosition="before">
            Price
          </mat-header-cell>
          <mat-cell *matCellDef="let product">{{ product.price | formatCurrency }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="stock">
          <mat-header-cell *matHeaderCellDef mat-sort-header="stock" arrowPosition="before">
            Stock
          </mat-header-cell>
          <mat-cell *matCellDef="let product">{{ product.stock }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="visible">
          <mat-header-cell *matHeaderCellDef mat-sort-header="visible">Visibility</mat-header-cell>
          <mat-cell *matCellDef="let product">
            {{ product.visible | booleanText: 'Visible' : 'Hidden' }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="[
            'id',
            'photo',
            'name',
            'description',
            'price',
            'stock',
            'visible'
          ]"></mat-header-row>
        <mat-row
          *matRowDef="
            let product;
            columns: ['id', 'photo', 'name', 'description', 'price', 'stock', 'visible']
          "
          class="product-row"></mat-row>
      </mat-table>
      <span *ngIf="(products$ | async)?.length === 0" class="empty-placeholder">
        No products found
      </span>
      <mat-paginator showFirstLastButtons hidePageSize="true" pageSize="50"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
