import { SimpleChanges } from '@angular/core';
import { stringify } from '@app/core/shared/helpers/json.helper';

/**
 * Updates the input bindings of a target object based on the changes in the SimpleChanges object.
 *
 * @param {any} target - The target object to update the input bindings of. Defaults to an empty object.
 * @param {SimpleChanges} changes - The SimpleChanges object containing the changes in the input properties.
 * @param {Function} cb - An optional callback function to be called with the new input bindings. If not provided, the new input bindings are assigned directly to the target object.
 * @return {void} This function does not return anything.
 */
export function updateInputBindingOnChanges(
  target: any = {},
  changes: SimpleChanges,
  cb?: Function,
) {
  const newInputBindings: any = {};
  Object.keys(changes)
    .filter(
      (k: string) =>
        !changes[k].isFirstChange() &&
        stringify(changes[k].previousValue) !== stringify(changes[k].currentValue),
    )
    .forEach((k: string) => (newInputBindings[k] = changes[k].currentValue));
  if (Object.keys(newInputBindings).length > 0) {
    if (cb) {
      cb(newInputBindings);
    } else {
      Object.assign(target, newInputBindings);
    }
  }
}
