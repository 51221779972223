import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoConsecutiveSpaces } from './noConsecutiveSpaces.directive';

@NgModule({
  declarations: [NoConsecutiveSpaces],
  imports: [CommonModule],
  exports: [NoConsecutiveSpaces],
})
export class NoConsecutiveSpacesDirectiveModule {}
