import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';
import {
  STORE_BY_ID_BASE_URL,
  STORE_MANAGEMENT_STATUS_UPDATE,
  UPDATE_STORE_PICTURE_BASE_URL,
  DOWNLOAD_STORE_DOCUMETS_BASE_URL,
  DELETE_STORE_DOCUMETS_BASE_URL,
  UPDATE_STORE_DOCUMENT_BASE_URL,
  UPLOAD_STORE_DOCUMETS_BASE_URL,
  UPDATE_STORE_OFFICIAL_DETAILS_BASE_URL,
  STORE_MANAGEMENT_BASE_URL,
} from '@src/app/features/store-management/core';

@Injectable({
  providedIn: 'root',
})
export class StoreRepository {
  constructor(private httpService: HttpService) {}

  create(body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'POST',
      STORE_MANAGEMENT_BASE_URL,
      body,
      this.httpService.multiPartFormDataHeaders,
    );
  }

  update(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH', 
      STORE_BY_ID_BASE_URL.replace('{id}', id), 
      body,
      this.httpService.multiPartFormDataHeaders,
    );
  }

  search(filter = {}): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', STORE_MANAGEMENT_BASE_URL, filter);
  }

  updateUserDetail(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PUT',
      UPDATE_STORE_OFFICIAL_DETAILS_BASE_URL.replace('{id}', id),
      body,
    );
  }
  updateUserDocumentFile(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PUT',
      UPDATE_STORE_DOCUMENT_BASE_URL.replace('{id}', id),
      body,
    );
  }
  updateUserDocument(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PUT',
      UPLOAD_STORE_DOCUMETS_BASE_URL.replace('{id}', id),
      body,
      this.httpService.multiPartFormDataHeaders,
    );
  }
  updateDetail(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH',
      STORE_MANAGEMENT_STATUS_UPDATE.replace('{id}', id),
      body,
    );
  }
  getById(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', STORE_BY_ID_BASE_URL.replace('{id}', id));
  }
  updateProfile(id: string, formData: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'PATCH',
      UPDATE_STORE_PICTURE_BASE_URL.replace('{id}', id),
      formData,
      this.httpService.multiPartFormDataHeaders,
    );
  }
  delete(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('DELETE', STORE_BY_ID_BASE_URL.replace('{id}', id));
  }
  download(link: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'POST',
      link,
      {}, // Set an empty object as the third argument
      this.httpService.formDataHeaders, // Headers remain the same
      true,
      false,
      false,
      true,
    );
  }
  downloadDocuments(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'GET',
      DOWNLOAD_STORE_DOCUMETS_BASE_URL.replace('{documentFileId}', id),
    );
  }
  deleteDocument(data: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity(
      'DELETE',
      DELETE_STORE_DOCUMETS_BASE_URL.replace('{documentId}', data),
    );
  }
}
