import { Component } from '@angular/core';

@Component({
  selector: 'app-delivery-methods',
  templateUrl: './delivery-methods.component.html',
  styleUrls: ['./delivery-methods.component.scss'],
})
export class DeliveryMethodsComponent {
  constructor() {}
}
