import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { BaseComponent } from '@src/app/core/base';
import { takeUntil } from 'rxjs';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import { UserManagementService } from '@src/app/features/user-management/core';
import * as constants from '@src/app/core/constants/system.constant';
import { UPDATE_USER_OFFICIAL_DETAILS_BASE_URL } from '../../../core';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { UserRepository } from '@src/app/features/user-management/core/repositories/user.repository';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [...COMMON_MODULES, NgxIntlTelInputModule, ViewPermissionDirectiveModule],
})
export class UserDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  public phoneNumberFormat = PhoneNumberFormat;
  public SearchCountryField = SearchCountryField;
  public selectedItems: any = [];
  public selectedCompleteValues: any = [];
  public CountryISO = CountryISO;
  public officialDetailsForm: FormGroup = new FormGroup({
    position: new FormControl(null, [Validators.required]),
    phoneNumber: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [
      Validators.required,
      Validators.email,
      Validators.pattern(this.constantList.emailRegex),
    ]),
  });
  formBackup = this.helperService.clone(this.officialDetailsForm.value);
  /**
   * Constructor for the UserDetailsComponent.
   *
   * @param {Injector} injector - The injector instance.
   * @param {UserManagementService} userManagementService - The UserManagementService instance.
   */
  constructor(
    injector: Injector,
    private userManagementService: UserManagementService,
    private userRepository: UserRepository,
  ) {
    super(injector);
    if (this.isEditMode() == null) this.pageMode = 'add';
  }

  /**
   * Listens for changes in the officialDetailsForm and updates the isDataModified flag in the userManagementService.
   * Also emits an event through sharedDataService.dataModified with the modified data.
   *
   * @return {void} This function does not return anything.
   */
  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onChanges(): void {
    this.officialDetailsForm.valueChanges.pipe(this.destroy$()).subscribe(val => {
      let formData: any = this.helperService.clone(val);
      formData.phoneNumber =
        formData.phoneNumber && formData.phoneNumber.number
          ? formData.phoneNumber.number
          : formData.phoneNumber;

      this.userManagementService.isDataModified = !this.helperService.isEqual(
        this.formBackup,
        formData,
      );
      this.sharedDataService.dataModified.next({
        component: 'user-details',
        isModified: this.userManagementService.isDataModified,
        data: this.helperService.clone(val),
      });
    });
  }

  /**
   * Component initialization
   */
  ngOnInit(): void {
    // Subscribe to route data and set the pageMode, baseModel and userDetailsForm
    // properties.
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.user) {
          // Set the baseModel to the users data
          this.baseModel = data?.user;
          // Create a copy of the users roles
          this.baseModel.rolesData = this.helperService.clone(this.baseModel.roles);
          // Check if the user is a super admin
          if (this.baseModel.rolesData?.length > 0) {
            const index = this.baseModel?.rolesData.findIndex(
              (role: any) => role.code === 'super_admin',
            );
            if (index !== -1) {
              // Set the pageMode to view if the user is a super admin
              this.pageMode = 'view';
            }
          }

          // Check if the user phone number has a dial code and if not add '+'
          if (
            this.baseModel?.professionalDetail?.phoneNumber?.length > 0 &&
            !this.baseModel?.professionalDetail?.phoneNumber?.includes('+')
          ) {
            this.baseModel.professionalDetail.phoneNumber =
              '+' + this.baseModel?.professionalDetail?.phoneNumber;
          }

          // Set the phone number to the users phone number
          if (this.baseModel?.professionalDetail?.phoneNumber) {
            const phoneNumber = { ...this.baseModel?.professionalDetail?.phoneNumber };
            // Check if the phone number has a dial code and if so remove it
            if (phoneNumber?.number?.includes(phoneNumber.dialCode)) {
              phoneNumber.number = phoneNumber.number.replace(phoneNumber.dialCode, '');
            }
          }

          // Set the details form values and user details list
          this.setDetails();
        }
      });
    this.onChanges();
  }

  /**
   * Submit the form data to the API endpoint
   */
  protected submitDetails(): void {
    // Check if the form is valid
    const { valid, value } = this.officialDetailsForm;
    if (!valid) {
      // Mark all the fields as touched and show an error message
      this.officialDetailsForm.markAllAsTouched();
      this.httpService.showMessage(
        this.translate.instant('COMMON.VALIDATION.ENTER_REQUIRED_FIELDS'),
        'error',
      );
      // Return early without submitting the data
      return;
    }
    // If the form is valid, prepare the data and submit it to the API endpoint
    if (valid) {
      // Remove the '+' from the phone number and convert it to a number
      if (value.phoneNumber?.e164Number) {
        value.phoneNumber = Number(value.phoneNumber.e164Number.replace('+', ''));
      }
      // Set the URL and submit the data
      let url = UPDATE_USER_OFFICIAL_DETAILS_BASE_URL.replace('{id}', this.baseModel.id);
      this.userRepository
        .updateUserDetail(this.baseModel.id, value)
        .pipe(this.destroy$())
        .subscribe({
          /**
           * Handles the response from the API call and displays a success or error message.
           *
           * @param {any} res - The response object from the API call.
           * @return {void} This function does not return anything.
           */
          next: (response: unknown) => {
            const res = response as ApiResponseInterface<any>;

            // Check if the response is a success
            if (res?.status >= 200 && res?.status <= 210) {
              this.formBackup = this.helperService.clone(this.officialDetailsForm.value);

              this.userManagementService.isDataModified = false;
              this.sharedDataService.dataModified.next({
                component: 'user-info',
                isModified: this.userManagementService.isDataModified,
                data: this.helperService.clone(res),
              });
              // Show a success message
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UPDATE_USER_DETAILS'),
                'success',
              );
            } else {
              // Show an error message
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_DETAILS'),
                'error',
              );
            }
          },
          error: err => {
            // Show an error message
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_DETAILS'),
              'error',
            );
          },
        });
    }
  }

  /**
   * Set the form values and validity from the baseModel
   */
  setDetails() {
    if (this.baseModel?.professionalDetail !== null) {
      this.officialDetailsForm.patchValue(
        { ...this.baseModel?.professionalDetail },
        { emitEvent: false },
      );
      // Update form validity
      this.officialDetailsForm.updateValueAndValidity({ emitEvent: false });
      this.formBackup = this.helperService.clone(this.officialDetailsForm.value);
    } else {
      // Reset the form
      this.officialDetailsForm.reset();
    }
  }
}
