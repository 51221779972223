import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, isDevMode, reflectComponentType, createEnvironmentInjector, createComponent, ChangeDetectorRef, SimpleChange, EventEmitter, Directive, Input, Output, Pipe, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
function hasProperty(context, name) {
  if (name in context) {
    return true;
  }
  const prototype = Object.getPrototypeOf(context);
  if (prototype) {
    return hasProperty(prototype, name);
  }
  return false;
}
function getPropertyDescriptor(context, name) {
  const descriptor = Object.getOwnPropertyDescriptor(context, name);
  if (descriptor) {
    return Object.getOwnPropertyDescriptor(context, name);
  }
  const prototype = Object.getPrototypeOf(context);
  if (prototype) {
    return getPropertyDescriptor(prototype, name);
  }
  return void 0;
}
function toPropertyDef(context, dynamicContext, hostContext) {
  return property => ({
    context: context,
    dynamicContext: dynamicContext,
    hostContext: hostContext,
    insidePropName: property.propName,
    outsidePropName: property.templateName
  });
}
const SimpleChangesWeakMap = new WeakMap();
const WasChangesBeforeWeakMap = new WeakMap();
function hasOnChangesHook(component) {
  return component && hasProperty(component, 'ngOnChanges');
}
function runOnChangesHook(context) {
  const simpleChanges = SimpleChangesWeakMap.get(context);
  if (simpleChanges != null && hasOnChangesHook(context)) {
    context.ngOnChanges(simpleChanges);
  }
  SimpleChangesWeakMap.set(context, null);
}
class DoCheckHook {
  context;
  changeDetectorRef;
  ngDoCheck() {
    if (this.context) {
      runOnChangesHook(this.context);
    }
    if (this.changeDetectorRef) {
      this.changeDetectorRef.markForCheck();
    }
  }
  attach(componentRef) {
    this.context = componentRef.instance;
    this.changeDetectorRef = componentRef.changeDetectorRef;
  }
  static attach(componentRef, viewContainerRef) {
    const doCheckComponentRef = viewContainerRef.createComponent(DoCheckHook);
    doCheckComponentRef.instance.attach(componentRef);
    return () => doCheckComponentRef.destroy();
  }
  /** @nocollapse */
  static ɵfac = function DoCheckHook_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DoCheckHook)();
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: DoCheckHook,
    selectors: [["ngxd-do-check-hook"]],
    decls: 0,
    vars: 0,
    template: function DoCheckHook_Template(rf, ctx) {},
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DoCheckHook, [{
    type: Component,
    args: [{
      selector: 'ngxd-do-check-hook',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class HostInputManager {
  host;
  name;
  static hostInputManagerMap = new WeakMap();
  changes;
  defaultDescriptor;
  value;
  refCount;
  disposed = false;
  constructor(host, name) {
    this.host = host;
    this.name = name;
    if (HostInputManager.hostInputManagerMap.has(host)) {
      const inputs = HostInputManager.hostInputManagerMap.get(host);
      if (inputs.has(name)) {
        return inputs.get(name);
      }
    }
    if (!HostInputManager.hostInputManagerMap.has(host)) {
      HostInputManager.hostInputManagerMap.set(host, new Map());
    }
    HostInputManager.hostInputManagerMap.get(host).set(name, this);
    this.changes = new Subject();
    this.defaultDescriptor = getPropertyDescriptor(host, name);
    if (this.defaultDescriptor && this.defaultDescriptor.get && !this.defaultDescriptor.set) {
      if (isDevMode()) {
        const constructorName = host.constructor.name;
        console.log(`You should use get and set descriptors both with dynamic components:
ERROR: not found '${name}' input, it has setter only, please add getter!

  class ${constructorName} {

    // Please add that 👇
    get ${name}() { ... }

    set ${name}() { ... }

  }`);
      }
    }
    this.refCount = 0;
    const defaultValue = host[name];
    Object.defineProperty(host, name, {
      get: () => {
        if (this.defaultDescriptor && this.defaultDescriptor.get) {
          return this.defaultDescriptor.get.call(host);
        }
        return this.value;
      },
      set: value => {
        if (this.defaultDescriptor && this.defaultDescriptor.set) {
          this.defaultDescriptor.set.call(host, value);
        }
        this.value = value;
        this.changes.next(value);
      },
      configurable: true
    });
    if (typeof defaultValue !== 'undefined') {
      host[name] = defaultValue;
    }
  }
  attach() {
    this.refCount++;
  }
  detach() {
    this.refCount--;
    if (this.refCount <= 0) {
      this.dispose();
    }
  }
  dispose() {
    const defaultValue = this.host[this.name];
    this.disposed = true;
    this.changes.complete();
    HostInputManager.hostInputManagerMap.get(this.host).delete(this.name);
    if (this.defaultDescriptor) {
      if (this.defaultDescriptor.writable) {
        this.defaultDescriptor.value = defaultValue;
      }
      Object.defineProperty(this.host, this.name, this.defaultDescriptor);
      if (this.defaultDescriptor.set) {
        this.host[this.name] = defaultValue;
      }
    } else {
      delete this.host[this.name];
      this.host[this.name] = defaultValue;
    }
  }
}
class HostManager {
  host;
  static hostManagerMap = new WeakMap();
  inputs;
  state;
  refCount;
  constructor(host) {
    this.host = host;
    if (HostManager.hostManagerMap.has(host)) {
      return HostManager.hostManagerMap.get(host);
    }
    this.inputs = new Map();
    this.state = {};
    this.refCount = 0;
    HostManager.hostManagerMap.set(host, this);
  }
  attach() {
    this.refCount++;
  }
  attachInput(propertyDef) {
    const hostInputManager = new HostInputManager(this.host, propertyDef.outsidePropName);
    hostInputManager.attach();
    this.inputs.set(propertyDef.outsidePropName, hostInputManager);
    return {
      ...propertyDef,
      defaultDescriptor: hostInputManager.defaultDescriptor
    };
  }
  getInputManager(bindingDef) {
    return this.inputs.get(bindingDef.outsidePropName);
  }
  detachInput(bindingDef) {
    const hostInputManager = this.inputs.get(bindingDef.outsidePropName);
    hostInputManager.detach();
    if (hostInputManager.disposed) {
      this.inputs.delete(bindingDef.outsidePropName);
    }
  }
  detach() {
    this.refCount--;
    if (this.refCount <= 0) {
      HostManager.hostManagerMap.delete(this.host);
    }
  }
}
class NgxComponentOutletRef {
  viewContainerRef;
  componentMirror;
  componentRef;
  host;
  context = {};
  changeDetectorRef;
  attachedInputs = [];
  attachedOutputs = [];
  propertyDefs = [];
  bindingDefs = [];
  hostManager;
  detachLifecycle;
  static create(componentType, viewContainerRef, injector, projectableNodes, host) {
    const componentMirror = reflectComponentType(componentType);
    const environmentInjector = createEnvironmentInjector([], injector);
    const componentRef = createComponent(componentType, {
      environmentInjector: environmentInjector,
      projectableNodes
    });
    const ngxComponentOutletRef = new NgxComponentOutletRef({
      componentMirror: componentMirror,
      componentRef,
      host
    }, viewContainerRef);
    viewContainerRef.insert(componentRef.hostView, viewContainerRef.length);
    return ngxComponentOutletRef;
  }
  constructor(config, viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    this.componentMirror = config.componentMirror;
    this.componentRef = config.componentRef;
    this.host = config.host;
    this.changeDetectorRef = this.componentRef.injector.get(ChangeDetectorRef, this.componentRef.changeDetectorRef);
    this.propertyDefs = this.componentMirror.inputs.map(toPropertyDef(this.context, this.componentRef.instance, this.host));
    this.attachHost();
    this.attachInputs();
    this.attachLifecycle();
    this.attachOutputs();
  }
  dispose() {
    this.disposeOutputs();
    this.disposeInputs();
    this.detachHost();
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    if (this.detachLifecycle) {
      this.detachLifecycle();
      this.detachLifecycle = null;
    }
  }
  updateContext(context) {
    const contextProps = context ? Object.keys(context) : [];
    for (const contextPropName of contextProps) {
      const bindingDef = this.getBindingDef(contextPropName);
      if (bindingDef) {
        this.detachHostInput(bindingDef);
      }
      const propertyDef = this.getPropertyDef(contextPropName);
      if (propertyDef && this.context[propertyDef.outsidePropName] !== context[contextPropName]) {
        this.context[propertyDef.outsidePropName] = context[contextPropName];
      }
    }
    const unattachedProps = this.propertyDefs.filter(propertyDef => !(contextProps.indexOf(propertyDef.outsidePropName) > -1 || this.getBindingDef(propertyDef.outsidePropName)));
    for (const propertyDef of unattachedProps) {
      const bindingDef = this.attachHostInput(propertyDef);
      this.attachInput(bindingDef);
    }
  }
  getPropertyDef(outsidePropName) {
    return this.propertyDefs.find(_ => _.outsidePropName === outsidePropName);
  }
  getBindingDef(outsidePropName) {
    return this.bindingDefs.find(_ => _.outsidePropName === outsidePropName);
  }
  attachHost() {
    this.hostManager = new HostManager(this.host);
    this.hostManager.attach();
  }
  detachHost() {
    this.hostManager.detach();
    this.hostManager = null;
  }
  attachHostInput(propertyDef) {
    const bindingDef = this.hostManager.attachInput(propertyDef);
    this.bindingDefs.push(bindingDef);
    return bindingDef;
  }
  detachHostInput(bindingDef) {
    this.hostManager.detachInput(bindingDef);
    this.bindingDefs = this.bindingDefs.filter(_ => _ !== bindingDef);
  }
  attachInputs() {
    this.bindingDefs = [];
    for (const propertyDef of this.propertyDefs) {
      const bindingDef = this.attachHostInput(propertyDef);
      this.attachContextPropertyToComponentInput(bindingDef);
      this.attachInput(bindingDef);
    }
  }
  attachInput(bindingDef) {
    const host = this.host;
    const hostManager = this.hostManager;
    const context = this.context;
    const defaultValue = host[bindingDef.outsidePropName];
    if (typeof defaultValue !== 'undefined') {
      context[bindingDef.outsidePropName] = defaultValue;
    }
    const subscription = hostManager.getInputManager(bindingDef).changes.subscribe(value => {
      context[bindingDef.outsidePropName] = value;
    });
    this.attachedInputs.push(subscription);
  }
  attachContextPropertyToComponentInput(bindingDef) {
    const {
      insidePropName,
      outsidePropName,
      defaultDescriptor
    } = bindingDef;
    Object.defineProperty(bindingDef.context, outsidePropName, {
      get: () => {
        if (defaultDescriptor && defaultDescriptor.get) {
          return defaultDescriptor.get.call(bindingDef.context);
        } else {
          return bindingDef.dynamicContext[insidePropName];
        }
      },
      set: value => {
        if (bindingDef.dynamicContext[insidePropName] === value) {
          return void 0;
        }
        let simpleChanges = SimpleChangesWeakMap.get(bindingDef.dynamicContext);
        if (simpleChanges == null) {
          simpleChanges = {};
          SimpleChangesWeakMap.set(bindingDef.dynamicContext, simpleChanges);
        }
        if (!WasChangesBeforeWeakMap.has(bindingDef.dynamicContext)) {
          WasChangesBeforeWeakMap.set(bindingDef.dynamicContext, new Map());
        }
        const isFirstChange = !WasChangesBeforeWeakMap.get(bindingDef.dynamicContext).get(outsidePropName);
        WasChangesBeforeWeakMap.get(bindingDef.dynamicContext).set(outsidePropName, true);
        simpleChanges[outsidePropName] = new SimpleChange(bindingDef.dynamicContext[insidePropName], value, isFirstChange);
        if (defaultDescriptor && defaultDescriptor.set) {
          defaultDescriptor.set.call(bindingDef.hostContext, value);
        }
        try {
          bindingDef.dynamicContext[insidePropName] = value;
        } catch (e) {
          if (isDevMode()) {
            const constructorName = bindingDef.dynamicContext.constructor.name;
            console.log(`You should use get and set descriptors both with dynamic components:
ERROR: not found '${insidePropName}' input, it has getter only, please add setter!

  class ${constructorName} {

    get ${insidePropName}() { ... }

    // Please add that 👇
    set ${insidePropName}() { ... }

  }`);
            console.error(e);
          }
        }
        this.changeDetectorRef.markForCheck();
      }
    });
  }
  attachLifecycle() {
    this.detachLifecycle = DoCheckHook.attach(this.componentRef, this.viewContainerRef);
  }
  disposeInputs() {
    for (const bindingDef of this.bindingDefs) {
      this.detachHostInput(bindingDef);
    }
    for (const subscription of this.attachedInputs) {
      subscription.unsubscribe();
    }
    this.attachedInputs.splice(0);
  }
  attachOutputs() {
    const propertyDefs = this.componentMirror.outputs.map(toPropertyDef(this.context, this.componentRef.instance, this.host));
    for (const propertyDef of propertyDefs) {
      if (propertyDef.outsidePropName in this.host) {
        const subscription = this.componentRef.instance[propertyDef.insidePropName].subscribe(this.host[propertyDef.outsidePropName]);
        this.attachedOutputs.push(subscription);
      }
    }
  }
  disposeOutputs() {
    for (const subscription of this.attachedOutputs) {
      subscription.unsubscribe();
    }
    this.attachedOutputs.splice(0);
  }
}
class NgxComponentOutletDirective {
  viewContainerRef;
  changeDetectorRef;
  ngxComponentOutlet;
  ngxComponentOutletInjector;
  ngxComponentOutletContent;
  ngxComponentOutletContext;
  ngxComponentOutletActivate = new EventEmitter();
  ngxComponentOutletDeactivate = new EventEmitter();
  ngxComponentOutletRef;
  _host;
  get host() {
    if (this._host) {
      return this._host;
    }
    const {
      context
    } = this.changeDetectorRef;
    return this._host = context;
  }
  get injector() {
    return this.ngxComponentOutletInjector || this.viewContainerRef.injector;
  }
  constructor(viewContainerRef, changeDetectorRef) {
    this.viewContainerRef = viewContainerRef;
    this.changeDetectorRef = changeDetectorRef;
  }
  ngOnChanges(changes) {
    if (changes.ngxComponentOutlet || changes.ngxComponentOutletInjector) {
      this.destroyNgxComponentOutletRef();
      this.createNgxComponentOutletRef();
    }
    if (changes.ngxComponentOutletContext) {
      this.updateContext();
    }
  }
  ngOnDestroy() {
    this.destroyNgxComponentOutletRef();
  }
  updateContext() {
    if (this.ngxComponentOutletRef) {
      this.ngxComponentOutletRef.updateContext(this.ngxComponentOutletContext);
    }
  }
  createNgxComponentOutletRef() {
    if (this.ngxComponentOutlet) {
      this.ngxComponentOutletRef = NgxComponentOutletRef.create(this.ngxComponentOutlet, this.viewContainerRef, this.injector, this.ngxComponentOutletContent, this.host);
      if (this.ngxComponentOutletContext) {
        this.updateContext();
      }
      this.ngxComponentOutletActivate.emit(this.ngxComponentOutletRef.componentRef.instance);
    }
  }
  destroyNgxComponentOutletRef() {
    if (this.ngxComponentOutletRef) {
      this.ngxComponentOutletDeactivate.emit(this.ngxComponentOutletRef.componentRef.instance);
      this.ngxComponentOutletRef.dispose();
      this.ngxComponentOutletRef = null;
    }
  }
  /** @nocollapse */
  static ɵfac = function NgxComponentOutletDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxComponentOutletDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NgxComponentOutletDirective,
    selectors: [["", "ngxComponentOutlet", ""]],
    inputs: {
      ngxComponentOutlet: "ngxComponentOutlet",
      ngxComponentOutletInjector: "ngxComponentOutletInjector",
      ngxComponentOutletContent: "ngxComponentOutletContent",
      ngxComponentOutletContext: "ngxComponentOutletContext"
    },
    outputs: {
      ngxComponentOutletActivate: "ngxComponentOutletActivate",
      ngxComponentOutletDeactivate: "ngxComponentOutletDeactivate"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxComponentOutletDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxComponentOutlet]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    ngxComponentOutlet: [{
      type: Input
    }],
    ngxComponentOutletInjector: [{
      type: Input
    }],
    ngxComponentOutletContent: [{
      type: Input
    }],
    ngxComponentOutletContext: [{
      type: Input
    }],
    ngxComponentOutletActivate: [{
      type: Output
    }],
    ngxComponentOutletDeactivate: [{
      type: Output
    }]
  });
})();
class NgxComponentOutletResolvePipe {
  transform(resolver, value) {
    return resolver && resolver.resolve(value);
  }
  /** @nocollapse */
  static ɵfac = function NgxComponentOutletResolvePipe_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxComponentOutletResolvePipe)();
  };
  /** @nocollapse */
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "resolve",
    type: NgxComponentOutletResolvePipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxComponentOutletResolvePipe, [{
    type: Pipe,
    args: [{
      name: 'resolve',
      pure: true
    }]
  }], null, null);
})();
class NgxdModule {
  /** @nocollapse */static ɵfac = function NgxdModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxdModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxdModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxdModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxComponentOutletDirective, NgxComponentOutletResolvePipe, DoCheckHook],
      exports: [NgxComponentOutletDirective, NgxComponentOutletResolvePipe]
    }]
  }], null, null);
})();
class NgxdResolver {
  config;
  constructor(providers = []) {
    this.config = providers.reduce((config, provider) => config.set(provider.type, provider.component), new Map());
  }
  resolve(type) {
    if (type && type.constructor) {
      return this.config.get(type.constructor) || this.config.get(type) || null;
    }
    return this.config.get(type) || null;
  }
}
class NgxdProvider {
  type;
  component;
}
function Dynamic({
  token
} = {}) {
  return function (provider) {
    return {
      provide: token,
      useValue: provider,
      multi: true
    };
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { Dynamic, NgxComponentOutletDirective, NgxComponentOutletResolvePipe, NgxdModule, NgxdProvider, NgxdResolver };
