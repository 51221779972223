import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SharedDataService } from '@app/core/services';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnChanges {
  @Input() image: string = ''; // 'not-found.png'
  @Input() showInfoImage: boolean = false;
  @Input() infoMessage: string = '';
  @Input() text: any = `No project created yet.<br> Start your your first project here!`;
  constructor(public sharedDataService: SharedDataService) {}

  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }
}
