import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreventKeyboardKeysDirective } from './prevent-keyboard-keys.directive';

@NgModule({
  declarations: [PreventKeyboardKeysDirective],
  imports: [CommonModule],
  exports: [PreventKeyboardKeysDirective],
})
export class PreventKeyboardKeysDirectiveModule {}
