import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CONST_LIST from '../constants/system.constant';
import * as ROUTE_LIST from '../constants/routes.constant';
import * as API_LIST from '../constants/apis-list.constant';
import { HttpService, HelperService } from '../services';

/*
 * Base Resolver
 * Top Level Resolver
 */
@Injectable()
export class BaseResolver {
  protected router: Router;
  protected route: ActivatedRoute;
  public httpService: HttpService;
  public helperService: HelperService;

  public constantList = CONST_LIST;
  public routeList = ROUTE_LIST;
  public apiList = API_LIST;

  /**
   * Initializes the constructor of the class with the provided injector.
   *
   * @param {Injector} injector - The injector used to retrieve dependencies.
   */
  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.httpService = injector.get(HttpService);
    this.helperService = injector.get(HelperService);
  }

  /**
   * Redirects the user to the specified URL or the home page if no URL is provided.
   *
   * @param {string} url - The URL to navigate to. If not provided, the user will be redirected to the home page.
   * @return {Promise<void>} A promise that resolves when the navigation is complete.
   */
  public redirectToLink(url?: string) {
    this.router
      .navigate([url || ''])
      .then()
      .catch();
  }
}
