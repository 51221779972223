<div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
  <div class="d-flex flex-row-reverse">
    @if (isEditMode() && isLoading === false) {
      <status
        [data]="baseModel"
        [element]="baseModel"
        [options]="constantList.STATUS_ARRAY"
        [column]="column"
        (updatedStatus)="updateStatus($event.status)"></status>
    }
  </div>
</div>
<form [formGroup]="formGroup" novalidate>
  <div
    class="d-flex gap-3 align-items-center mb-4 flex-column flex-wrap align-content-start w-50 float-left">
    <label htmlFor="upload1" class="required">
      {{ 'Category Image' | translate }}
      <button
        type="button"
        class="btn-transparent ms-2 tooltip-btn"
        tooltipClass="tooltip-info-details"
        placement="right"
        [ngbTooltip]="tipContent">
        <i class="middle icon-info-circle-outline f-18"></i>
      </button>
    </label>
    <div class="wd-100">
      <img
        [src]="profileImage || 'assets/images/user-profile-placeholder.png'"
        class="w-100 user-img"
        alt="user" />
    </div>
    <input
      [attr.disabled]="pageMode === 'view' ? true : null"
      [class.disabled]="pageMode === 'view'"
      type="file"
      id="upload1"
      hidden
      (change)="onPictureUpload($event)"
      accept="image/png, image/jpg, image/jpeg" />

    <label
      for="upload1"
      onKeyPress="onPictureUpload($event)"
      [class.disabled]="pageMode === 'view'"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'COMPONENTS.USERS.UPLOAD_PHOTO' | translate }}
    </label>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="name" class="required">
          {{ 'Category Title' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="name"
          class="form-control"
          type="text"
          appNoConsecutiveSpaces
          id="name"
          name="name"
          placeholder="Category Title" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: formGroup.controls['name']
          }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="description">
          {{ 'Description' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="description"
          name="description"
          id="description"
          appNoConsecutiveSpaces
          class="form-control"
          type="text"
          placeholder="Description" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['description'] }"></ng-container>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="categoryId" [class.required]="hasSpecificRole === false">
          {{ 'Category ID' | translate }}
        </label>
        <input
          id="categoryId"
          appNoConsecutiveSpaces
          [class.disabled]="true"
          name="categoryId"
          class="form-control"
          type="text"
          readonly
          onfocus="this.removeAttribute('readonly');"
          placeholder="" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['categoryId'] }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="order" class="required">
          {{ 'Category Order' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="order"
          name="order"
          id="order"
          class="form-control"
          type="text"
          onlyInteger
          min="0"
          max= "99"
          minlength="1" 
          maxlength="2"
          placeholder="Order" />
        <ng-container
          [ngTemplateOutlet]="reqOrderErrRef"
          [ngTemplateOutletContext]="{ control: formGroup.controls['order'] }"></ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="pageMode === 'edit'" class="d-flex justify-content-end gap-2">
    <button
      (click)="setCategory()"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.RESET' | translate }}
    </button>
    <button
      type="button"
      appDebounceClick
      (debounceClick)="onSubmit('active')"
      class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
    </button>
  </div>
  <div *ngIf="pageMode === 'add'" class="d-flex justify-content-end gap-2">
    <button
      (click)="setCategory('cancel')"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.CANCEL' | translate }}
    </button>
    <button
      type="button"
      appDebounceClick
      (debounceClick)="onSubmit('active')"
      class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.SAVE' | translate }}
    </button>
  </div>
</form>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    } @else if (
      name === 'employeeId' && (control?.hasError('minlength') || control?.hasError('maxlength'))
    ) {
      <small class="text-danger">
        {{ 'COMMON.VALIDATION.USER_ID_VALIDATION' | translate }}
      </small>
    }
  }
</ng-template>
<ng-template #reqOrderErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_ORDER_VALUE' | translate }}</small>
    }
  }
</ng-template>
<ng-template #tipContent>
  <ul class="mb-0 ps-3">
    <li class="tx-12">Size: 0.3 MB</li>
    <li class="tx-12">Dimensions: 1200x900</li>
  </ul>
</ng-template>
