import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RejectionReasonDialogComponent } from '@components/rejection-reason-dialog/rejection-reason-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [RejectionReasonDialogComponent],
  exports: [RejectionReasonDialogComponent],
  imports: [CommonModule, LazyLoadImageModule],
})
export class RejectionReasonDialogModule {}
