<mat-card>
  <form (submit)="add()" [formGroup]="addForm">
    <mat-card-title>Add payment method</mat-card-title>
    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error *ngIf="addForm.controls.name.invalid">
          Invalid name
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="description" />
        <mat-error *ngIf="addForm.controls.description.invalid">
          Invalid description
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Price</mat-label>
        <span matPrefix>{{ 0 | formatCurrency: true }}&nbsp;</span>
        <input matInput type="number" formControlName="price" />
        <mat-error *ngIf="addForm.controls.price.invalid">
          Invalid price
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-flat-button color="primary" type="submit">Add</button>
    </mat-card-actions>
  </form>
</mat-card>
