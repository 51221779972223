<div class="mb-4 align-items-center d-flex justify-content-between">
  <h4 class="tx-16 mb-0 fw-500">{{ 'COMPONENTS.USERS.USER_DOCUMENTS' | translate }}</h4>
  <div class="form-field" [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <div class="upload-doc-btn d-flex justify-content-end">
      <input
        [attr.disabled]="pageMode === 'view' ? true : null"
        [class.disabled]="pageMode === 'view'"
        type="file"
        id="upload"
        hidden
        multiple
        (change)="onFileChange($event)"
        accept="image/png, image/jpg, image/jpeg, .pdf" />
      <label
        role="button"
        for="upload"
        class="btn btn-outline btn-outline-primary mb-0 btn-140 btn-icon-text">
        <i class="icon icon-upload middle"></i>
        {{ 'COMPONENTS.USERS.UPLOAD' | translate }}
      </label>
    </div>
  </div>
</div>
<form [formGroup]="documentForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="drivingLicenseNumber" class="required">
          {{ 'COMPONENTS.USERS.DRIVING_LICENSE_NUMBER' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          id="drivingLicenseNumber"
          name="drivingLicenseNumber"
          type="text"
          appOnlyNumber
          class="form-control"
          formControlName="drivingLicenseNumber"
          placeholder="1234567891278" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: documentForm.controls['drivingLicenseNumber']
          }"></ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="drivingLicenseExpiryDate" class="required">
          {{ 'COMPONENTS.USERS.DRIVING_LICENSE_EXPIRY_DATE' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          name="drivingLicenseExpiryDate"
          id="drivingLicenseExpiryDate"
          type="date"
          class="form-control"
          placeholder="08/04/2030"
          onfocus="this.showPicker()"
          (keydown)="(false)"
          formControlName="drivingLicenseExpiryDate"
          onclick="this.showPicker()" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: documentForm.controls['drivingLicenseExpiryDate']
          }"></ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="nationalIdentityNumber" class="required">
          {{ 'COMPONENTS.USERS.NATIONAL_IDENTITY_NUMBER' | translate }}
        </label>
        <input
          id="nationalIdentityNumber"
          [class.disabled]="pageMode === 'view'"
          name="nationalIdentityNumber"
          type="number"
          class="form-control"
          appOnlyNumber
          appNoConsecutiveSpaces
          formControlName="nationalIdentityNumber"
          placeholder="1234567891278" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: documentForm.controls['nationalIdentityNumber']
          }"></ng-container>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="documentFiles">
    <div class="col-md-6 mb-3" *ngFor="let file of documentFiles; index as i">
      <div class="doc-box d-flex align-items-center gap-4 justify-content-between w-100">
        <div class="d-flex gap-2 align-items-center">
          <img
            class="file-type-icon"
            src="{{ 'assets/images/' + file?.fileName?.split('.')[1] + '.png' }}"
            alt="{{ file.fileName }}" />
          <div class="d-flex flex-column justify-content-between">
            <h5 class="fw-500 f-16 text-underline">
              {{
                file?.fileName?.length > 17 ? file?.fileName?.slice(0, 17) + '...' : file?.fileName
              }}
            </h5>
            <span class="f-14">
              {{ 'COMPONENTS.USERS.UPLOADED_ON' | translate }} {{ file?.createdAt }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center gap-2">
          <a
            (click)="downloadMyFile(file)"
            class="btn btn-outline btn-outline-primary btn-140 btn-icon-text">
            <i class="icon icon-download middle" data-feather="download" appFeatherIcon></i>
            {{ 'COMPONENTS.USERS.DOWNLOAD' | translate }}
          </a>
          <button
            (click)="onDocumentDelete(file, i)"
            type="button"
            class="btn-transparent"
            [viewPermission]="[this.permissionsList.UPDATE_USERS]">
            <i
              class="icon middle icon-trash-filled text-primary tx-24"
              data-feather="trash"
              appFeatherIcon></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="pageMode === 'edit'"
    class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button
      (click)="setDocuments()"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.RESET' | translate }}
    </button>
    <button (click)="submitDocuments()" type="button" class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
    </button>
  </div>
</form>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    }
  }
</ng-template>
