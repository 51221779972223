import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Define a custom property decorator for debouncing a method
type PropertyDecorator = (
  target: Object,
  propertyKey: string | symbol,
  descriptor: PropertyDescriptor,
) => PropertyDescriptor;

/**
 * Debounce decorator to throttle method calls.
 * @param delay The debounce delay in milliseconds (default is 300ms)
 */
export function Debounce(delay: number = 300): PropertyDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: any) {
    // Store a reference to the original method
    const originalMethod = descriptor.value;

    // Generate a unique subject key for each decorated method
    const subjectKey = `__debounce_subject_${String(propertyKey)}`;

    // Override the method with a debounced version
    descriptor.value = function (...args: any[]) {
      // Initialize a subject for debouncing if it doesn't exist
      if (!this[subjectKey]) {
        this[subjectKey] = new Subject<any>();

        // Pipe the subject through debounceTime and subscribe to execute the original method
        this[subjectKey].pipe(debounceTime(delay)).subscribe(() => {
          originalMethod.apply(this, this[subjectKey].args); // Apply the original method with stored arguments
        });
      }

      // Store the current method arguments
      this[subjectKey].args = args;

      // Trigger the subject to debounce the method call
      this[subjectKey].next();
    };

    return descriptor;
  };
}
