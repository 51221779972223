import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewPermissionDirective } from '@src/app/core/shared/directives/view-permission/view-permission.directive';

@NgModule({
  declarations: [ViewPermissionDirective],
  imports: [CommonModule],
  exports: [ViewPermissionDirective],
})
export class ViewPermissionDirectiveModule {}
