import { Routes, RouterModule } from '@angular/router';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { CategoriesComponent } from './categories.component';
import { CategoryListingComponent } from './views/category-listing/category-listing.component';
import { CategoryDetailComponent } from './views/category-detail/category-detail.component';
import { CategoryResolver } from './core/resolvers/category-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: CategoriesComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: CategoryListingComponent,
      },
      {
        path: ROUTE_LIST.VIEW_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: CategoryDetailComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: CategoryDetailComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: CategoryDetailComponent,
        resolve: {
          store: CategoryResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
