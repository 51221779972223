import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StoreManagement } from '../interfaces/store-management.interface';

@Injectable({
  providedIn: 'root',
})
export class StoreManagementService {
  private storesList: StoreManagement[] = [];

  isDataModified = false;

  /**
   * Initializes the StoreManagementService by populating the stores array with 50 objects,
   * each representing a store, and setting the status of the third stores to 'active'.
   *
   * @return {void} This function does not return anything.
   */
  constructor() {
    for (let index = 0; index < 50; index++) {
      this.storesList.push({
        id: 1,
        first_name: 'Omer',
        last_name: 'Lamin',
        email: 'lamar@domain.com',
        contact: '+220 123 4567',
        role: 'Driver',
        created_date: '21/11/2022',
        status: 'in_active',
      });
    }

    this.storesList[2].status = 'active';
  }

  /**
   * Retrieves the list of stores.
   *
   * @return {Observable<StoreManagement[]>} An observable that emits the list of stores.
   */
  public getStores(): Observable<StoreManagement[]> {
    return of(this.storesList);
  }
}
