import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from '@app/features/user-management/views/base/base.component';
import { UserDetailsResolver } from './core/resolvers/user-details-resolver.service';
import { HasPermissionGuard } from '@src/app/core/guards';
import { PERMISSIONS_LIST } from '@src/app/core/constants';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { UserListingComponent } from './views/users-listing/users-listing.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { UnsavedDataChangesGuard } from '@src/app/core/guards/unsaved-data-changes.guard';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: UserListingComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: UserProfileComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: UserProfileComponent,
        resolve: {
          user: UserDetailsResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
