import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from '@app/features/user-management/views/base/base.component';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import { SubCategoryResolver } from './core/resolver/sub-category-resolver.service';
import { SubCategoryListingComponent } from './views/sub-category-listing/sub-category-listing.component';
import { CreateSubCategoryComponent } from './views/create-sub-category/create-sub-category.component';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: SubCategoryListingComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: CreateSubCategoryComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: CreateSubCategoryComponent,
        resolve: {
          subCategory: SubCategoryResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
