import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnDestroy,
  ViewChild,
  signal,
} from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiEndPointConfiguration } from '@src/app/app-types';
import { BaseComponent } from '@src/app/core/base';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { TableActions } from '@src/app/core/interfaces/table-actions.interface';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { TableComponent } from '@src/app/core/shared/components/table/table.component';
import { TableModule } from '@src/app/core/shared/components/table/table.module';
import { GET_AUDITS_BASE_URL, GET_CATEGORIES_BASE_URL, GET_MODULES_BASE_URL } from '../../core';

@Component({
  selector: 'app-user-activities',
  standalone: true,
  templateUrl: './user-activities.component.html',
  styleUrls: ['./user-activities.component.scss'],
  imports: [...COMMON_MODULES, TableModule, NgbDropdownModule, ConfirmDialogModule],
})
export class UserActivitiesComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) userId: string = '';
  @ViewChild(TableComponent, { static: false }) tableCompRef!: TableComponent;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  private selectedRows = signal([]);
  public confirmEventAction: 'active' | 'delete' | null = null;
  public endPointConfiguration: ApiEndPointConfiguration = {
    method: 'GET',
    url: GET_AUDITS_BASE_URL,
  };
  public requestBody: any = {};

  public paramFilters: any[] = [
    {
      type: 'autocomplete',
      key: 'filter.module.category.id',
      method: 'GET',
      placeholder: 'COMPONENTS.USERS.MODULE',
      field: this.getLanguageSpecificKey('name'),
      filterKey: 'id',
      preFetch: true,
      searchable: true,
      multiple: true,
      operation: '$in:',
      apiUrl: GET_CATEGORIES_BASE_URL,
      size: 100,
      loading: true,
      closeOnSelect: false,
    },
    {
      type: 'autocomplete',
      key: 'filter.module.id',
      method: 'GET',
      placeholder: 'COMPONENTS.USERS.SUB_MODULE',
      field: this.getLanguageSpecificKey('name'),
      filterKey: 'id',
      preFetch: true,
      searchable: true,
      multiple: true,
      operation: '$in:',
      apiUrl: GET_MODULES_BASE_URL,
      size: 100,
      loading: true,
      closeOnSelect: false,
    },
    {
      key: 'fromDate',
      type: 'date',
      operation: '$btw:',
      placeholder: 'COMMON.TEXT.DATE_FROM',
      mutualKey: 'filter.createdAt',
      maxTo: 'toDate',
    },
    {
      key: 'toDate',
      type: 'date',
      placeholder: 'COMMON.TEXT.DATE_TO',
      mutualKey: 'filter.createdAt',
      minFrom: 'fromDate',
    },
  ];
  public displayedColumnsViewArray: any[] = [];

  /**
   * Constructor of UserActivitiesComponent
   *
   * @param injector Injects services
   */
  constructor(private injector: Injector) {
    super(injector);

    // Sets the userId in the request body after 500 milliseconds
    // This is done to ensure that the userId is set before the
    // component's view is initialized
    setTimeout(() => {
      this.requestBody['filter.user.id'] = this.userId;
    }, 500);
  }

  /**
   * Overrides the ngOnDestroy method of the superclass to perform additional cleanup tasks.
   *
   * This method is called when the component is about to be destroyed. It calls the ngOnDestroy method of the superclass
   * to ensure that any cleanup tasks defined in the superclass are also executed.
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * This hook is called after the component's view has been fully
   * initialized. It is called once when the view is being initialized,
   * and will not be called again if the view is re-attached later.
   *
   * Use this hook to perform any necessary setup operations,
   * such as setting up event listeners or querying the DOM tree.
   */
  ngAfterViewInit(): void {
    // Timeout is used to ensure that ViewChild elements are
    // initialized before calling their functions
    setTimeout(() => {
      /**
       * Initializes table columns
       *
       * This function is called to set the column headers of table.
       * It is called after the component's view has been fully
       * initialized.
       */
      this.initTableColumns(); // To set the column headers of table
    }, 500);
  }
  /**
   * This function will be called when user clicks on any
   * element in the table.
   * @param e Event emitted by the table
   */
  public onElementClick(e: any) {
    // Checking if user has selected any element
    if (e.element.length > 0) {
      // Setting the selected rows
      this.selectedRows.set(e.element);
    }
  }

  /**
   * Following method will work against particullar ation performed
   * @param e Action emitted by filter action in table
   */
  public tableAction({ key }: TableActions) {
    if (this.selectedRows().length > 0) {
      // Prepairing ids to send alonmg API request
      const ids = this.selectedRows().map((item: any) => {
        return item.id;
      });
    } else {
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.SELECT_ROW'), 'error');
    }
  }

  /**
   * Updates the table data
   * @param msg Success message to be displayed
   */
  private updateTable(msg: string) {
    // Show success message
    this.httpService.showMessage(msg, 'success');
    // Reload the table data
    this.tableCompRef.loadResourcesPage();
    // Clear the bulk select list
    this.tableCompRef.bulkSelectList = [];
    // Clear the selected rows
    this.selectedRows.set([]);
  }

  /**
   * Initializes the table columns for the user activity table
   */
  private initTableColumns(): void {
    this.displayedColumnsViewArray = [
      /**
       * Checkbox column
       */
      {
        key: '',
        value: '',
        type: 'checkbox',
        headingClass: 'col-check',
      },
      /**
       * User ID column
       */
      {
        key: 'user.employeeId',
        value: '#',
        type: 'text',
        sortable: true,
        sortKey: 'employeeId',
      },
      /**
       * Date column
       */
      {
        key: 'createdAt',
        value: 'COMPONENTS.USERS.DATE',
        type: 'text',
        sortable: true,
        /**
         * Callback function to format the date column
         */
        callback: (date: any) => {
          if (typeof date === 'string') {
            return date?.split(' ')[0] || '-';
          }
          return '-';
        },
      },
      /**
       * Time column
       */
      {
        key: 'createdAt',
        value: 'COMPONENTS.USERS.TIME',
        type: 'text',
        sortable: false,
        /**
         * Callback function to format the time column
         */
        callback: (date: any) => {
          if (typeof date === 'string') {
            return date?.split(' ')[1] || '-';
          }
          return '-';
        },
      },
      /**
       * Module column
       */
      {
        key: 'moduleName',
        value: 'COMPONENTS.USERS.MODULE',
        type: 'text',
        sortable: false,
        /**
         * Returns the translated text of the category name if it exists, otherwise returns a hyphen.
         *
         * @param {any} data - The data object containing the category name.
         * @return {string} The translated text of the category name if it exists, otherwise a hyphen.
         */
        callback: (data: any) => {
          return data?.category?.name ? this.helperService.getTranslatedText(data?.category?.name) : '-';
        },
      },
      {
        key: 'subModuleName',
        value: 'COMPONENTS.USERS.SUB_MODULE',
        type: 'text',
        sortable: false,
        /**
         * Returns the translated text of the module name if it exists, otherwise returns a hyphen.
         *
         * @param {any} data - The data object containing the module name.
         * @return {string} The translated text of the module name if it exists, otherwise a hyphen.
         */
        callback: (data: any) => {
          return data?.module?.name ? this.helperService.getTranslatedText(data?.module?.name) : '-';
        },
      },
      /**
       * Performed on column
       */
      {
        key: 'performedOn',
        value: 'COMPONENTS.USERS.PERFORMED_ON',
        type: 'text',
        sortable: false,
      },
      /**
       * Action column
       */
      {
        key: 'action',
        value: 'Action',
        type: 'text',
        sortable: false,
      },
    ];
  }
}
