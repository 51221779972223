import { firstValueFrom } from 'rxjs';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { ApiResponseInterface, ComponentCanDeactivate } from '@src/app/core/interfaces';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import moment from 'moment';
import * as constants from '@src/app/core/constants/system.constant';

import { ProfileRepository } from '@src/app/features/profile/core/repositories/profile.repository';
import { selectUser } from '@src/app/features/auth/core/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-profile',
  styleUrl: './profile.component.scss',
  templateUrl: './profile.component.html',
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgSelectModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
  ],
})
export class ProfileComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate
{
  public profileImage: any = 'assets/images/user-profile-placeholder.png';
  public image: any = null;
  public passwordType: 'password' | 'text' = 'password';
  public passwordForm: FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.pattern(this.constantList.passwordRegex)]),
  });

  public formGroup: FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.pattern(this.constantList.passwordRegex)]),
  });
  user$ = this.store.select(selectUser);
  public user: any;
  formBackup: any;
  isDataModified = false;

  /**
   * Constructor of the UserInfoComponent
   * @param injector The injector.
   */
  constructor(
    injector: Injector,
    private store: Store,
    private pageSettingsService: PageSettingsService,
    private profileRepository: ProfileRepository,
  ) {
    super(injector);

    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PROFILE.TITLE',
        link: this.routeList.PROFILE,
      },
    };

    // Use the service to update the pageSettings in BaseComponent
    this.pageSettingsService.updatePageSettings(newSettings);
    this.formBackup = this.helperService.clone(this.passwordForm.value);
  }

  canDeactivate(): Promise<boolean> | boolean {
    return this.isDataModified;
  }

  @Debounce(constants.DEFAULT_DEBOUNCE_TIME_1_SEC)
  onChanges(): void {
    // this.passwordForm.valueChanges.pipe(this.destroy$()).subscribe((val: any) => {
    //   this.isDataModified = !this.helperService.isEqual(
    //     this.formBackup,
    //     this.helperService.clone(val),
    //   );
    //   this.sharedDataService.dataModified.next({
    //     component: 'profile-form',
    //     isModified: this.isDataModified,
    //     data: this.helperService.clone(val),
    //   });
    // });
  }
  /**
   * @description
   * Initializes component and retrieves user data from route resolver
   */
  ngOnInit(): void {
    this.user = firstValueFrom(this.user$);
    // this.profileRepository.getAll().subscribe((res: any) => {
    //   if (res.body) {
    //     this.user = res.body;
    //     this.user.dob = moment(this.user?.dob, 'DD/MM/YYYY')?.format('YYYY-MM-DD');
    //     if (this.user?.profilePictureDocument !== null) {
    //       this.profileImage = this.user.profilePictureDocument?.path;
    //     }
    //   }
    // });
    this.onChanges();
  }

  /**
   * Handle the password change form submission
   */
  onPasswordChange() {
    const { valid, value } = this.passwordForm;
    const controls = this.passwordForm.controls;

    if (controls['password'].invalid) {
      if (value.password !== null && value.password !== '') {
        this.passwordForm.markAllAsTouched();
        this.httpService.showMessage(
          this.translate.instant('COMMON.VALIDATION.INVALID_PASSWORD_FORMAT'),
          'error',
        );
        return;
      }
    }

    // Check for invalid form
    if (!valid) {
      this.passwordForm.markAllAsTouched();
      this.httpService.showMessage(
        this.translate.instant('COMMON.VALIDATION.ENTER_REQUIRED_FIELDS'),
        'error',
      );
      return;
    }

    // Submit the form
    if (valid) {
      this.profileRepository
        .updatePassword(value)
        .pipe(this.destroy$())
        .subscribe({
          /**
           * Handles the response from the password change request.
           *
           * @param {any} res - The response object from the password change request.
           * @return {void} This function does not return anything.
           */
          next: (response: unknown) => {
            const res = response as ApiResponseInterface<any>;

            if (res?.status >= 200 && res?.status <= 210) {
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UPDATE_PROFILE'),
                'success',
              );

              this.passwordForm.reset();
            } else {
              this.httpService.showMessage(
                this.translate.instant('MSGS.USERS.UNABLE_TO_UPDATE_PASSWORD'),
                'error',
              );
            }
          },
        });
    }
  }

  /**
   * Handles file input change event
   * @param event file input change event
   */
  onPictureUpload(event: any) {
    if (!event.target.files[0]?.type.includes('image')) {
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
      return;
    }
    // Set the selected image details to the image variable
    this.image = {
      file: event.target.files[0],
      fileName: event.target.files[0]?.name,
    };

    if (this.image.file.size > 2 * 1024 * 1024) {
      this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.IMAGE_TOO_LARGE'), 'error');
      return;
    }
    // Create an instance of FileReader
    const reader = new FileReader();
    // Set the function to be called when loading is complete
    reader.onloadend = () => {
      // Set the image source to the result of reading the image
      this.profileImage = reader.result;
    };
    // Read the image as a data url
    reader.readAsDataURL(new Blob([event.target.files[0]], { type: event.target.files[0].type }));

    // Prepare form data for image upload
    const formData: any = new FormData();
    formData.append('image', this.image?.file);

    // Create requests for updating user and uploading image
    const uploadRequest = this.profileRepository.updateDetail(formData);

    uploadRequest.pipe(this.destroy$()).subscribe({
      /**
       * Handles the response from the update user profile request.
       *
       * @param {any} response - The response object from the update user profile request.
       * @return {void} This function does not return anything.
       */
      next: (response: any) => {
        if (response?.status >= 200 && response?.status <= 210) {
          this.authService.howIAm();
          // Show success message for updating user profile
          this.httpService.showMessage(
            this.translate.instant('MSGS.USERS.UPDATE_PROFILE'),
            'success',
          );
        }
      },
      error: error => {
        this.httpService.showMessage(
          this.translate.instant('COMPONENTS.ERROR_PAGE.TITLE_SOMETHING_WENT_WRONG'),
          'error',
        );
      },
    });
    uploadRequest.pipe(this.destroy$()).subscribe({
      next: (response: any) => {
        if (response?.status >= 200 && response?.status <= 210) {
          this.authService.howIAm();
          // Show success message for updating user profile
          this.httpService.showMessage(
            this.translate.instant('MSGS.USERS.UPDATE_PROFILE'),
            'success',
          );
        }
      },
      error: error => {
        this.httpService.showMessage(
          this.translate.instant('COMPONENTS.ERROR_PAGE.TITLE_SOMETHING_WENT_WRONG'),
          'error',
        );
      },
    });
  }
  /**
   * Toggle the password field between showing and hiding the password.
   */
  passwordToggle(): void {
    // Toggle the passwordType between 'password' and 'text'
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }
}
