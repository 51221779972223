import { Component, Injector, OnInit } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@src/app/core/base';
import { UserActivitiesComponent } from '../user-activities/user-activities.component';
import { takeUntil } from 'rxjs';
import { UserAboutComponent } from '../user-about/user-about.component';
import { ComponentCanDeactivate } from '@src/app/core/interfaces';
import { UserManagementService } from '@src/app/features/user-management/core/services/user-management.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [...COMMON_MODULES, NgbNavModule, UserActivitiesComponent, UserAboutComponent],
})
export class UserProfileComponent extends BaseComponent implements OnInit, ComponentCanDeactivate {
  public active: number = 1;
  public user_Type: string = 'admin_user';

  /**
   * Constructor for the UserProfileComponent.
   *
   * @param {Injector} injector - The injector instance.
   * @param {UserManagementService} userManagementService - The UserManagementService instance.
   */
  constructor(
    injector: Injector,
    private userManagementService: UserManagementService,
  ) {
    super(injector);
    if (this.isEditMode() == null) this.pageMode = 'add';
  }

  /**
   * Checks if the user data has been modified and returns a Promise<boolean> or boolean indicating whether the user can be deactivated.
   *
   * @return {Promise<boolean> | boolean} A Promise<boolean> or boolean indicating whether the user can be deactivated.
   */
  canDeactivate(): Promise<boolean> | boolean {
    return this.userManagementService.isDataModified;
  }

  /**
   * Component initialize method
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['type']) {
        this.user_Type = params['type'];
      }
    });
    // Subscribe to route data and get user data
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        // If data has user object
        if (data?.user) {
          // Set user to local baseModel
          this.baseModel = data?.user;
          this.baseModel?.roles?.forEach((element: any) => {
            if (element?.code && this.constantList.SPECIFIC_ROLES.includes(element.code)) {
              this.user_Type = 'app_user';
            }
          });
          // If user has roles
          if (this.baseModel.roles?.length > 0) {
            // Find index of role having code 'super_admin'
            const index = this.baseModel?.roles.findIndex(
              (role: any) => role.code === 'super_admin',
            );
            // If index is not -1, set pageMode to 'view'
            if (index !== -1) this.pageMode = 'view';
          }
        }
      });
  }
}
