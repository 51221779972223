<ng-template #dialog let-modal>
  <div class="confirm-dialog">
    <div class="modal-header" *ngIf="title">
      <h3 class="modal-title">{{ title }}</h3>
    </div>
    <div class="modal-body">
        <textarea
          maxlength="250"
          [value]="reason"
          appNoConsecutiveSpaces
          class="form-control"
          placeholder="{{ placeholder }}"
          (input)="onInputChanged($event)"
          rows="5"
        ></textarea>
        <div class="character-info-message-container">
          <p class="character-info-message">{{remainingCharacters}} Characters Remaining</p>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center align-items-center gap-3">
      <button type="button" class="btn btn-outline close" (click)="modal.dismiss(null)">
        {{ closeText }}
      </button>
      <button [class.disabled]="isSaveButtonDisabled"
       type="button" class="btn btn-primary" (click)="modal.close(true)">
        {{ confirmText }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' }}</small>
    } @else if (
      name === 'employeeId' && (control?.hasError('minlength') || control?.hasError('maxlength'))
    ) {
      <small class="text-danger">
        {{ 'COMMON.VALIDATION.USER_ID_VALIDATION' }}
      </small>
    }
  }
</ng-template>
