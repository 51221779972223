<app-table
  [linkText]="'View Detail Coomponent'"
  [canUpdateStatus]="isEditPermission"
  [paramFilters]="paramFilters"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  [enableTableAction]="false"
  [canUpdateStatus]="true"
  [allowedTableActions]="allowedTableActions"
  notFoundMessage="MSGS.GENERAL.NO_RECORD_AVAILABLE_TO_DISPLAY"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)"></app-table>

<app-confirm-dialog
  [confirmText]="'GENERAL.BUTTONS.DELETE' | translate"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  message="{{ 'MSGS.USERS.DELETE_USER' | translate }}"
  extraIcon="icon-trash-filled"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>

<input
  #fileInput
  type="file"
  style="display: none"
  (change)="onFileChange($event)"
  accept=".csv, text/csv" />
