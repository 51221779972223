import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  NgModule,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';
import { BaseComponent } from '@src/app/core/base';

@Component({
  selector: 'reject-reason-dialog',
  templateUrl: './rejection-reason-dialog.component.html',
  styleUrls: ['./rejection-reason-dialog.component.scss'],
})
export class RejectionReasonDialogComponent extends BaseComponent implements OnChanges {
  @Input() title: any = null;
  @Input() placeholder: any = null;
  @Input() closeText: any = this.translate.instant('GENERAL.BUTTONS.CLOSE') || 'Close';
  @Input() confirmText: any = this.translate.instant('GENERAL.BUTTONS.CONFIRM') || 'Confirm';
  @Input() eventType: string | null = null;

  public isSaveButtonDisabled = true;
  public reason = "";
  public maxCharacters = 250; // Set your character limit here
  public remainingCharacters = this.maxCharacters;

  @ViewChild('dialog', { static: true }) dialogRef!: ElementRef;
  @Output() closeDialog: EventEmitter<{
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
    reason: string | "";
  }> = new EventEmitter<{
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
    reason: string | "";
  }>();
  /**
   * Constructs a new instance of the ConfirmDialogComponent class.
   *
   * @param {Injector} injector - The injector used to inject dependencies.
   */
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }

  /**
   * Opens a dialog with the specified data.
   *
   * @param {any} data - The data to be passed to the dialog. Defaults to null.
   */
  openDialog(data: any = null) {
    if (this.dialogRef) {
      this.modalService
        .open(this.dialogRef, {
          size: 'md',
          centered: true,
          scrollable: true,
          windowClass: 'confirm-modal',
          animation: true,
          keyboard: false,
          backdrop: 'static',
          ariaLabelledBy: 'Confirmation',
        })
        .result.then(value => {
          this.closeDialog.emit({
            data: data,
            eventType: this.eventType,
            confirmed: true,
            action: 'confirm',
            reason: this.reason,
          });
          this.resetPopUp();
        })
        .catch(reason => {
          this.closeDialog.emit({
            data: data,
            eventType: this.eventType,
            confirmed: false,
            action: 'dismiss',
            reason: this.reason,
          });
          this.resetPopUp();
        });
    }
  }

  onInputChanged(event: any) {
    this.reason = event.target.value;
    const textLength = this.reason.length || 0;
    this.remainingCharacters = this.maxCharacters - textLength;
    if (textLength > 0) {
      this.isSaveButtonDisabled = false
    }
    else {
      this.isSaveButtonDisabled = true
    }
  }

  resetPopUp() {
    this.isSaveButtonDisabled = true;
    this.reason = "";
    this.maxCharacters = 250;
    this.remainingCharacters = this.maxCharacters;
  }

}
