<div
  class="empty-state d-flex justify-content-center align-items-center py-5 flex-column"
  [ngClass]="sharedDataService.appConfigSource.value.theme">
  @if (showInfoImage && image) {
    <img [lazyLoad]="'assets/images/others/' + image" alt="not found" />
    <!-- defaultImage="assets/images/404.png" -->
  }
  <p
    class="text-center mb-0 mx-auto message-text text-primary"
    [innerHTML]="showInfoImage && image ? (infoMessage | translate) : text"></p>
</div>
