export class SubCategoryUpdateRequest {
  name: string | null;
  description: string | null;
  order: number | null;
  isActivated: boolean | null;
  parentCategoryId: number | null;

  constructor(
    name: string | null = null,
    description: string | null = null,
    order: number | null = null,
    isActivated: boolean | null = null,
    parentCategoryId: number | null = null
  ) {
    this.name = name;
    this.description = description;
    this.order = order;
    this.isActivated = isActivated;
    this.parentCategoryId = parentCategoryId;
  }
}
