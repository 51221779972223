import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { SharedDataService } from '../../../services';

@Directive({
  selector: '[viewPermission]',
})
export class ViewPermissionDirective {
  /**
   * Constructs a new instance of the class.
   *
   * @param {ElementRef} elementRef - The reference to the element.
   * @param {Renderer2} renderer - The renderer for DOM manipulation.
   * @param {SharedDataService} sharedDataService - The shared data service.
   */
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public sharedDataService: SharedDataService,
  ) {}

    /**
     * Sets the value of the `shouldRemove` property and removes the element from the DOM if the user does not have edit permission.
     *
     * @param {any} value - The value to set for the `shouldRemove` property.
     */
  @Input('viewPermission') set shouldRemove(value: any) {

    let isEditPermission = this.sharedDataService.checkPermission(value, undefined);

    if (!isEditPermission) {
      // Remove the element from the DOM
      this.renderer.removeChild(
        this.elementRef.nativeElement.parentNode,
        this.elementRef.nativeElement,
      );
    }
  }
}
