
export const CATEGORY_BASE_URL: string = `/categories`;
export const CATEGORY_BY_ID_BASE_URL: string = `${CATEGORY_BASE_URL}/{id}`;
export const UPDATE_CATEGORY_PICTURE_BASE_URL: string = `${CATEGORY_BASE_URL}/picture`;
export const UPDATE_CATEGORY_STATUS_BASE_URL: string = `${CATEGORY_BASE_URL}/status`;
export const UPLOAD_CATEGORY_DOCUMETS_BASE_URL: string = `${CATEGORY_BASE_URL}/category/{id}/upload-document-file`;
export const DELETE_CATEGORY_DOCUMETS_BASE_URL: string = `${CATEGORY_BASE_URL}/category/{documentId}/delete-document-file`;
export const DOWNLOAD_CATEGORY_DOCUMETS_BASE_URL: string = `${CATEGORY_BASE_URL}/category/{documentFileId}/download-document-file`;
export const UPDATE_CATEGORY_DOCUMENT_BASE_URL: string = `${CATEGORY_BASE_URL}/category/{id}/document`;
export const UPDATE_CATEGORY_OFFICIAL_DETAILS_BASE_URL: string = `${CATEGORY_BASE_URL}/category/{id}/professional-detail`;
export const CATEGORY_BULK_STATUS: string = `${CATEGORY_BASE_URL}/bulk-status-update`;
export const CATEGORY_STATUS_UPDATE: string = `${CATEGORY_BASE_URL}/toggle-category-status/{id}`;
export const CATEGORY_BULK_DELETE: string = `${CATEGORY_BASE_URL}/bulk-delete`;

export const GET_COUNTRIES_BASE_URL: string = `system/countries`;
export const GET_CITIES_BASE_URL: string = `system/countries/{countryId}/cities`;
export const SYSTEM_ROLES_BASE_URL: string = `system/roles`;

export const GET_AUDITS_BASE_URL: string = `system/audits`;
export const GET_CATEGORIES_BASE_URL: string = `system/categories`;
export const GET_MODULES_BASE_URL: string = `system/modules`;