<div class="mb-4 align-items-center d-flex justify-content-betweenp">
  <h4 class="f-20 mb-0 fw-500">{{ 'COMPONENTS.USERS.USER_ADDRESS' | translate }}</h4>
</div>

<form [formGroup]="addressForm" novalidate>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="form-field">
        <label htmlFor="countryId" class="required">
          {{ 'COMPONENTS.USERS.COUNTRY' | translate }}
        </label>
        <ng-select
          [clearSearchOnAdd]="true"
          (change)="countryChanged($event)"
          [class.disabled]="pageMode === 'view'"
          [clearable]="false"
          placeholder="{{ 'COMPONENTS.USERS.SELECT_COUNTRY' | translate }}"
          formControlName="countryId"
          bindValue="id"
          [bindLabel]="getLanguageSpecificKey('name')"
          name="countryId"
          class="w-100">
          <ng-option *ngFor="let country of countries" [value]="country.id">
            {{ country?.name }}
          </ng-option>
        </ng-select>
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: addressForm.controls['countryId']
          }"></ng-container>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="form-field">
        <label for="cityId" class="required">
          {{ 'COMPONENTS.USERS.CITY' | translate }}
        </label>
        <ng-select
          [clearSearchOnAdd]="true"
          [class.disabled]="pageMode === 'view'"
          [clearable]="false"
          placeholder="{{ 'COMPONENTS.USERS.SELECT_CITY' | translate }}"
          formControlName="cityId"
          [bindLabel]="getLanguageSpecificKey('name')"
          bindValue="id"
          name="cityId"
          class="w-100">
          <ng-option *ngFor="let city of cities" [value]="city.id">
            {{ city?.name }}
          </ng-option>
        </ng-select>
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: addressForm.controls['cityId']
          }"></ng-container>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="form-field">
        <label class="required" htmlFor="address">
          {{ 'COMPONENTS.USERS.COMPLETE_ADDRESS' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          type="text"
          class="form-control"
          appNoConsecutiveSpaces
          id="address"
          name="address"
          formControlName="address" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: addressForm.controls['address']
          }"></ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="pageMode === 'edit'"
    class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button
      (click)="setAddress()"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.RESET' | translate }}
    </button>
    <button (click)="submitAddress()" type="button" class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
    </button>
  </div>
</form>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    }
  }
</ng-template>
