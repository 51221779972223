<mat-card>
  <mat-card-title-group>
    <mat-card-title>
      <a mat-button routerLink="/catalog/categories">
        <mat-icon>arrow_back</mat-icon></a
      >{{ (category$ | async)?.name }}
    </mat-card-title>
  </mat-card-title-group>
  <mat-tab-group dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="mat-tab-icon">info</mat-icon>
        General -->
      </ng-template>
      <mat-card-content>
        <app-category-details
          *ngIf="category$ | async"
          [category]="(category$ | async) ?? null"
        ></app-category-details>
      </mat-card-content>
    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mat-tab-icon">inventory_2</mat-icon>
        Products
      </ng-template>
      <mat-card-content>
        <app-category-products
          *ngIf="category$ | async"
          [category]="(category$ | async) ?? null"
        ></app-category-products
      ></mat-card-content>
    </mat-tab> -->
  </mat-tab-group>
</mat-card>
