import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { log } from 'console';
import { FileInput } from 'ngx-material-file-input';

@Component({
  selector: 'app-new-product-photos-input',
  templateUrl: './new-product-photos-input.component.html',
  styleUrls: ['./new-product-photos-input.component.scss'],
})
export class NewProductPhotosInputComponent {
  photosToSave: File[] = [];
  photosToDisplay: { name: string; data: string }[] = [];

  constructor() {}

  updatePhotosToDisplay($event: any) {
    if($event.target.files.length == 0)
      return;
    this.photosToSave = [...$event.target.files];
    this.photosToDisplay = [];
    for (const file of this.photosToSave) {
      this.photosToDisplay.push({
        name: file.name,
        data: URL.createObjectURL(file),
      });
    }
  }

  removePhoto(name: string) {
    this.photosToSave = this.photosToSave.filter(
      (file) => file.name !== name,
    );
    this.photosToDisplay = this.photosToDisplay.filter(
      (photo) => photo.name !== name,
    );
  }
}
