import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';
import { BaseComponent } from '@src/app/core/base';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent extends BaseComponent implements OnChanges {
  @Input() title: any = null;
  @Input() message: any = null;
  @Input() closeText: any = this.translate.instant('GENERAL.BUTTONS.CLOSE') || 'Close';
  @Input() confirmText: any = this.translate.instant('GENERAL.BUTTONS.CONFIRM') || 'Confirm';
  @Input() eventType: string | null = null;

  @Input() iconClass: any = null;
  @Input() extraIcon: any = null;

  @ViewChild('dialog', { static: true }) dialogRef!: ElementRef;
  @Output() closeDialog: EventEmitter<{
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }> = new EventEmitter<{
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }>();
  /**
   * Constructs a new instance of the ConfirmDialogComponent class.
   *
   * @param {Injector} injector - The injector used to inject dependencies.
   */
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Executes when input properties change.
   *
   * @param {SimpleChanges} changes - An object containing the changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes);
  }

  /**
   * Opens a dialog with the specified data.
   *
   * @param {any} data - The data to be passed to the dialog. Defaults to null.
   */
  openDialog(data: any = null) {
    if (this.dialogRef) {
      this.modalService
        .open(this.dialogRef, {
          size: 'md',
          centered: true,
          scrollable: true,
          windowClass: 'confirm-modal',
          animation: true,
          keyboard: false,
          backdrop: 'static',
          ariaLabelledBy: 'Confirmation',
        })
        .result.then(value => {
          this.closeDialog.emit({
            data: data,
            eventType: this.eventType,
            confirmed: true,
            action: 'confirm',
          });
        })
        .catch(reason => {
          this.closeDialog.emit({
            data: data,
            eventType: this.eventType,
            confirmed: false,
            action: 'dismiss',
          });
        });
    }
  }
}
