<div class="d-flex align-content-center justify-content-between app-pagination w-100">
  <!-- <div class="d-flex rows-per-page align-items-center"> -->
  <!-- <ng-select class="bg-white w-100 cus-select clear-disable" [(ngModel)]='size' [searchable]="false" [clearable]="false"
      (ngModelChange)="handlePageChange('size', $event)">
      <ng-option *ngFor="let size of pageSizeList || []" [value]="size">{{size}}</ng-option>
    </ng-select> -->

  <!-- </div> -->
  <div class="d-flex align-items-center justify-content-between">
    <ng-select
      [clearSearchOnAdd]="true"
      class="bg-white w-100 p-0 pagination-select clear-disable"
      [(ngModel)]="size"
      [searchable]="false"
      [clearable]="false"
      (ngModelChange)="handlePageChange('size', $event)">
      <ng-option *ngFor="let size of pageSizeList || []" [value]="size">{{ size }}</ng-option>
    </ng-select>
    <div class="rows-per-page">
      {{ 'PAGINATION.SHOWING' | translate }}
      {{ (page - 1) * size || 1 }} {{ 'PAGINATION.TO' | translate }}
      {{
        size > totalElements
          ? totalElements
          : page * size > totalElements
            ? totalElements
            : page * size
      }}
      {{ 'PAGINATION.OF' | translate }} {{ totalElements }} {{ 'PAGINATION.ENTERIES' | translate }}
    </div>
  </div>

  <ngb-pagination
    [ellipses]="true"
    [rotate]="true"
    (pageChange)="handlePageChange('page', $event)"
    [maxSize]="3"
    [pageSize]="size"
    [collectionSize]="totalElements"
    [(page)]="page"
    [boundaryLinks]="true">
    <ng-template ngbPaginationFirst><i class="icon icon-angle-double-left middle"></i></ng-template>
    <ng-template ngbPaginationLast><i class="icon icon-angle-double-right middle"></i></ng-template>
    <ng-template ngbPaginationPrevious><i class="icon icon-angle-left middle"></i></ng-template>
    <ng-template ngbPaginationNext><i class="icon icon-angle-right middle"></i></ng-template>
    <!-- <ng-template ngbPaginationPages>{{page}}</ng-template> No Need pages number -->
  </ngb-pagination>
</div>
