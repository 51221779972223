<div class="mb-4 px-4 pt-4 pb-5 bg-white br-8">
  <div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
    <div class="col-md-6">
      <div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
        <div class="d-flex flex-row-reverse">
          @if (isEditMode() && isLoading === false) {
            <status
              [data]="baseModel"
              [element]="baseModel"
              [options]="constantList.STATUS_ARRAY"
              [column]="column"
              (updatedStatus)="updateStatus($event.status)"></status>
          }
        </div>
      </div>
    </div>
    <!-- <div class="d-flex gap-3" *ngIf="pageMode !== 'add'" [viewPermission]="[this.permissionsList.UPDATE_USERS]">
      <div class="d-inline-block" ngbDropdown>
        <button [disabled]="isSuperAdmin() === true" [class.disabled]="isSuperAdmin() === true"
          class="dropdown-toggle btn-dropdown btn-140 btn-outline btn-outline-primary ms-2 mb-0"
          [ngClass]="baseModel?.isActivated ? 'active-class' : 'inactive-class'" id="dropdownStatus" ngbDropdownToggle>
          <i class="icon middle me-2" [ngClass]="{
                'icon-tick-circle': baseModel?.isActivated,
                'icon-close-circle': !baseModel?.isActivated
            }"></i>

          {{ 'COMMON.TEXT.' + (baseModel?.isActivated ? 'ACTIVE' : 'INACTIVE') | translate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownStatus">
          <button ngbDropdownItem (click)="updateUserStatus(true, baseModel.id)">
            <i class="icon middle me-2 icon-tick-circle"></i>
            {{ 'GENERAL.BUTTONS.ACTIVATE' | translate }}
          </button>
          <button ngbDropdownItem (click)="updateUserStatus(false, baseModel.id)">
            <i class="icon middle me-2 icon-close-circle"></i>
            {{ 'GENERAL.BUTTONS.DEACTIVATE' | translate }}
          </button>
        </div>
      </div>
    </div> -->
  </div>

 


  <app-sub-category-info></app-sub-category-info>
</div>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
  @if (control?.hasError('required')) {
  <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
  } @else if (control?.hasError('pattern')) {
  <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
  }
  }
</ng-template>

<app-confirm-dialog [confirmText]="dialogConfirmText" [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  [message]="dialogMessage" extraIcon="icon-trash-filled" [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>