<div class="mb-4 align-items-center d-flex justify-content-between">
  <h4 class="tx-16 mb-0 fw-500">{{ 'COMPONENTS.USERS.OFFICIAL_DETAILS' | translate }}</h4>
</div>
<form [formGroup]="officialDetailsForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="input-phone-number" class="required">
          {{ 'COMPONENTS.USERS.OFFICIAL_CONTACT_NUMBER' | translate }}
        </label>
        <ngx-intl-tel-input
          class="intl-tel-input"
          inputId="input-phone-number"
          name="phoneNumber"
          customPlaceholder="+000 000 0000"
          formControlName="phoneNumber"
          searchCountryPlaceholder="Search by Country Name or Code"
          [phoneValidation]="true"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [separateDialCode]="false"
          [searchCountryFlag]="true"
          [selectFirstCountry]="false"
          [maxLength]="15"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectedCountryISO]="CountryISO.UnitedStates"
          [numberFormat]="phoneNumberFormat.National"></ngx-intl-tel-input>
        <ng-container
          *ngIf="
            officialDetailsForm.controls['phoneNumber'].value !== null &&
            officialDetailsForm.controls['phoneNumber']?.invalid
          ">
          <small class="text-danger w-100">
            {{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}
          </small>
        </ng-container>
        <ng-container
          *ngIf="
            officialDetailsForm.controls['phoneNumber'].value === null &&
            officialDetailsForm.controls['phoneNumber']?.invalid &&
            officialDetailsForm.controls['phoneNumber']?.touched &&
            officialDetailsForm.controls['phoneNumber']?.dirty
          ">
          <small class="text-danger w-100">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
        </ng-container>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label htmlFor="email" class="required">
          {{ 'COMPONENTS.USERS.OFFICIAL_EMAIL_ADDRESS' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          formControlName="email"
          name="email"
          appNoConsecutiveSpaces
          id="email"
          class="form-control"
          type="email"
          placeholder="charly.robinson@domain.com" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: officialDetailsForm.controls['email']
          }"></ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-field mb-4">
        <label class="required" htmlFor="position">
          {{ 'COMPONENTS.USERS.DESIGNATION' | translate }}
        </label>
        <input
          [class.disabled]="pageMode === 'view'"
          name="position"
          formControlName="position"
          id="position"
          class="form-control"
          appNoConsecutiveSpaces
          type="text"
          placeholder="Designation" />
        <ng-container
          [ngTemplateOutlet]="reqErrRef"
          [ngTemplateOutletContext]="{
            control: officialDetailsForm.controls['position']
          }"></ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="pageMode === 'edit'"
    class="d-flex justify-content-end gap-2"
    [viewPermission]="[this.permissionsList.UPDATE_USERS]">
    <button
      (click)="setDetails()"
      type="button"
      class="btn btn-outline btn-outline-primary btn-140">
      {{ 'GENERAL.BUTTONS.RESET' | translate }}
    </button>
    <button (click)="submitDetails()" type="button" class="btn btn-primary btn-140">
      {{ 'GENERAL.BUTTONS.UPDATE' | translate }}
    </button>
  </div>
</form>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
    @if (control?.hasError('required')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
    } @else if (control?.hasError('pattern')) {
      <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
    }
  }
</ng-template>
