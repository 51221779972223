
/**
 * Downloads a file from the provided response.
 *
 * @param {any} rsponse - The response containing the file data.
 * @return {void} This function does not return anything.
 */
export function downloadFile(rsponse: any) {
  const blob = new Blob([rsponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'exported-sheet.xlsx';
  link.click();
}
