import { NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BaseResolver } from '@src/app/core/resolvers/base-resolver';
import { UnsavedDataChangesGuard } from '@src/app/core/guards/unsaved-data-changes.guard';
import { ModuleRoutes } from './brands.routes';

@NgModule({
  declarations: [],
  imports: [CommonModule, ModuleRoutes, TranslateModule],
  providers: [provideClientHydration(), BaseResolver, UnsavedDataChangesGuard],
})
export class BrandsModule { }
