import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@src/app/core/base';
import { forkJoin, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import {
  STORE_MANAGEMENT_STATUS_UPDATE,
  StoreManagementService,
} from '@src/app/features/store-management/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DebounceClickDirectiveModule } from '@src/app/core/shared/directives/debounceClick/debounceClick.directive.module';
import { DEFAULT_DEBOUNCE_TIME, S3_BUCKET_URL, TAG_TYPE } from '@src/app/core/constants';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { NoConsecutiveSpacesDirectiveModule } from '@src/app/core/shared/directives/noConsecutiveSpaces/noConsecutiveSpaces.directive.module';
import { Debounce } from '@src/app/core/decorator/debounce.decorator';
import { SharedModule } from '../../../../core/shared/shared.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AppStatus } from '@src/app/core/shared/components/app-status/app-status.component';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { NewProductPhotosInputComponent } from '@src/app/core/shared/components/new-product-photos-input/new-product-photos-input.component';
import { ItemsRepository } from '@src/app/features/item-management/core/repositories/items.repository';
import { OnlyIntegerDirectiveModule } from '@src/app/core/shared/directives';
import { CategoryRepository } from '@src/app/features/categories/core/repositories/category.repository';
import { SubCategoriesRepository } from '@src/app/features/sub-categories/core/repositories/sub-categories.repository';
import { StoreRepository } from '@src/app/features/store-management/core/repositories/store.repository';
import { BrandsRepository } from '@src/app/features/brands/core/repositories/brands.repository';
import { TagsRepository } from '@src/app/features/tags/core/repositories/tags.repository';
import { LoaderModule } from '@src/app/core/shared/components/loader/loader.module';
import { NgMultiSelectDropDownModule, IDropdownSettings  } from 'ng-multiselect-dropdown';
import { ITEM_BY_ID_BASE_URL, ITEM_STATUS_UPDATE } from '../../core/constants/apis_list';
import { RequestActvationType } from '@src/app/core/shared/interfaces/app-types';

export enum Role {
  Admin = 'admin',
  'Content Creator' = 'content_creator',
  Operator = 'operator',
}
@Component({
  selector: 'item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DebounceClickDirectiveModule,
    ViewPermissionDirectiveModule,
    NoConsecutiveSpacesDirectiveModule,
    SharedModule,
    AppStatus,
    NgxIntlTelInputModule,
    NewProductPhotosInputComponent,
    OnlyIntegerDirectiveModule,
    LoaderModule,
    NgMultiSelectDropDownModule
  ],
})
export class ItemDetailComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  public dialogMessage: any = '';
  public dialogConfirmText: any = '';
  public confirmEventAction: string = '';
  public disableRoles: boolean = false;
  public isLoading: boolean = false;
  public isOpenAlternative: boolean = false;
  public isOpenSimilar: boolean = false;
  // public active: number = 1;
  public userType: any = 'admin_user';
  public hasSpecificRole: boolean = false;
  public CountryISO = CountryISO;
  public itemCoverImage: any = 'assets/images/thumbnail.svg';
  public coverImageFile: any = null;
  public phoneNumberFormat = PhoneNumberFormat;
  public SearchCountryField = SearchCountryField;
  public rolesList: any = Role;
  public S3_BUCKET_URL = S3_BUCKET_URL;
  public categoriesArray: Array<any> = [];
  public subCategoriesArray: Array<any> = [];
  public brandsArray: Array<any> = [];
  public storesArray: Array<any> = [];
  public styleTagArray: Array<any> = [];
  public materialTagArray: Array<any> = [];
  public finishTagArray: Array<any> = [];
  public similarProductsArray: Array<any> = [];
  public alternativeProductsArray: Array<any> = [];
  public productsArray = [];
  public multiDropdownSettings = {};
  public multiDropdownAlternativeItemsSettings = {};
  public multiDropdownSimilarItemsSettings = {};
  

  @ViewChild(NewProductPhotosInputComponent)
  public photosInput!: NewProductPhotosInputComponent;

  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    coverImagePath: new FormControl(null),
    images: new FormControl(null),
    categoryId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    subCategoryId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    storeId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    brandId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    styleId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    baseMaterialId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    finishId: new FormControl(null, [Validators.required]), // Mandatory dropdown
    sku: new FormControl(null, [Validators.required]),
    price: new FormControl(null, [Validators.required]),
    order: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(99)]),
  });
//   brandId: 2
// coverImage: (binary)
// alternatives: 1,2
// price: 23.99
// similar: 1,2
// name: Product name
// images: (binary)
// tags: 1,2
// subcategoryId: 11
// itemOrder: 10
// storeId: 2
// description: Product description
// sku: DS12935324
  formBackup = this.helperService.clone(this.formGroup.value);


  public column: any = {
    key: 'status',
    value: 'COMPONENTS.ITEMS.STATUS',
    headingClass: 'ps-4',
    type: 'status',
    sortable: false,
    defaultTitle: true,
    apiCallType: RequestActvationType.RESOURCE_URL,
    options: [
      {
        label: 'GENERAL.BUTTONS.ACTIVATE',
        value: 'active',
        icon: 'tick-circle',
      },
      {
        label: 'GENERAL.BUTTONS.DEACTIVATE',
        value: 'inactive',
        icon: 'close-circle',
      },
    ],
    endpoint: ITEM_BY_ID_BASE_URL,
    success: this.translate.instant('MSGS.ITEMS.UPDATE_ITEM_STATUS'),
    successSecond: this.translate.instant('MSGS.ITEMS.UPDATE_ITEM_STATUS_DEACTIVATED'),
    alert: this.translate.instant('MSGS.ITEMS.ARE_YOU_SURE_DEACTIVATE'),
    alertSecond: this.translate.instant('MSGS.ITEMS.ARE_YOU_SURE_ACTIVATE'),
    error: this.translate.instant('MSGS.ITEMS.UNABLE_TO_UPDATE_ITEM_STATUS'),
  };

  /**
   * Component constructor
   * @param injector injects required services
   * @param pageSettingsService service for managing page settings in BaseComponent
   */
  constructor(
    injector: Injector,
    private repository: ItemsRepository,
    private categoryRepository: CategoryRepository,
    private subCategoryRepository: SubCategoriesRepository,
    private storeRepository: StoreRepository,
    private brandRepository: BrandsRepository,
    private tagsRepository: TagsRepository,
    private pageSettingsService: PageSettingsService, // Service for managing page settings
  ) {
    super(injector);
    // If the page is not in edit mode, set the pageMode to 'add'
    if (this.isEditMode() == null) {
      this.pageMode = 'add';
    };

    this.getAllData();
    this.searchProducts();
    // Get the item from the resolver
    this.getItem();

    // Creating new page settings for the user management page
    const newSettings = {
      link1: {
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE', // Title for the page
        name:
          this.pageMode === 'add'
            ? 'BREADCRUMBS.PRODUCTS_MANAGEMENT.ADD_PRODUCT'
            : 'BREADCRUMBS.PRODUCTS_MANAGEMENT.EDIT_PRODUCT', // Name for the breadcrumb
        link: this.routeList.PRODUCTS, // Link for the breadcrumb
      },
      // link2: {
      //   title:
      //     this.pageMode === 'add'
      //       ? 'BREADCRUMBS.PRODUCTS_MANAGEMENT.ADD_PRODUCT'
      //       : 'BREADCRUMBS.PRODUCTS_MANAGEMENT.EDIT_PRODUCT',
      // },
    };

    // Using the page settings service to update the page settings
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  /**
   * Initializes the component and performs any necessary setup operations.
   *
   * This function is called when the component is first created and is used to
   * retrieve any necessary data from the URL parameters, such as the email and
   * token, and perform any initial setup tasks.
   *
   * @return {void} This function does not return a value.
   */
  /**
   * @description
   * Initializes component and retrieves user data from route resolver
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.userType = params['type'];
        if (this.userType == 'app_user' && this.pageMode == 'edit') {
          this.disableRoles = true;
        } else this.disableRoles = false;
      }
    });
    /**
     * Retrieves user data from route resolver
     * Stores user data in component property `baseModel`
     * Also stores a copy of user's roles in `baseModel.rolesData`
     * Checks if user is a super admin
     *   and sets `pageMode` property to 'view' if true
     * Converts user's role IDs to an array of strings
     * Removes '+' prefix from phone numbers if present
     * Formats phone numbers to E.164 format
     * Removes 'null' and '+null' from landlineNumber
     * Formats date of birth to ISO format
     * Calls `setProfile`
     */
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.item) {
          this.baseModel = this.helperService.clone(data?.item);

          this.setItem();
        }
      });
      this.multiDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'title',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true
      };
      this.multiDropdownAlternativeItemsSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        limitSelection: 6,
        allowSearchFilter: true
      };
      this.multiDropdownSimilarItemsSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        limitSelection: 4,
        allowSearchFilter: true
      };
    // this.onChanges();
    this.formGroup.get('categoryId')!.valueChanges.subscribe(data => {
      this.searchSubCategories(data)
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.photosInput.remotePhotosToDisplay = this.baseModel.photos;
    }, 500); // Delay of 500 milliseconds
  }
    
  /**
   * Subscribes to the data observable from the ActivatedRoute and
   * sets the baseModel property with the data from the observable.
   */
  getItem() {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.item) {
          /**
           * The store data from the ActivatedRoute data observable.
           */
          this.baseModel = data?.item;
        }
      });
  }

  /**
   * Reset the password form fields
   *
   * @param form The form to reset
   */
  resetPasswordForm(form: any) {
    // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
    form.reset(); // Reset the form fields
  }

  /**
   * Get role details by ID
   *
   * @param id The ID of the role
   * @returns The role details if found, empty object otherwise
   */
  getRoleDetailsbyId(id: any): any {
    const index = this.rolesList.findIndex((s: any) => s.id == id);
    if (index !== -1) {
      return this.rolesList[index];
    } else {
      return {}; // Empty object if not found
    }
  }

  /**
   * Finds all invalid controls in the given form
   * @param form The form to search for invalid controls
   * @returns The names of the invalid controls
   */
  public findInvalidControls(form: any): string[] {
    const invalid = []; // The names of the invalid controls
    const controls = form.controls; // The controls of the given form

    // Loop through all the controls in the form
    for (const name in controls) {
      // If the control is invalid
      if (controls[name].invalid) {
        // Add its name to the list of invalid controls
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**
   * Updates the status of the segment.
   *
   * @param {string} status - The new status of the segment.
   * @return {void} This function does not return anything.
   */
  public updateStatus(status: string) {
    this.httpService
      .requestEntity('PATCH', ITEM_STATUS_UPDATE.replace('{id}', this.baseModel?.id), {
        status: status,
      })
      .pipe(this.destroy$())
      .subscribe({
        /**
         * Handles the next callback of the subscription.
         *
         * @param {any} res - The response object.
         * @return {void} This function does not return anything.
         */
        next: (res: any) => {
          if (
            res.status === this.constantList.SUCCESS_STATUS ||
            res.status === this.constantList.SUCCESS_STATUS_CODE
          ) {
            this.httpService.showMessage(
              this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
              'success',
            );
            this.baseModel.isActivated = res.body?.isActivated;
          }
        },
      });
  }

  /**
   * Submits store based on the specified type.
   *
   * @param {string} type - The type of submission ('draft' or 'create').
   * @return {void} This function does not return a value.
   */
  protected onSubmit(type: string): void {
    let { valid, value } = this.formGroup;
    let formData = new FormData();
    let allTags = [];
    let alternativeItemsIDs = [];
    let similarItemsIDs = [];
    if (type === 'add') {
      if (!this.coverImageFile?.file) {
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
        return;
      }
      value.coverImagePath = this.coverImageFile?.file;
      formData.append('coverImage', this.coverImageFile.file);
    } else if (type === 'update') {

      if (this.coverImageFile?.file) {
        formData.append('coverImage', this.coverImageFile.file);
      }
      if (this.photosInput.photosToDelete && this.photosInput.photosToDelete) {
        formData.append('deleteImages', this.photosInput.photosToDelete.join(','));
      }
      
    }
    for (const index in this.photosInput.photosToSave) {
      formData.append(`images`, this.photosInput.photosToSave[index]);
    }
    for (const index in value.styleId) {
      allTags.push(value.styleId[index].id);
    }
    for (const index in value.baseMaterialId) {
      allTags.push(value.baseMaterialId[index].id);
    }
    for (const index in value.finishId) {
      allTags.push(value.finishId[index].id);
    }
    formData.append('tags', allTags.join(','));
    formData.append('name', value.name);
    formData.append('description', value.description);
    formData.append('price', value.price);
    formData.append('sku', value.sku);
    formData.append('itemOrder', value.order);
    formData.append('subcategoryId', value.subCategoryId);
    // formData.append('categoryId', value.categoryId);
    formData.append('storeId', value.storeId);
    formData.append('brandId', value.brandId);
    if (this.alternativeProductsArray && this.alternativeProductsArray.length) {
      for (const item of this.alternativeProductsArray) {
        alternativeItemsIDs.push(item.id);
      }
    }
    if (this.similarProductsArray && this.similarProductsArray.length) {
      for (const item of this.similarProductsArray) {
        similarItemsIDs.push(item.id);
      }
    }

    formData.append('alternatives', alternativeItemsIDs.join(','));
    formData.append('similar', similarItemsIDs.join(','));
  
    // formData.forEach(item =>{
    //   console.log(item)
    // });
    if (valid) {
      // Determine API endpoint and HTTP method based on whether it's an update or creation
      let apiContainer = this.repository.create(formData);
      if (this.baseModel?.id) {
        apiContainer = this.repository.update(this.baseModel.id, formData);
      }

      apiContainer
        .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
        .subscribe({
          /**
           * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
           * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
           *
           * @param {any} res - The response object.
           * @return {Promise<void>} A promise that resolves when the function completes.
           */
          next: async (res: any) => {
            this.httpService.showMessage(
              `${type === 'update'
                ? this.translate.instant('MSGS.ITEMS.ITEM_UPDATED')
                : this.translate.instant('MSGS.ITEMS.ITEM_CREATED')
              }`,
              'success',
            );
            this.router.navigate([this.routeList.ITEMS]);
          },
        });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  /**
   * Sets the form values to the base model
   *
   * This function is called in the ngOnInit lifecycle hook,
   * after getting the user profile from the API.
   *
   * It patches the formGroup with the baseModel values,
   * and updates the formGroup validity to emit events.
   */
  setItem(id?: string) {
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    if (id === 'cancel') {
      this.formGroup.patchValue({}, { emitEvent: false });
      this.helperService.goToRoute(this.routeList.STORES);
    } else {
      // this.formGroup.patchValue({ ...this.baseModel }, { emitEvent: false });
      this.searchSubCategories(this.baseModel.subcategories[0]?.parentCategory.id)
      this.formGroup.patchValue({
        name: this.baseModel.name,                    // Set subCategoryId
        description: this.baseModel.description,
        id: this.baseModel.id,
        price: this.baseModel.price,
        sku: this.baseModel.sku,
        order: this.baseModel.itemOrder,
        coverImagePath: this.baseModel.coverPhoto,
        brandId: this.baseModel.brand?.id,
        subCategoryId: this.baseModel.subcategories[0]?.id,
        categoryId: this.baseModel.subcategories[0]?.parentCategory.id,
        storeId: this.baseModel.store?.id,
        isActivated: this.baseModel.isActivated,
        styleId: this.baseModel.tags?.filter((tag: any) => tag.type === TAG_TYPE.style),
        baseMaterialId: this.baseModel.tags?.filter((tag: any) => tag.type === TAG_TYPE.base_material),
        finishId: this.baseModel.tags?.filter((tag: any) => tag.type === TAG_TYPE.finish)
      });
      this.itemCoverImage = this.baseModel.coverPhoto;
      this.alternativeProductsArray = this.baseModel.alternatives;
      this.similarProductsArray = this.baseModel.similar;
    }
  }

  /**
   * Handles file input change event
   * @param event file input change event
   */
  onPictureUpload(event: any) {
    // if (!event.target.files[0]?.type.includes('image') || !(event.target.files[0].size < 300000)) {
    //   this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'), 'error');
    //   return;
    // }
    // Set the selected image details to the image variable
    this.coverImageFile = {
      file: event.target.files[0],
      fileName: event.target.files[0]?.name,
    };
    // Create an instance of FileReader
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result; // Use FileReader result as the image source

      // Directly access image dimensions when available
      img.onload = () => {
        // if (img.width != 1200 || img.height != 900) {
        //   this.httpService.showMessage(
        //     this.translate.instant('MSGS.GENERAL.INVALID_IMAGE_DIMENSION'),
        //     'error',
        //   );
        //   return;
        // } else {
          // Set the image source to the result of reading the image
          this.itemCoverImage = reader.result;
        // }
      };
    };

    // Read the image as a data url
    reader.readAsDataURL(new Blob([event.target.files[0]], { type: event.target.files[0].type }));
  }

  //fetch categories
  searchCategories() {
    this.categoryRepository.search()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.categoriesArray = res.body.data.filter((category: any) => category.isActivated);
          }
        },
      });
  }

  //fetch sub categories
  searchSubCategories(id: string) {
    this.subCategoryRepository.search({
      'filter.isActivated': true,
      'filter.parentCategoryId': id,
      'limit': 100
    })
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.subCategoriesArray = res.body.data;
          }
        },
      });
  }

  //fetch store
  searchStores() {
    this.storeRepository.search()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.storesArray = res.body.data.filter((store: any) => store.isActivated);
          }
        },
      });
  }

  //fetch brands
  searchBrands() {
    this.brandRepository.search()
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.brandsArray = res.body.data.filter((brand: any) => brand.isActivated);
          }
        },
      });
  }

  searchProducts(keyword: string = "") {
    this.repository.search({
      'filter.isActivated': true,
      'search': keyword,
      'limit': 100
    })
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;
          if (res?.body) {
            this.productsArray = res.body.data;
          }
        },
      });
  }

  // get all data
  getAllData() {
    this.isLoading = true;
    const categoriesCall = this.categoryRepository.search({
      'filter.isActivated': true,
      'limit': 100
    });
    // const subCategoriesCall = this.subCategoryRepository.search({
    //   'filter.isActivated': true
    // });
    const storesCall = this.storeRepository.search({
      'filter.isActivated': true,
      'limit': 100
    });
    const brandsCall = this.brandRepository.search({
      'filter.isActivated': true,
      'limit': 100
    });
    const styleTagsCall = this.tagsRepository.search({
      'filter.isActivated': true,
      'filter.type': TAG_TYPE.style,
      'limit': 100
    });
    const materialTagsCall = this.tagsRepository.search({
      'filter.isActivated': true,
      'filter.type': TAG_TYPE.base_material,
      'limit': 100
    });
    const finishTagsCall = this.tagsRepository.search({
      'filter.isActivated': true,
      'filter.type': TAG_TYPE.finish,
      'limit': 100
    });
    forkJoin([
      categoriesCall,
      // subCategoriesCall,
      storesCall,
      brandsCall,
      styleTagsCall,
      materialTagsCall,
      finishTagsCall
    ])
    .subscribe(response => {
      this.isLoading = false;
      const resCategories = response[0] as ApiResponseInterface<any>;
      // const resSubCategories = response[1] as ApiResponseInterface<any>;
      const resStores = response[1] as ApiResponseInterface<any>;
      const resBrands = response[2] as ApiResponseInterface<any>;
      const resStyleTags = response[3] as ApiResponseInterface<any>;
      const resMaterialTags = response[4] as ApiResponseInterface<any>;
      const resFinishTags = response[5] as ApiResponseInterface<any>;
          if (resCategories?.body) {
            this.categoriesArray = resCategories.body.data;
          }
          // if (resSubCategories?.body) {
          //   this.subCategoriesArray = resSubCategories.body.data;
          // }
          if (resStores?.body) {
            this.storesArray = resStores.body.data;
          }
          if (resBrands?.body) {
            this.brandsArray = resBrands.body.data;
          }
          if (resStyleTags?.body) {
            this.styleTagArray = resStyleTags.body.data;
          }
          if (resMaterialTags?.body) {
            this.materialTagArray = resMaterialTags.body.data;
          }
          if (resFinishTags?.body) {
            this.finishTagArray = resFinishTags.body.data;
          }
    });
  }

}
