<form
  class="search-bar"
  (submit)="searchClick.emit(searchControl.value); hasValue = true"
  novalidate>
  <div class="form-field">
    <input
      type="text"
      class="form-control"
      [placeholder]="placeholder"
      [formControl]="searchControl"
      (input)="search(searchControl.value)" />
    <button type="button" class="btn-transparent">
      <i class="icon icon-search" *ngIf="!hasValue"></i>
      <i class="icon icon-close-circle" type="button" *ngIf="hasValue" (click)="resetValue()"></i>
    </button>
  </div>
</form>
