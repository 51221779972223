import { Routes, RouterModule } from '@angular/router';
import * as ROUTE_LIST from '@src/app/core/constants/routes.constant';
import {STYLE, BASE_MATERIAL, FINISH} from '@src/app/core/constants/routes.constant';
import { BrandsComponent } from './brands.component';
import {BrandListingComponent} from '@src/app/features/brands/views/brands-listing/brands-listing.component';
import { BrandDetailComponent } from './views/brands-detail/brands-detail.component';
import { BrandsResolver } from './core/resolvers/brands-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: BrandsComponent,
    children: [
      {
        path: '',
        // canActivate: [HasPermissionGuard],
        // data: { permission: PERMISSIONS_LIST.VIEW_USERS },
        component: BrandListingComponent,
      },
      {
        path: STYLE,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: BrandListingComponent,
      },
      {
        path: BASE_MATERIAL,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: BrandListingComponent,
      },
      {
        path: FINISH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: BrandListingComponent,
      },
      {
        path: ROUTE_LIST.VIEW_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: BrandDetailComponent,
      },
      {
        path: ROUTE_LIST.ADD_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },

        // canDeactivate: [UnsavedDataChangesGuard],
        component: BrandDetailComponent,
      },
      {
        path: ROUTE_LIST.EDIT_PATH,
        // canActivate: [HasPermissionGuard],
        // data: { permission: [PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS] },
        // canDeactivate: [UnsavedDataChangesGuard],
        component: BrandDetailComponent,
        resolve: {
          store: BrandsResolver,
        },
      },
    ],
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
