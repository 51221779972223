import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  constructor(private el: ElementRef) {}

  @Input() OnlyNumber = true;
  @Input() allowNegative = false;
  @Input() excludeKeyCodes: number[] = [];

  /**
   * Blocks the paste event if the pasted data is not a number.
  *
  * @param {any} event - The paste event object.
  * @return {void} This function does not return anything.
  */
  @HostListener('paste', ['$event'])
  blockPaste(event: any): void {

    let clipboardData, pastedData;
    // Get pasted data via clipboard API
    clipboardData = event.clipboardData || (<any>window)['clipboard'];
    pastedData = clipboardData.getData('Text').toUpperCase();

     // const invalidChars = ['e', 'E', '+', '-'];
     if (isNaN(pastedData)) {
      // if( invalidChars.includes(pastedData)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  /**
   * Handles the keydown event for the input field.
   *
   * @param {any} event - The keydown event object.
   * @return {void} This function does not return anything.
   */
  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    const e = event as KeyboardEvent;
    if ((this.OnlyNumber as any) === '') {
      this.OnlyNumber = true;
    }
    if (this.OnlyNumber) {
      // Checking for exclude key codes
      if (this.excludeKeyCodes && this.excludeKeyCodes.length > 0) {
        const hasKeyCode = this.excludeKeyCodes.indexOf(e.keyCode) > -1;
        if (hasKeyCode) {
          e.preventDefault();
        }
      }
      if (
        [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        [35, 36, 37, 39].indexOf(e.keyCode) !== -1
      ) {
        // let it happen, don't do anything
        return;
      }
      // Allow negative values if set to true
      if (this.allowNegative && e.keyCode === 109) {
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }

    /**
     * Prevents the default behavior of the mouse wheel event in Chrome.
     *
     * @param {any} event - The mouse wheel event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    event.preventDefault();
  }

    /**
     * Prevents the default behavior of the mouse wheel event in Firefox.
     *
     * @param {any} event - The mouse wheel event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    event.preventDefault();
  }

    /**
     * Prevents the default behavior of the mouse wheel event in Internet Explorer.
     *
     * @param {any} event - The mouse wheel event object.
     * @return {void} This function does not return anything.
     */
  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    event.preventDefault();
  }
}
