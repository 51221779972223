<!-- <div class="user-profile">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ 'COMPONENTS.TAGS.STYLE_TAGS' | translate }}</a>
      <ng-template ngbNavContent>
        <tag-table title="Style" [key]="TAGS_TABS.style"></tag-table>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>{{ 'COMPONENTS.TAGS.BASE_MATERIAL_TAGS' | translate }}</a>
      <ng-template ngbNavContent>
        <tag-table title="Base Material" [key]="TAGS_TABS.base_material"></tag-table>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>{{ 'COMPONENTS.TAGS.FINISH_TAGS' | translate }}</a>
      <ng-template ngbNavContent>
        <tag-table title="Finish" [key]="TAGS_TABS.finish"></tag-table>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="py-4 px-0"></div>
</div> -->
@if (active === 1) {
  <tag-table title="Style" [key]="TAGS_TABS.style"></tag-table>
} @else if (active === 2) {
  <tag-table title="Base Material" [key]="TAGS_TABS.base_material"></tag-table>
} @else if (active === 3) {
  <tag-table title="Finish" [key]="TAGS_TABS.finish"></tag-table>
}
