/**
 * Converts an object to a JSON string, handling circular references.
 *
 * @param {any} obj - The object to be converted to a JSON string. Defaults to null.
 * @return {string} The JSON string representation of the object.
 */
export function stringify(obj: any = null) {
  JSON.stringify(obj, getCircularReplacer);
}

/**
 * Returns a function that replaces circular references in an object with `undefined` during JSON serialization.
 *
 * @return {Function} A function that takes a key and value as arguments and returns the value. If the value is an object,
 * it checks if it has already been seen. If it has, it returns `undefined`. Otherwise, it adds the value to the seen set
 * and returns the value. If the value is not an object, it simply returns the value.
 */
export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
